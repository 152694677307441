import { Link } from 'react-router-dom';
import profileImage from '../assets/avatar.jpg'
import { useNavigate } from 'react-router-dom'
import circle from '../assets/Eclipse Background 1.png';

const StudentSidebar = ({ sidebarToggle, setSidebarToggle, user }) => {
    const navigate = useNavigate();
    
    function handleLogout(){
        localStorage.removeItem('auth');
        navigate('/login');
    }



    return (
        <div className={`absolute left-0 top-0 transition-all duration-300 
                        ${sidebarToggle ? "w-full" : "w-0"} 
                        h-dvh overflow-hidden pb-0 z-50`}

            style={{ backgroundImage: 'linear-gradient(to bottom , #393185 , #7570abae 68% , #f7f8fc00 0%)' }}
        >
            <div className="pt-2 h-full w-full flex flex-col">
                <section className=" text-white h-32">
                    <div className='relative px-2 overflow-hidden'>
                        <button onClick={() => {
                            //collapse the sidebar
                            setSidebarToggle(false)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                            </svg>
                        </button>
                        {/* <img src={circle}  className='absolute -top-10 -left-10'/> */}
                        <span className='absolute left-1/2 font-semibold' style={{ transform: 'translateX(-50%)' }}>Profile</span>
                    </div>
                    <div className="p-2 py-5 w-full relative">
                        <div className="w-11/12 h-24 rounded-2xl p-2 py-4 absolute top-full left-1/2" style={{ transform: 'translateX(-50%)', backgroundImage: 'linear-gradient(to bottom,#fe7d5f ,#f4b858 )' }}>
                            <div className='flex items-center justify-between h-full p-1 rounded-xl' style={{border:'solid 1px #ffaa8e' ,backgroundImage: 'linear-gradient(to bottom,#fe7d5f ,#f4b858 )'}}>
                                <div className='flex items-center gap-2 '>
                                    <span className="w-10 h-10 rounded-full overflow-hidden">
                                        <img className="w-full h-full" src={profileImage} alt="profile-image" />
                                    </span>
                                    <span className="flex flex-col ">
                                        <h4>{user.first_name}</h4>
                                        <div>
                                            <h6 className='text-xs'>
                                                {user.class_id ? user.class_id : 'Class 4'}  <br/>
                                                {user.school_name ? user.school_name : 'Patel Govt.School,Pune'}
                                            </h6>
                                        </div>
                                    </span>
                                </div>
                                <div>
                                    {/* <button className='rounded-full bg-slate-800 p-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                        </svg>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="px-5 pt-10 rounded-t-3xl grow" style={{backgroundColor:' #f5f4fd'}}>
                    <div onClick={() => {
                         setSidebarToggle(false)
                    }} className='h-full flex flex-col gap-5 mt-5'>
                        <div className='flex justify-between items-center h-20 px-4' style={{border:'solid 1px #ede8ff' , borderRadius:'10px'}}>
                            <div className='flex items-center gap-2'>
                                <span className='rounded-full p-2' style={{backgroundColor:'#dfdcf9'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                </span>
                                <span className='py-2'>
                                    <h4 className='font-bold'>Home</h4>
                                    <span className='text-xs'>Go to home page</span>
                                </span>
                            </div>
                            <div>
                                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-8 rounded-full p-1" style={{backgroundColor:'#ffdec6' , color:'#fa9248'}}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>

                                </span>
                            </div>
                        </div>
                        <Link to={`/student/myCourses`}>
                            <div className='flex justify-between items-center h-20 px-4' style={{border:'solid 1px #ede8ff' , borderRadius:'10px'}}>
                                <div className='flex items-center gap-2'>
                                    <span className='rounded-full  p-2' style={{backgroundColor:'#dfdcf9'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                        </svg>
                                    </span>
                                    <span className='py-2'>
                                        <h4 className='font-bold'>My courses</h4>
                                        <span className='text-xs'>Go to courses page</span>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-8 rounded-full p-1" style={{backgroundColor:'#ffdec6' , color:'#fa9248'}}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>

                                    </span>
                                </div>
                            </div></Link>
                        <Link to={'/student/course-points'}>
                        <div className='flex justify-between items-center h-20 px-4' style={{border:'solid 1px #ede8ff' , borderRadius:'10px'}}>
                            <div className='flex items-center gap-2'>
                                <span className='rounded-full  p-2' style={{backgroundColor:'#dfdcf9'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                    </svg>
                                </span>
                                <span className='py-2'>
                                    <h4 className='font-bold'>Credits and Rewards</h4>
                                    <span className='text-xs'>Go to credits page</span>
                                </span>
                            </div>
                            <div>
                                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-8 rounded-full p-1" style={{backgroundColor:'#ffdec6' , color:'#fa9248'}}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        </Link>
                        <div onClick={handleLogout} className='flex justify-between items-center h-20 px-4' style={{border:'solid 1px #ede8ff' , borderRadius:'10px'}}>
                            <div className='flex items-center gap-2'>
                                <span className='rounded-full p-2' style={{backgroundColor:'#dfdcf9'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                                    </svg>
                                </span>
                                <span className='py-2'>
                                    <h4 className='font-bold'>Logout</h4>
                                    <span className='text-xs'>Go to login page</span>
                                </span>
                            </div>
                            <div>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-8 rounded-full p-1" style={{backgroundColor:'#ffdec6' , color:'#fa9248'}}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentSidebar;