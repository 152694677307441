import { useState } from "react";
import { BASEURL } from '../../../../constants';
import axios from "axios";

const TestThumbnail = ({validateQuestion ,autoSaveQuestion , setDeleteQuestionId, q, currentQuestionId, index, setQuestion , questionsArray ,setQuestionsArray , question , setCurrentQuestionId , selectedTestId , setShowModal }) => {



    const [isHovering, setIsHovering] = useState(false);
    console.log('q ', q);

    async function switchSlide(questionId) {
        /*
            if it doesnt match the 
            we need to set q to the new q 
            need the array get the id on which user clicked 
        */
        //save the previos slide 

        if (!validateQuestion()) {
            // If validation fails, return early
            return;
        }
        autoSaveQuestion().then((response)=>{
            if (response.data.status === 'successfully saved') {
                //q is saved we can now set the questions array and set the q to the newly created q 
                setQuestionsArray((questionArray) => {
                    const newQuestionArray = questionArray.map((q) => {
                        if (q.id === currentQuestionId) {
                            return { ...question }
                        } else {
                            return q;
                        }
                    });
                    return newQuestionArray;
                })
                const q = questionsArray.find(item => item.id === questionId);
                console.log('q ' , q , questionId)
                setQuestion(q);
                setCurrentQuestionId(q.id);
            }
        })
    }

    async function saveQuestion(q) {
        const response = await axios.post(`${BASEURL}/api/save-q`, {
            questionId: currentQuestionId
        })


    }

    function handleQuestionDelete(questionId , event){
        event.stopPropagation();
        setShowModal(true);
        setDeleteQuestionId(questionId);
    }
    return (
        <div className={`${q.id === currentQuestionId ? 'border-2 border-blue-400' : 'border border-slate-400'} rounded-md w-20 h-16 2xl:w-28 2xl:h-24 overflow-hidden relative p-1`}
            onClick={() => { switchSlide(q.id) }}
            onMouseEnter={() => { setIsHovering(true) }}
            onMouseLeave={() => { setIsHovering(false) }}
        >
            {
                q ? (
                    <div className="w-full">
                        <span className="absolute bottom-0 left-2 ">{index + 1}</span>
                        {
                            isHovering ? (
                                <svg onClick={(e)=>{handleQuestionDelete(q.id , e)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 absolute right-0 top-0">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            ) : (null)
                        }
                        <span className="w-full mb-1 border rounded-md p-1" style={{fontSize:'4px'}} >{q.text}</span>
                        {
                            q.image_data?.image ? (
                                <div className="w-full flex justify-start">
                                    <img src={q.image_data.image} style={{ height: q.image_data.height, width: q.image_data.width }}></img>
                                </div>
                            ) : null
                        }

                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto auto',
                                gridTemplateRows: `repeat(${Math.ceil((q.options.length) / 2)}, 10px)`,
                                gap: '0.1rem',
                                fontSize:'4px'
                            }}
                        >
                            {
                                q.type === "single" ? (
                                    q.options.map((option, optionIndex) => {
                                        return (
                                            <div key={optionIndex} className="flex gap-1 border border-solid border-gray-300 items-center px-1 py-0 rounded-lg">
                                                <span style={{fontSize:'4px'}} className="flex items-center pr-1 border-r border-solid border-gray-300 h-full text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                <span style={{fontSize:'4px'}} className="w-4/5 outline-none " type="text">{option}</span>
                                            </div>
                                        )
                                    })) : (
                                    q.options.map((option, optionIndex) => {
                                        return (
                                            <div className="flex gap-1 border border-solid border-gray-300 items-center px-1 py-0 rounded-lg" key={optionIndex}>
                                                <span style={{fontSize:'4px'}} className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                <span style={{fontSize:'4px'}} className="w-4/5 outline-none ">{option}</span>
                                            </div>
                                        )
                                    }
                                    ))
                            }
                        </div>
                    </div>
                ) : (null)
            }
        </div>);
}

export default TestThumbnail;