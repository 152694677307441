import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASEURL } from "../../../constants";
import DiactoShareCourseModal from './DiactoShareCourseModal'


const DiactoPublishedCourses = ({ searchTerm }) => {
    const [userCourses, setUserCourses] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState({
        state: false,
        courseId: null,
    });
    // const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]); // Filtered school data for display
    const [courseDetails, setCourseDetails] = useState(null);
    const [render, setRender] = useState(false)
    const [showNewPublishModal, setShowNewPublishModal] = useState(false);
    const navigate = useNavigate();

    const [dropdownState, setDropdownState] = useState({}); // State to track each dropdown's open state
    const [activeTab, setActiveTab] = useState('published'); // Default to 'published' tab

    const [showShareCousrseModal, setShowShareCousrseModal] = useState(false);

    const [coverImages, setCoverImages] = useState({});

    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    }

    const toggleDropdown = (courseId) => {
        setDropdownState(prevState => ({
            ...prevState,
            [courseId]: !prevState[courseId]
        }));
    };

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const userId = localStorage.getItem('auth');
                if (userId) {
                    const response = await axios.post(`${BASEURL}/api/diacto-fetch-courses`);

                    const courseData = response.data.courseData;
                    const publishedCourses = courseData.filter(course =>
                        course.verify_status === 'verified' &&
                        course.course_status === 'completed' &&
                        course.publish_status === 'published'
                    );

                    // setUserCourses(courseData.length ? courseData : []);

                    if (publishedCourses.length === 0) {
                        setUserCourses([]);
                    } else {
                        setUserCourses(publishedCourses);
                        // console.log("",response.data)
                    }
                    console.log('API Data:', publishedCourses);
                }
            } catch (error) {
                console.error("Error fetching user courses:", error);
            }
        };

        fetchCourses();
    }, []);


    useEffect(() => {
        const filtered = [...userCourses].filter(course =>
            course.course_name && course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchTerm, userCourses]);


    function deleteCourse(courseId) {
        axios.delete(`${BASEURL}/api/delete-course`, {
            data: { courseId: courseId },
        })
            .then((response) => {
                if (response.data.status === "success") {
                    setUserCourses(userCourses => userCourses.filter(course => course.id !== courseId));
                }
            });
    }

    function handleDelete(courseId) {
        setShowDeleteModal({ state: true, courseId: courseId });
    }

    function handleCloseDeleteModal() {
        setShowDeleteModal({ state: false, courseId: null });
    }

    const fetchCourseData = async (courseId) => {
        try {
            const response = await axios.get(`${BASEURL}/api/courses/${courseId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching course data:', error);
            return null;
        }
    };

    function formatArrayToString(array) {
        return array.join(', ');
    }


    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const truncateGrade = (grade, maxLength) => {
        if (grade.length <= maxLength) {
            return `Grade: ${grade}`;
        } else {
            const truncatedGrade = grade.substring(0, maxLength);
            return `Grade: ${truncatedGrade} + more`;
        }
    };


    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const fetchCoverImages = async () => {
        const images = {};
        for (const course of filteredData) {
            try {
                console.log(`Fetching image for course ${course.id}`);
                const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
                console.log(`Received response for course ${course.id}:`, response.data);

                if (response.data.dataUrl) {
                    const imageExists = await checkImageExists(response.data.dataUrl);
                    if (imageExists) {
                        images[course.id] = response.data.dataUrl;
                    } else {
                        console.log(`Image for course ${course.id} does not exist in S3 bucket`);
                        images[course.id] = null; // or a default image URL
                    }
                } else {
                    console.log(`No image URL received for course ${course.id}`);
                    images[course.id] = null; // or a default image URL
                }
            } catch (error) {
                console.error(`Error fetching cover image for course ${course.id}:`, error);
                images[course.id] = null; // or a default image URL
            }
        }
        console.log("All fetched images:", images);
        setCoverImages(images);
    };

    useEffect(() => {
        fetchCoverImages();
    }, [filteredData]);


    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
            image.src = imageUrl;
        });
    }

    function formatClassId(classId) {
        // Check if classId is a string, if not, convert it to a string
        const classIdString = typeof classId === 'string' ? classId : String(classId);
    
        // Use regex to match all sequences of digits, sort them, and join them with commas and spaces
        const sortedClassIds = classIdString.match(/\d+/g)?.map(Number).sort((a, b) => a - b).join(', ') || '';
        
        return sortedClassIds;
    }
    
    
    function formatTags(tag) {
        if (!tag || !Array.isArray(tag) || tag.length === 0) {
            return 'No tags available'; // Handle case where tag is not an array, is empty, or has no elements
        }
    
        // Join the array elements into a comma-separated string
        return tag.join(', ');
    }
    

    return (
        <>
            <DiactoShareCourseModal
                isOpen={showShareCousrseModal.state}
                onClose={() => setShowShareCousrseModal({ state: false, courseId: null })}
                courseDetails={{
                    id: showShareCousrseModal.courseId,
                    courseName: userCourses.find(course => course.id === showShareCousrseModal.courseId)?.course_name,
                    subjectName: userCourses.find(course => course.id === showShareCousrseModal.courseId)?.subject_name,
                    courseDescription: userCourses.find(course => course.id === showShareCousrseModal.courseId)?.course_desc,
                }}
            />

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">
                {filteredData.length === 0 ? (
                    <div className="col-span-full w-full my-0 mx-auto">
                        <div className="flex justify-center my-0 mx-auto w-full">
                            <div className="flex flex-col items-center text-center max-w-sm">
                                <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 flex justify-center">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>
                                </p>
                                <p className="text-xl font-bold mt-2 text-gray-700">Oops! No courses found.</p>
                                <p className="mt-2">It looks like there are no courses here. Why not create one and get started?</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    filteredData.map((course, index) => (

                        <div className='bg-white rounded-md border-solid border border-gray-200 p-4 h-max max-w-md relative m-1 ' >
                            {/* {course.course_type === 'imported' && (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10 absolute -top-2.5 right-2">
                                    Imported
                                </span>
                            )} */}
                            <div className="flex justify-between">

                                <h1 className="mb-4 text-md font-semibold text-gray-800 dark:text-white">
                                    {truncateText(course.course_name, 20)}
                                </h1>
                                <div x-data="{ isOpen: true }" className="relative inline-block ">
                                    <button
                                        className="relative z-10 block p-1 text-gray-700  border border-transparent rounded-md dark:text-white focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:bg-gray-800 focus:outline-none"
                                        onClick={() => toggleDropdown(course.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 font-bold">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                        </svg>

                                    </button>

                                    {dropdownState[course.id] && (
                                        <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800"
                                        >
                                            {/* <Link to={`/view/${course.id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg>


                                                <span className="mx-1">
                                                    View Course
                                                </span>
                                            </Link> */}
                                            <Link to={`/admin-view/${course.master_course_id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg>


                                                <span className="mx-1">
                                                    View Course
                                                </span>
                                            </Link>

                                            <a href="/" className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>

                                                <span className="mx-1">
                                                    Delete Course
                                                </span>
                                            </a>

                                            <a href="/" className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
                                                </svg>



                                                <span className="mx-1">
                                                    Course Analytics
                                                </span>
                                            </a>
                                        </div>
                                    )}

                                </div>
                            </div>

                            {/* <img className="object-cover object-center w-full h-64 rounded-lg lg:h-40" src={coverPhoto} alt="" /> */}

                            {coverImages[course.id] ? (
                                <img
                                    src={coverImages[course.id]}
                                    alt={`${course.course_name} cover`}
                                    className="object-cover object-center w-full h-64 rounded-lg lg:h-40 transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
                                    onError={(e) => {
                                        console.error(`Error loading image for course ${course.id}:`, e);
                                        e.target.src = 'path/to/fallback/image.jpg';
                                    }}
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
                                    <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
                                </div>
                            )}

                            <div className="mt-4">

                                <p className="mt-2 text-gray-500 dark:text-gray-400 h-12 text-sm">
                                    {truncateText(course.course_desc, 58)}
                                </p>

                                <div className="flex flex-col w-full gap-2 mt-1 rounded-sm">
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Grade :</span>  {formatClassId(course.class_id)}
                                    </span>
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Difficulty Level :</span>
                                        <span className="capitalize">{course.difficulty_level}</span>
                                    </span>
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Tags :</span>  {formatTags(course.tags)}
                                    </span>
                                </div>

                                <div className='flex flex-row gap-8 justify-start px-1 flex-wrap mt-3'>
                                    <div className="flex flex-col items-start mt-4">
                                        <p className="text-xs text-gray-500">{course.slide_count ? course.slide_count : 0}</p>
                                        <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                            Slides
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-start mt-4">
                                        <p className="text-xs text-gray-500">{course.test_count ? course.test_count : 0}</p>
                                        <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                            Tests
                                        </p>
                                    </div>

                                    {/* {course.master_course_id && (
                                        <div className="flex flex-col items-start mt-4">
                                            <p className="text-xs text-gray-500 dark:text-gray-400">30+</p>
                                            <p className="text-sm font-medium text-gray-700 dark:text-gray-300 cursor-pointer">
                                                Shares
                                            </p>
                                        </div>
                                    )} */}
                                    <div className="text-center flex items-center mt-4  rounded-full">


                                    </div>
                                </div>

                            </div>
                        </div>


                    ))

                )}
            </div>
        </>
    )
}

export default DiactoPublishedCourses