import { useEffect, useState } from "react";

const Thumbnail = ({ slide, currentSlideId, slideIndex, paginate, setShowModal, setDeleteSlideId }) => {

    const [isHovering, setIsHovering] = useState(false);

    function handleThumbnailClick() {
        //if its not the current slide we need to set and save
        paginate(slide.id)
    }

    function handleSlideDelete(e, slideId) {
        console.log('handle delete clicked');
        e.stopPropagation();
        setShowModal(true);
        setDeleteSlideId(slideId);
    }

    return (<>
        {
            slide ? (
                <div className="shrink-0 relative p-1 w-20 h-16 2xl:w-28 2xl:h-24 bg-white" style={slide.id === currentSlideId ? { borderRadius: '0.3rem', cursor: 'pointer', fontSize: '0.3rem',  overflow: 'hidden', border: '2px solid #0c4a6e' } : { borderRadius: '0.3rem', cursor: 'pointer', fontSize: '0.3rem', overflow: 'hidden', border: '1px solid gray' }} onClick={handleThumbnailClick}
                    onMouseEnter={() => { setIsHovering(true) }}
                    onMouseLeave={() => { setIsHovering(false) }}
                >
                    {
                        isHovering ? (
                            <span className="absolute top-0 right-0" onClick={(e) => { handleSlideDelete(e, slide.id) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" width={"0.5rem"} height={'0.5rem'} stroke="currentColor" class="size-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </span>
                        ) : (null)
                    }
                    {
                        slide.content.map((contentObj) => {
                            if (!contentObj.data) return;
                            if (contentObj.type === "Heading") {
                                return (
                                    <div className=''>
                                        <span className="" style={{ fontSize: "5px", fontWeight: '400', lineHeight: '145%', width: '100%', color: 'black' }}>{contentObj.data}</span>
                                    </div>
                                )
                            }
                            if (contentObj.type === "Text") {
                                return (
                                    <div className=''>
                                        <div dangerouslySetInnerHTML={{ __html: contentObj.data }} className='' style={{ fontSize: '' }}>

                                        </div>
                                    </div>
                                )
                            }
                            if (contentObj.type === "Quiz") {
                                return (
                                    <>
                                        {
                                            contentObj.data ? (
                                                <div className="w-full">
                                                    <span className="w-full mb-1 border rounded-md p-1" style={{ fontSize: '4px' }} >{contentObj.data.question}</span>
                                                    {
                                                        contentObj.data.imageData.image ? (
                                                            <div style={{ width: contentObj.data.imageData.width || '400px', height: contentObj.data.imageData.height || '400px' }}>
                                                                <img src={contentObj.data.imageData.image} style={{ height: "100%", width: "100%" }}></img>
                                                            </div>
                                                        ) : null
                                                    }

                                                    <div
                                                        style={{
                                                            display: 'grid',
                                                            gridTemplateColumns: 'auto auto',
                                                            gridTemplateRows: `repeat(${Math.ceil((contentObj.data.options.length) / 2)}, 10px)`,
                                                            gap: '0.1rem',
                                                            fontSize: '4px'
                                                        }}
                                                    >
                                                        {
                                                            contentObj.data.type === "single" ? (
                                                                contentObj.data.options.map((option, optionIndex) => {
                                                                    return (
                                                                        <div key={optionIndex} className="flex gap-1 border border-solid border-gray-300 items-center px-1 py-0 rounded-lg">
                                                                            <span style={{ fontSize: '4px' }} className="flex items-center pr-1 border-r border-solid border-gray-300 h-full text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                                            <span style={{ fontSize: '4px' }} className="w-4/5 outline-none " type="text">{option}</span>
                                                                        </div>
                                                                    )
                                                                })) : (
                                                                contentObj.data.options.map((option, optionIndex) => {
                                                                    return (
                                                                        <div className="flex gap-1 border border-solid border-gray-300 items-center px-1 py-0 rounded-lg" key={optionIndex}>
                                                                            <span style={{ fontSize: '4px' }} className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                                            <span style={{ fontSize: '4px' }} className="w-4/5 outline-none ">{option}</span>
                                                                        </div>
                                                                    )
                                                                }
                                                                ))
                                                        }
                                                    </div>
                                                </div>
                                                // <div className="w-full">
                                                //     <div className="w-full mb-1 border rounded-md p-1 text-xs min-h-20"  >{contentObj.data.question}</div>
                                                //     <div className="flex justify-between items-center mb-2">
                                                //     </div>
                                                //     {
                                                //         contentObj.data.imageData.image ? (
                                                //             <div style={{ width: contentObj.data.imageData.width || '400px', height: contentObj.data.imageData.height || '400px' }}>
                                                //                 <img src={contentObj.data.imageData.image} style={{ height: "100%", width: "100%" }}></img>
                                                //             </div>
                                                //         ) : (null)
                                                //     }
                                                //     {
                                                //         contentObj.data.type !== 'audio' ? (
                                                //             <>
                                                //                 <section
                                                //                     className="text-xs"
                                                //                     style={{
                                                //                         display: 'grid',
                                                //                         gridTemplateColumns: 'auto auto',
                                                //                         gridTemplateRows: `repeat(${Math.ceil((contentObj.data.options.length) / 2)}, 80px)`,
                                                //                         gap: '1rem'
                                                //                     }}
                                                //                 >
                                                //                     {
                                                //                         contentObj.data.type === 'single' ? (
                                                //                             contentObj.data.options.map((option, optionIndex) => {
                                                //                                 return (
                                                //                                     <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                //                                         <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500" >{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                //                                         <div className="w-4/5 outline-none text-xs" type="text" >{option}</div>
                                                //                                         <input className="text-xl" type="radio" name="radio" />
                                                //                                     </div>
                                                //                                 )
                                                //                             })
                                                //                         ) : (
                                                //                             contentObj.data.type === 'multiple' ? (
                                                //                                 contentObj.data.options.map((option, optionIndex) => {
                                                //                                     return (
                                                //                                         <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                //                                             <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                //                                             <div className="w-4/5 outline-none text-xs" type="text" >{option}</div>
                                                //                                             <input className="text-xl" type="checkbox" name="radio" />
                                                //                                         </div>
                                                //                                     )
                                                //                                 }
                                                //                                 )
                                                //                             ) : (
                                                //                                 null
                                                //                                 // return component for audio 
                                                //                             )
                                                //                         )
                                                //                     }
                                                //                 </section>
                                                //             </>
                                                //         ) : (null)
                                                //     }
                                                // </div>
                                            ) : (null)
                                        }
                                    </>


                                )
                            }

                            if (contentObj.type === "Image") {
                                console.log('url ', contentObj.data.imgData);
                                return (
                                    <div style={{ width: "100%", display: "flex", justifyContent: contentObj.data.align ? contentObj.data.align : 'center' }}>
                                        {contentObj.data.imgData && (
                                            <div style={{ width: contentObj.data.width ? '20px' : 'auto', height: contentObj.data.height ? '20px' : 'auto' }}>
                                                <img id={"imgId"} src={contentObj.data.imgData} style={{ height: "100%", width: "100%" }}></img>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                            if (contentObj.type === "Video") {
                                return (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {contentObj.data.ytData.videoId && (
                                            <img
                                                className='w-3/5 h-5 2xl:h-5'
                                                src={`https://img.youtube.com/vi/${contentObj.data.ytData.videoId}/hqdefault.jpg`}
                                                alt="YouTube Video Thumbnail"
                                                style={{ cursor: 'default' }}
                                            />
                                        )}
                                    </div>
                                );
                            }

                        })
                    }
                    <span className="absolute bottom-0 left-0 text-sm p-0.5">{slideIndex + 1}</span>
                </div>
            ) : (null)
        }

    </>);
}

export default Thumbnail;






