import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../../constants";

const AdminCreateTeacher = () => {

    const { schoolId } = useParams(); // Extract schoolId from URL params
    const navigate = useNavigate();
    const [file, setFile] = useState(null);

    const [formData, setFormData] = useState({
        // Initial form state
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        birthdate: "",
        email: "",
        contactNumber: "",
        alternativeNumber: "",
        aadharCardNumber: "",
        // panCard: "",
        permanentAddress: "",
        city: "",
        state: "",
        zipCode: "",
        // fatherName: "",
        // motherName: "",
        emergencyContactName: "",
        emergencyContactNumber: "",
        // classId: "",
        // subjectId: "",
    });

    const notify = () => toast.success("Creator Profile successfully created !!");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all required fields are filled
        const requiredFields = [
            "firstName",
            "lastName",
            "gender",
            "birthdate",
            "email",
            "contactNumber",
            "aadharCardNumber",
            // "panCard",
            "permanentAddress",
            "city",
            "state",
            "zipCode",
            // "fatherName",
            // "motherName",
            "emergencyContactName",
            "emergencyContactNumber",
            // Add other required fields as needed
        ];

        for (const field of requiredFields) {
            if (!formData[field]) {
                // If any required field is empty, show an error toast and return
                toast.error(`${field.replace(/([A-Z])/g, " $1")}: Field is required`, {
                    duration: 1000,
                });
                return;
            }
        }

        // Validate contact numbers for exactly 10 digits
        const contactFields = [
            "contactNumber",
            "alternativeNumber",
            "emergencyContactNumber",
        ];
        for (const field of contactFields) {
            if (formData[field] && formData[field].length !== 10) {
                toast.error(
                    `${field.replace(/([A-Z])/g, " $1")} must be exactly 10 digits`,
                    {
                        duration: 4000,
                    }
                );
                return;
            }
        }

        // Validate Aadhar card number for exactly 12 digits
        if (
            formData["aadharCardNumber"] &&
            formData["aadharCardNumber"].length !== 12
        ) {
            toast.error("Aadhar Card Number must be exactly 12 digits", {
                duration: 4000,
            });
            return;
        }

        // Create a new FormData object to handle form data
        const newFormData = new FormData();

        // Create an object 'data' by spreading the 'formData' and adding 'schoolId'
        const data = {
            ...formData,
            schoolId: schoolId,
        };

        // Convert the 'data' object to a JSON string
        const stringData = JSON.stringify(data);

        // Append the JSON string and the 'file' to the FormData object
        newFormData.append("TeacherData", stringData);
        newFormData.append("image", file);

        try {
            // Send a POST request to the specified URL with the form data
            const response = await axios.post(
                `${BASEURL}/api/add-creator/${schoolId}`,
                newFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            // Log success message and trigger notification
            console.log("Creator added successfully", response.data);
            notify();

            // Navigate to the specified URL upon success
            navigate(`/admin/allCreators/${schoolId}`);
        } catch (error) {
            // Log error message and display an error toast
            console.error("Error adding creator:", error.message);
            toast.error("Error adding creator");
        }
    };


    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Add Course Creator
                        </p>
                    </div>
                    <div class=" flex items-center justify-end gap-x-4">
                        <Link to={`/admin/allCreators/${schoolId}`}>
                            <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
                        </Link>

                        <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleSubmit}>Save</button>
                    </div>
                </div>

            </div>

            <form>
                <div class="space-y-12 px-4 mx-auto my-0">


                    <div class="border-b border-gray-900/10 pb-12">
                        <h2 class="text-md font-semibold leading-7 text-gray-900 mt-4  pl-2 my-2 border-l-4 font-sans border-blue-400">Personal Information</h2>
                        {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="sm:col-span-2 sm:col-start-1">
                                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First Name  <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="firstName" placeholder="Enter your first name" value={formData.firstName} onChange={handleChange} required autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="middle-name" class="block text-sm font-medium leading-6 text-gray-900">Middle Name</label>
                                <div class="mt-2">
                                    <input type="text" name="middleName" placeholder="Enter your middle name" value={formData.middleName} onChange={handleChange} required autocomplete="additional-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last Name <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your last name" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <select name="gender" value={formData.gender} onChange={handleChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                                        <option value="" disabled selected>
                                            Select Gender
                                        </option>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                        <option value='Others'>Others</option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="dob" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="birthdate" type="date" value={formData.birthdate} onChange={handleChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                <div class="mt-2">
                                    <input name="email" type="email" value={formData.email} onChange={handleChange} required autocomplete="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your email address" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="contact-number" class="block text-sm font-medium leading-6 text-gray-900">Contact Number <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="contactNumber" value={formData.contactNumber} onChange={handleChange} required type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your contact number" maxLength={10} />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="alt-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Alternate Contact Number</label>
                                <div class="mt-2">
                                    <input name="alternativeNumber" value={formData.alternativeNumber} onChange={handleChange} type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your alternate contact number" maxLength={10} />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="aadhar-card-number" class="block text-sm font-medium leading-6 text-gray-900">Aadhar Card Number <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="aadharCardNumber" value={formData.aadharCardNumber} onChange={handleChange} required type="tel" autocomplete="on" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your Aadhar card number" maxLength={12} />
                                </div>
                            </div>

                            {/* <div class="sm:col-span-2">
                                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Pancard Number <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input name="panCard" value={formData.panCard} onChange={handleChange} required type="tel" autocomplete="on" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your PAN card number" maxLength={10} />
                                </div>
                            </div> */}


                            <div class="sm:col-span-2">
                                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Profile Image <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="file"
                                        accept="image/*"
                                        onChange={handleFileChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                        </div>

                        <h2 class="text-md font-semibold leading-7 text-gray-900 mt-6  pl-2 my-2 border-l-4 font-sans border-blue-400">Address Details</h2>
                        {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div class="col-span-full">
                                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Permanent Address <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="permanentAddress" value={formData.permanentAddress} onChange={handleChange} required autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your permanent address" />
                                </div>
                            </div>

                            <div class="sm:col-span-2 sm:col-start-1">
                                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="city" value={formData.city} onChange={handleChange} required autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your city" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="region" class="block text-sm font-medium leading-6 text-gray-900">State <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="state" value={formData.state} onChange={handleChange} required autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your State" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="zipCode" value={formData.zipCode} onChange={handleChange} required autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your ZIP code" maxLength={6} />
                                </div>
                            </div>

                        </div>
                        <h2 class="text-md font-semibold leading-7 text-gray-900 mt-6  pl-2 my-2 border-l-4 font-sans border-blue-400">Other Details</h2>
                        {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="sm:col-span-2 sm:col-start-1">
                                <label for="emergency-contact-name" class="block text-sm font-medium leading-6 text-gray-900">Emergency Contact Name <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="text" name="emergencyContactName" value={formData.emergencyContactName} onChange={handleChange} required autocomplete="on" placeholder="Enter name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="emergency-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Emergency Contact Number <span className="text-red-600">*</span></label>
                                <div class="mt-2">
                                    <input type="tel" name="emergencyContactNumber" value={formData.emergencyContactNumber} onChange={handleChange} required autocomplete="tel" placeholder="Enter number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" maxLength={10} />
                                </div>
                            </div>


                        </div>
                    </div>



                    {/* ----------------------------------------------------- */}


                </div>

                {/* <div class="my-6 flex items-center justify-end gap-x-4">
                    <Link to={`/admin/allTeachers/${schoolId}`}>
                        <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
                    </Link>

                    <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleSubmit}>Save</button>
                </div> */}
            </form>
        </>
    )
}

export default AdminCreateTeacher