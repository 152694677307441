const Finish = ({ color , fill}) => {


    return (
        <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.996 53.2899C61.9144 44.0859 62.4935 28.5842 53.2895 18.6658C44.0855 8.74746 28.5838 8.16833 18.6654 17.3723C8.74704 26.5763 8.16792 42.078 17.3719 51.9964C26.5759 61.9148 42.0776 62.4939 51.996 53.2899Z" fill={fill} />
            <path d="M51.996 53.2899C61.9144 44.0859 62.4935 28.5842 53.2895 18.6658C44.0855 8.74746 28.5838 8.16833 18.6654 17.3723C8.74704 26.5763 8.16792 42.078 17.3719 51.9964C26.5759 61.9148 42.0776 62.4939 51.996 53.2899Z" stroke="#FFE0C1" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.541 28.8966C39.7322 29.0554 39.8524 29.2837 39.8754 29.5312C39.8983 29.7787 39.822 30.0252 39.6632 30.2165L30.1196 41.7029C30.0341 41.8058 29.9275 41.8893 29.8071 41.9476C29.6867 42.0059 29.5552 42.0378 29.4214 42.041C29.2876 42.0443 29.1547 42.0189 29.0316 41.9665C28.9084 41.9142 28.7979 41.836 28.7075 41.7374L24.6701 37.3347C24.5079 37.1505 24.4245 36.9101 24.4376 36.665C24.4507 36.42 24.5594 36.1899 24.7403 36.0241C24.9212 35.8583 25.1599 35.77 25.4051 35.7782C25.6504 35.7865 25.8826 35.8905 26.052 36.0681L29.3637 39.6795L38.221 29.0188C38.3799 28.8276 38.6082 28.7073 38.8557 28.6844C39.1032 28.6615 39.3497 28.7378 39.541 28.8966ZM45.8229 28.8494C46.2065 29.1964 46.2348 29.7896 45.8865 30.1732L35.4507 41.6815C35.3595 41.782 35.2476 41.8616 35.1227 41.9146C34.9978 41.9677 34.8629 41.993 34.7273 41.9888C34.5916 41.9847 34.4585 41.9511 34.3371 41.8904C34.2157 41.8298 34.1089 41.7435 34.0242 41.6376L33.4709 40.9471C33.326 40.7675 33.2524 40.5407 33.2643 40.3102C33.2762 40.0797 33.3727 39.8617 33.5353 39.698C33.6979 39.5342 33.9152 39.4361 34.1456 39.4226C34.376 39.4091 34.6033 39.481 34.784 39.6247L44.4978 28.913C44.6648 28.729 44.8981 28.6187 45.1463 28.6066C45.3946 28.5944 45.6375 28.6813 45.8216 28.8482" fill={`${color}`} />
        </svg>
    );
}

export default Finish;