
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURL } from "../../../constants";
import { Link, useParams } from "react-router-dom";
import SlideConsumption from "../Course Consumption/SlideConsumption";
import TestConsumption from "../Course Consumption/TestConsumption";
import Sidebar from '../Course Consumption/Sidebar';
import { ReactComponent as Toggle } from '../../assets/list svg 3.svg'

const CourseConsumption = () => {
    console.log("course consumption is called");

    const [testStartTime, setTestStartTime] = useState(null);
    const [attemptNumber, setAttemptNumber] = useState(null);
    const [isTestSubmitted, setIsTestSubmitted] = useState(false);
    const [showPassModal, setShowPassModal] = useState(false);
    const [showFailModal, setShowFailModal] = useState(false);


    // const [contentArray, setContentArray] = useState(null);
    // const [contentIndex, setContentIndex] = useState(null);

    const [currentSlideData, setCurrentSlideData] = useState(null);
    const [currentSlideId, setCurrentSlideId] = useState(null);
    const [slidesArray, setSlidesArray] = useState(null);

    const [questionsArray, setQuestionsArray] = useState(null);
    const [question, setQuestion] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [testTimeMinutes, setTestTimeMinutes] = useState(null);
    const [isTestStarted, setIsTestStarted] = useState(false);

    const [sidebarData, setSidebarData] = useState(null);
    const [topicName, setTopicName] = useState(null);
    const [subtopicName, setSubtopicName] = useState(null);
    const [testName, setTestName] = useState(null)

    const [selectedSubtopicId, setSelectedSubtopicId] = useState(null);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [courseInfo, setCourseInfo] = useState(null);
    const [startTime, setStartTime] = useState(new Date().toISOString().slice(0, 19).replace('T', ' '))

    // states for preview of content
    const [previous, setPrevious] = useState(false);
    const [imageIdArray, setImageIdArray] = useState(null)
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const { courseId, sharedCourseId } = useParams();

    useEffect(() => {
        axios.get(`${BASEURL}/api/get-student-sidebar-hierarchy`, {
            params: {
                courseId: courseId
            }
        }).then((response) => {
            console.log(response.data);

            const sidebarHierarchy = response.data;
            setSidebarData(response.data);
            if (sidebarHierarchy.topics[0].content[0].type === 'slide-group') {
                setTopicName(sidebarHierarchy.topics[0].name);
                setSelectedSubtopicId(sidebarHierarchy.topics[0].content[0].id);
                setSubtopicName(sidebarHierarchy.topics[0].content[0].name)
            } else {
                setTopicName(sidebarHierarchy.topics[0].name);
                setSelectedTestId(sidebarHierarchy.topics[0].content[0].id);
                setTestName(sidebarHierarchy.topics[0].content[0].name);
            }

            /*
                 * creating a contentArray  
                    for jumping slide-groups and tests 
                 */
            // let contentArray = [];
            // sidebarHierarchy.topics.forEach((topic) => {
            //     /* 
            //       topic ->  { id , name , content : [] }
            //     */
            //     if (topic.content.length) {
            //         topic.content.forEach((contentObject) => {
            //             /* 
            //                 contentObject -> { id , type , sequence , name }
            //             */
            //             contentArray.push(contentObject);
            //         })
            //     }
            // })

            // if (contentArray.length) {
            //     console.log('contentArray ', contentArray)
            //     setContentArray(contentArray);
            //     setContentIndex(0);
            // } else {
            //     setContentArray([]);
            // }
        })
    }, [])

    useEffect(() => {
        if (sidebarData) {
            console.log('running useEffect')
            if (selectedSubtopicId) {
                console.log('selectedSubtopicId', selectedSubtopicId);
                axios.get(`${BASEURL}/api/get-sub-topic-data`, {
                    params: {
                        subTopicId: selectedSubtopicId
                    }
                }).then((response) => {
                    console.log('response  ', response);
                    //----------------------------------------------------
                    let slidesArray = response.data.map((slide) => {
                        return { ...slide, finished: false }
                    });
                    const arrayOfImageIdArray = slidesArray.map((slideObject, index) => {
                        if (slideObject.imageIdArray) {
                            return slideObject.imageIdArray;
                        } else {
                            return []
                        }
                    })

                    console.log('arrayOfImageIdArray', arrayOfImageIdArray)
                    //array of imageIdArray will have an empty array for slides which dont have any images so it will always have length

                    const imageKeysArray = arrayOfImageIdArray.reduce((acc, item) => acc.concat(item));
                    console.log('images to be fetched array', imageKeysArray);
                    //if imageKeysArray is empty there are no images to be fetched just set the data
                    if (imageKeysArray.length) {
                        const imagesPromiseArray = imageKeysArray.map((key, index) => {
                            return axios.get(`${BASEURL}/api/get-image`, {
                                params: {
                                    imageId: key,
                                    index: index
                                }
                            })
                        })
                        Promise.all(imagesPromiseArray)
                            .then((imageResponse) => {
                                //response will be array of array ? [ { data : { dataUrl : '' } } {} ]

                                axios.get(`${BASEURL}/api/get-finished-slides`, {
                                    params: {
                                        subTopicId: selectedSubtopicId
                                    }
                                }).then((finishedResponse) => {
                                    console.log("finishSlide response ", finishedResponse)

                                    console.log('imageResponse', imageResponse);
                                    imageResponse.forEach((resObject, index) => {
                                        const imageUrl = resObject.data.dataUrl;
                                        const contentObjectId = imageKeysArray[index];

                                        //how to find slideIndex 
                                        //the slide where the key is present in the imageIdArray 
                                        const slideIndex = slidesArray.findIndex(slide => slide.imageIdArray.includes(contentObjectId));
                                        //find the contentObjectIndex 
                                        const targetSlide = slidesArray[slideIndex];
                                        const contentObjectIndex = targetSlide.content.findIndex(contentObject => contentObject.id === contentObjectId);

                                        if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Image') {
                                            slidesArray[slideIndex].content[contentObjectIndex].data.imgData = imageUrl;

                                        }
                                        if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Quiz') {
                                            slidesArray[slideIndex].content[contentObjectIndex].data.imageData.image = imageUrl;
                                        }
                                    })
                                    slidesArray = slidesArray.map((slide) => {
                                        if (finishedResponse.data.includes(slide.id)) {
                                            return { ...slide, finished: true }
                                        } else {
                                            return slide
                                        }
                                    })
                                    setSlidesArray(slidesArray);
                                    if (previous) {
                                        setPrevious(false);
                                        setCurrentSlideId(slidesArray[slidesArray.length - 1].id);
                                        setCurrentSlideData(slidesArray[slidesArray.length - 1]);
                                    } else {
                                        setCurrentSlideId(slidesArray[0].id);
                                        setCurrentSlideData(slidesArray[0]);
                                    }
                                })


                            })
                    } else {

                        axios.get(`${BASEURL}/api/get-finished-slides`, {
                            params: {
                                subTopicId: selectedSubtopicId
                            }
                        }).then((finishedResponse) => {
                            console.log("finishSlide response ", finishedResponse)
                            slidesArray = slidesArray.map((slide) => {
                                if (finishedResponse.data.includes(slide.id)) {
                                    return { ...slide, finished: true }
                                } else {
                                    return slide
                                }
                            })
                            // [ { slide_id  , sub_topic_id } ]
                            console.log("slidesArray", slidesArray);
                            setSlidesArray(slidesArray);
                            if (previous) {
                                setPrevious(false);
                                setCurrentSlideId(slidesArray[slidesArray.length - 1].id);
                                setCurrentSlideData(slidesArray[slidesArray.length - 1]);
                            } else {
                                setCurrentSlideId(slidesArray[0].id);
                                setCurrentSlideData(slidesArray[0]);
                            }
                        })

                    }
                })
            } else {
                //this will execute on mount as well as on subtopic delete
                setSlidesArray(null);
                setCurrentSlideData(null);
                setCurrentSlideId(null);
            }
            // publish time pe we will calculate the video count and the quiz count.
            if (selectedTestId) {
                console.log('getting test data as testId was resetted')
                axios.get(`${BASEURL}/api/get-test-data`, {
                    params: {
                        testId: selectedTestId
                    }
                }).then((response) => {

                    axios.get(`${BASEURL}/api/get-test-duration`, {
                        params: {
                            testId: selectedTestId
                        }
                    }).then((getDurationResponse) => {
                        //response should be questionsArray 
                        //well we are not setting anything here 
                        const questionsArray = response.data.map((question) => {
                            return { ...question, attempted: false, answers: [] }
                        });
                        console.log("questionsArray to be setted", questionsArray);
                        const { testTimeHours, testTimeMinutes, numberOfQuestions } = getDurationResponse.data[0];
                        //[{ } , { } ]
                        const imagekeyArray = questionsArray.map((question) => {
                            if (question.image_data.image) {
                                return question.image_key
                            }
                        }).filter((key) => key)

                        if (imagekeyArray.length) {
                            const imagePromisesArray = imagekeyArray.map((key) => {
                                if (key) {
                                    return axios.get(`${BASEURL}/api/get-image`, {
                                        params: {
                                            imageId: key
                                        }
                                    })
                                }
                            })

                            Promise.all(imagePromisesArray)
                                .then((imagesResponse) => {
                                    //[{ dataUrl: urlObject, status: "success" }]

                                    imagesResponse.forEach((responseObj, index) => {
                                        const key = imagekeyArray[index];
                                        const questionIndex = questionsArray.findIndex(question => question.image_key === key);
                                        questionsArray[questionIndex] = { ...questionsArray[questionIndex], image_data: { ...questionsArray[questionIndex].image_data, image: responseObj.data.dataUrl } }
                                    })
                                    setQuestionsArray(questionsArray);
                                    if (previous) {
                                        setPrevious(false);
                                        setQuestion(questionsArray[questionsArray.length - 1]);
                                        setCurrentQuestionId(questionsArray[questionsArray.length - 1].id);
                                    } else {
                                        setQuestion(questionsArray[0]);
                                        setCurrentQuestionId(questionsArray[0].id);
                                    }
                                    setTestTimeMinutes(testTimeMinutes ? testTimeMinutes : 0);
                                })
                        } else {
                            console.log('setting test data', questionsArray)
                            setQuestionsArray(questionsArray);
                            if (previous) {
                                console.log("inside previos")
                                setPrevious(false);
                                setQuestion(questionsArray[questionsArray.length - 1]);
                                setCurrentQuestionId(questionsArray[questionsArray.length - 1].id);
                            } else {
                                console.log("setting properly the question", questionsArray[0])
                                setQuestion(questionsArray[0]);
                                setCurrentQuestionId(questionsArray[0].id);
                            }
                            setTestTimeMinutes(testTimeMinutes ? testTimeMinutes : 0);
                        }
                    })
                })
            } else {
                setQuestionsArray(null);
                setQuestion(null);
                setCurrentQuestionId(null);
            }
        }
    }, [selectedSubtopicId, selectedTestId])

    useEffect(() => {
        axios.get(`${BASEURL}/api/fetch-course-info`, {
            params: {
                courseId: courseId
            }
        }).then((res) => {
            setCourseInfo(res.data.rows)
        })
    }, [])

    function markFinish(slideId) {

        axios.post(`${BASEURL}/api/finish-slide`, {
            courseId: sharedCourseId,
            slideId: slideId,
            startTime: startTime,
            finishTime: new Date().toISOString().replace('T', ' ').replace('Z', '')
        }).then((response) => {
            //slides array 
            const newSlidesArray = slidesArray.map((slide) => {
                if (slide.id === currentSlideId) {
                    return { ...slide, finished: true }
                } else {
                    return slide
                }
            })

            setSlidesArray(newSlidesArray);
            setCurrentSlideData((currentSlide) => {
                return { ...currentSlide, finished: true }
            })

            let sendFinishSubTopicRequest = true;

            for (let slide of newSlidesArray) {
                if (!slide.finished) {
                    sendFinishSubTopicRequest = false;
                    break;  // This will break out of the loop
                }
            }

            if(sendFinishSubTopicRequest){
                axios.post(`${BASEURL}/api/mark-sub-topic-finished` ,{ 
                    courseId: sharedCourseId,
                    subTopicId: selectedSubtopicId,
                    finishTime: new Date().toISOString().slice(0 , 19).replace('T' , ' ')
                }).then((response) => {
                    console.log("response" , response);
                    setSidebarData((sidebarData) => {
                        const newSidebarData = { topics : sidebarData.topics.map((topic) =>{
                            return { ...topic , content: topic.content.map((contentObj) => {
                                if(contentObj.id === selectedSubtopicId){
                                    return {...contentObj , status : "complete"}
                                }else {
                                    return contentObj;
                                }
                            })}
                        })}

                        return newSidebarData;
                    })
                })
            }
        })
    }
    return (
        <div className="h-dvh flex flex-col" style={{ backgroundImage: 'linear-gradient(179deg, #393185 13%, rgba(117, 112, 171, 0.68) 77%, rgba(247, 248, 252, 0) 103%)' }}>
            <section className='my-3'>
                <nav className='flex justify-between p-2 items-center relative'>
                    <div className="flex items-center ">
                        <button >
                            {
                                isTestStarted ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                ) : (
                                    <Link to={`/CourseDetails/${courseId}/${sharedCourseId}`} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>
                                    </Link>
                                )
                            }
                        </button>
                        <span className="absolute text-base text-white left-1/2" style={{ transform: 'translate(-50%)' }}>
                            {
                                courseInfo?.course_name
                            }
                        </span>
                    </div>
                    <span onClick={() => {
                        if (isTestStarted) {
                            return;
                        }
                        setSidebarToggle((sidebarToggle) => {
                            return !sidebarToggle
                        })
                    }} className="cursor-pointer text-white">
                        <div className='p-2 rounded-full' style={{ backgroundColor: '#544ca5' }}>
                            <Toggle />
                        </div>
                    </span>
                </nav>
            </section>
            <Sidebar
                setTestName={setTestName}
                setSelectedSubtopicId={setSelectedSubtopicId}
                setSelectedTestId={setSelectedTestId}
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
                sidebarData={sidebarData}
                selectedSubtopicId={selectedSubtopicId}
                setTopicName={setTopicName}
                setSubtopicName={setSubtopicName}
                setTestStartTime={setTestStartTime}
                setAttemptNumber={setAttemptNumber}
                attemptNumber={attemptNumber}
                setIsTestStarted={setIsTestStarted}
                setIsTestSubmitted={setIsTestSubmitted}
                setShowPassModal={setShowPassModal}
                setShowFailModal={setShowFailModal}
            />
            <section className="grow">
                {
                    selectedTestId ? (
                        questionsArray && <TestConsumption
                            topicName={topicName}
                            testName={testName}
                            questionsArray={questionsArray}
                            currentQuestionId={currentQuestionId}
                            question={question}
                            setCurrentQuestionId={setCurrentQuestionId}
                            setQuestion={setQuestion}
                            testTimeMinutes={testTimeMinutes}
                            setQuestionsArray={setQuestionsArray}
                            selectedTestId={selectedTestId}
                            courseId={courseId}
                            sharedCourseId={sharedCourseId}
                            testStartTime={testStartTime}
                            attemptNumber={attemptNumber}
                            setSidebarToggle={setSidebarToggle}
                            setIsTestStarted={setIsTestStarted}
                            isTestSubmitted={isTestSubmitted}
                            setIsTestSubmitted={setIsTestSubmitted}
                            showPassModal={showPassModal}
                            setShowPassModal={setShowPassModal}
                            showFailModal={showFailModal}
                            setShowFailModal={setShowFailModal}
                        />
                    ) : (
                        slidesArray && (
                            <SlideConsumption
                                currentSlideData={currentSlideData}
                                topicName={topicName}
                                subtopicName={subtopicName}
                                slidesArray={slidesArray}
                                currentSlideId={currentSlideId}
                                setCurrentSlideId={setCurrentSlideId}
                                setCurrentSlideData={setCurrentSlideData}
                                // setContentIndex={setContentIndex}
                                // contentArray={contentArray}
                                // contentIndex={contentIndex}
                                setPrevious={setPrevious}
                                setSelectedSubtopicId={setSelectedSubtopicId}
                                setSelectedTestId={setSelectedTestId}
                                setStartTime={setStartTime}
                                markFinish={markFinish}
                            />
                        )
                    )
                }

            </section>
        </div>

    );
}

export default CourseConsumption;