import React from 'react'
import { jwtDecode } from "jwt-decode";
import { Link } from 'react-router-dom'


const DiactoAdminHome = () => {

    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);
    const firstName = decoded.first_name;


    return (
        <section className='h-full m-auto' style={{ width: '95%' }} >
            <div className="flex flex-col" >
                <div className="flex flex-col gap-3.5 pt-5 mx-5" >
                    <h1 class="text-2xl font-semibold  text-gray-800 capitalize lg:text-3xl">Welcome <span class="text-blue-500">{firstName}</span>,</h1>
                    <h1 class="text-xl font-semibold  text-gray-800 capitalize lg:text-2xl">Use <span class="text-blue-500">BlissIQ
                    </span> to create the best teaching aide in curriculum on holistic education.</h1>
                </div>

                <div>
                    <div class="container px-6 py-10 mx-auto">
                        <h1 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">explore our <br /> awesome <span class="text-blue-500">Features</span></h1>

                        <div class="grid grid-cols-1 gap-8 mt-20 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3 ">
                            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-white rounded-xl dark:bg-gray-800">
                                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="w-5 h-5 font-bold">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                </span>

                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Create Courses</h1>

                                <p class="text-gray-500 dark:text-gray-300 h-4/5">
                                    Easily design and structure your courses with our intuitive course creation tools. Add multimedia content, interactive quizzes, and engaging assignments to create a comprehensive learning experience for your students.
                                </p>

                                <Link to="/diactoAdmin/courses" class="flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                                    <span class="mx-1">know more</span>
                                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>


                                </Link>
                            </div>

                            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-white rounded-xl dark:bg-gray-800">
                                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                                    </svg>

                                </span>

                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Share Courses</h1>

                                <p class="text-gray-500 dark:text-gray-300 h-4/5">
                                    Share your expertly crafted courses with schools and students effortlessly. Our platform supports seamless sharing, allowing you to collaborate with other educators and reach a wider audience.
                                </p>

                                <Link to="/diactoAdmin/courses" class="flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                                    <span class="mx-1">know more</span>
                                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </Link>
                            </div>

                            <div class="flex flex-col items-center p-6 space-y-3 text-center bg-white rounded-xl dark:bg-gray-800">
                                <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                </span>

                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">Monitor progress</h1>

                                <p class="text-gray-500 dark:text-gray-300 h-4/5">
                                Keep track of your school's progress with our robust monitoring tools. Analyze performance data, provide feedback, and ensure that each aspect of your institution is on the right path to success.
                                </p>

                                <Link to="/diactoAdmin/allSchools" class="flex items-center -mx-1 text-sm text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                                    <span class="mx-1">know more</span>
                                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default DiactoAdminHome