

const TopicSubTopicView = ({nameOne , nameTwo}) => {
    return ( 
        <div className="text-white p-3">
            {`${nameOne} / ${nameTwo}`}
        </div>
     );
}
 
export default TopicSubTopicView;