import axios from "axios";
import { BASEURL } from "../../../constants";
import { ReactComponent as TestSVG } from '../../assets/Test svg.svg'
import { ReactComponent as SlideCard } from '../../assets/Slide Card.svg'
import { useEffect, useState } from 'react';

const TestModal = ({ attemptNumber, setIsTestSubmitted, setShowPassModal, setShowFailModal, setIsTestStarted, setAttemptNumber, setTestStartTime, setSidebarToggle, setTestModal, testId, setSelectedTestId, setSelectedSubtopicId }) => {
    const [testDetails, setTestDetails] = useState(null);

    useEffect(() => {
        axios.get(`${BASEURL}/api/get-test-info`, {
            params: {
                testId: testId
            }
        }).then((response) => {
            // no of questions , duration , points 
            console.log('response for the test modal', response);
            const { attempted } = response.data;

            if (attempted) {
                const { passOrFail } = response.data;
                if (passOrFail === 'fail') {
                    //show the start modal and set the attempt number 
                    setTestDetails({ timeLimit: response.data.duration, numberOfQuestions: response.data.numberOfQuestions, attempted: true, passOrFail: 'fail' })
                    setAttemptNumber(response.data.attemptNo);
                }

                if (passOrFail === 'pass') {
                    //show the passed modal 
                    setTestDetails({ timeLimit: response.data.duration, numberOfQuestions: response.data.numberOfQuestions, attempted: true, passOrFail: 'pass' })

                }
            } else {
                //attempted is false
                setAttemptNumber(0);
                setTestDetails({ timeLimit: response.data.duration, numberOfQuestions: response.data.numberOfQuestions, attempted: false })

            }
            // { attempted: true, passOrFail: result[0].pass_fail , attemptNo:result[0].attempt ,  duration: results[0].time_limit_minutes, numberOfQuestions: questionCountResult[0].numberOfQuestions, }

            /**
             * 
             * 
             *  attempted false -- start modal 
             * attempted true -- fail -- set the attempt and show the start modal 
             * attempted true -- passes -- show the passs modal 
             */
        })
    }, [])

    if (testDetails) {
        if (testDetails.attempted) {
            if (testDetails.passOrFail === 'fail') {
                return (
                    <div className="relative  z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                        <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                        <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                                <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                                    <div className="flex justify-end" >
                                        <svg onClick={() => { setTestModal({ showModal: false, testId: null }) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    <div className='flex w-full justify-center'>
                                        <div className="w-12 h-12 rounded-full flex justify-center items-center" style={{ backgroundColor: '#dfdcf9' }} >
                                            <TestSVG className="w-8 h-8" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center my-4 py-2 px-3">
                                        <SlideCard />
                                        <div className="flex w-full text-xs justify-between">
                                            <div className="flex flex-col items-center">
                                                <div className="font-bold text-base">{testDetails?.numberOfQuestions ? testDetails.numberOfQuestions : '10'}</div>
                                                <div >No of Question</div>
                                            </div>
                                            <div className="flex flex-col items-center">
                                                <div className="font-bold text-base">{testDetails?.timeLimit ? testDetails.timeLimit : '30'} minutes</div>
                                                <div>Duration</div>
                                            </div>
                                            <div className="flex flex-col items-center">
                                                <div className="font-bold text-base">100</div>
                                                <div>Points</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center font-bold">
                                        Failed, On Attempt {attemptNumber}
                                    </div>
                                    <div className="flex justify-around">
                                        <button onClick={() => {
                                            setSelectedTestId((selectedTestId) => {
                                                return testId;
                                            });
                                            setSelectedSubtopicId(null);
                                            setTestModal({ showModal: false, testId: null });
                                            setSidebarToggle(false);
                                            const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
                                            setTestStartTime(currentDateTime);
                                            setIsTestStarted(true)
                                            setIsTestSubmitted(false);
                                            setShowPassModal(false);
                                            setShowFailModal(false);
                                        }} className="w-24 h-10 rounded-xl text-white font-semibold" style={{ backgroundColor: '#fa9248' }}>Try Again</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            if (testDetails.passOrFail === 'pass') {
                return (
                    <div className="relative  z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                        <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                        <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                                <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                                    <div className="flex justify-end" >
                                        <svg onClick={() => { setTestModal({ showModal: false, testId: null }) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    <div className='flex w-full justify-center'>
                                        <div className="w-12 h-12 rounded-full flex justify-center items-center" style={{ backgroundColor: '#dfdcf9' }} >
                                            <TestSVG className="w-8 h-8" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center my-4 py-2 px-3">
                                        <SlideCard />
                                        <div className="flex w-full text-xs justify-between">
                                            <div className="flex flex-col items-center">
                                                <div className="font-bold text-base">{testDetails?.numberOfQuestions ? testDetails.numberOfQuestions : '10'}</div>
                                                <div >No of Question</div>
                                            </div>
                                            <div className="flex flex-col items-center">
                                                <div className="font-bold text-base">{testDetails?.timeLimit ? testDetails.timeLimit : '30'} minutes</div>
                                                <div>Duration</div>
                                            </div>
                                            <div className="flex flex-col items-center">
                                                <div className="font-bold text-base">100</div>
                                                <div>Points</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center font-bold">
                                        Passed!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="relative  z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                            <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                                <div className="flex justify-end" >
                                    <svg onClick={() => { setTestModal({ showModal: false, testId: null }) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <div className='flex w-full justify-center'>
                                    <div className="w-12 h-12 rounded-full flex justify-center items-center" style={{ backgroundColor: '#dfdcf9' }} >
                                        <TestSVG className="w-8 h-8" />
                                    </div>
                                </div>
                                <div className="flex flex-col items-center my-4 py-2 px-3">
                                    <SlideCard />
                                    <div className="flex w-full text-xs justify-between">
                                        <div className="flex flex-col items-center">
                                            <div className="font-bold text-base">{testDetails?.numberOfQuestions ? testDetails.numberOfQuestions : '10'}</div>
                                            <div >No of Question</div>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="font-bold text-base">{testDetails?.timeLimit ? testDetails.timeLimit : '30'} minutes</div>
                                            <div>Duration</div>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="font-bold text-base">100</div>
                                            <div>Points</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-around">
                                    <button onClick={() => {
                                        setSelectedTestId(testId);
                                        setSelectedSubtopicId(null);
                                        setTestModal({ showModal: false, testId: null });
                                        setSidebarToggle(false);
                                        const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
                                        setTestStartTime(currentDateTime);
                                        setIsTestStarted(true);
                                        setShowPassModal(false);
                                        setShowFailModal(false);
                                    }} className="w-24 h-10 rounded-xl text-white font-semibold" style={{ backgroundColor: '#fa9248' }}>Start Test</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="relative  z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                        <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                            Loading ...
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestModal;