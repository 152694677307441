import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../../constants";

const AdminCreateStudent = () => {

  const { schoolId } = useParams(); // Extract schoolId from URL params
  const navigate = useNavigate();

  // Add a new state variable to store the selected mentor
  const [file, setFile] = useState(null);
  const [mentors, setMentors] = useState([]);
  const [classes, setClasses] = useState([]);



  const [formData, setFormData] = useState({
    // Initial form state
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    birthdate: "",
    email: "",
    contactNumber: "",
    alternativeNumber: "",
    aadharCardNumber: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    fatherName: "",
    fatherContactNumber: "",
    fatherEmail: "",
    motherName: "",
    motherContactNumber: "",
    motherEmail: "",
    guardianName: "",
    guardianNumber: "",
    guardianEmail: "",
    mentorId: "",
    classId: "",
  });

  const notify = () => toast.success("Student profile successfully created !!");

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const response = await axios.get(`${BASEURL}/api/get-mentors/${schoolId}`);
        setMentors(response.data.mentors);
      } catch (error) {
        console.error("Error fetching mentors:", error.message);
      }
    };

    fetchMentors();
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(`${BASEURL}/api/get-classes/${schoolId}`);
        setClasses(response.data.classes);
      } catch (error) {
        console.error("Error fetching classes:", error.message);
      }
    };

    fetchClasses();
  }, []);



  const handleMentorChange = (e) => {
    const { value } = e.target;
    console.log("VALUE", value);

    setFormData((prevData) => ({
      ...prevData,
      mentorId: value,
    }));
  };

  const handleClassChange = (e) => {
    const { value } = e.target;
    console.log("CLASS", value);

    setFormData((prevData) => ({
      ...prevData,
      classId: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "firstName",
      "lastName",
      "gender",
      "birthdate",
      // "email",
      "contactNumber",
      "aadharCardNumber",
      "address",
      "city",
      "state",
      "zipCode",
      "fatherName",
      "fatherContactNumber",
      "motherName",
      "motherContactNumber",
      "mentorId", 
      "classId", 
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        // Corrected the display of field names in the error message
        toast.error(
          `${field
            .replace(/([A-Z])/g, " $1")
            .charAt(0)
            .toUpperCase() + field.slice(1)
          }: Field is required`,
          {
            duration: 1000,
          }
        );
        return;
      }
    }

    // Validate contact numbers for exactly 10 digits
    const contactFields = [
      "contactNumber",
      "alternativeNumber",
      "fatherContactNumber",
      "motherContactNumber",
      "guardianNumber",
    ];
    for (const field of contactFields) {
      if (formData[field] && formData[field].length !== 10) {
        toast.error(
          `${field.replace(/([A-Z])/g, " $1")} must be exactly 10 digits`,
          {
            duration: 4000,
          }
        );
        return;
      }
    }

    // Validate Aadhar card number for exactly 12 digits
    if (
      formData["aadharCardNumber"] &&
      formData["aadharCardNumber"].length !== 12
    ) {
      toast.error("Aadhar Card Number must be exactly 12 digits", {
        duration: 4000,
      });
      return;
    }
    // Create a new FormData object to handle form data
    const newFormData = new FormData();

    // Create an object 'data' by spreading the 'formData' and adding 'schoolId'
    const data = {
      ...formData,
      schoolId: schoolId,
    };

    // Convert the 'data' object to a JSON string
    const stringData = JSON.stringify(data);

    // Append the JSON string and the 'file' to the FormData object
    newFormData.append("studentData", stringData);
    newFormData.append("image", file);

    try {
      // Send a POST request to the specified URL with the form data
      const response = await axios.post(
        `${BASEURL}/api/add-student/${schoolId}`,
        newFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Log success message and trigger notification
      console.log("Student added successfully", response.data);
      notify();

      // Navigate to the specified URL upon success
      navigate(`/admin/allStudents/${schoolId}`);
    } catch (error) {
      // Log error message and display an error toast
      console.error("Error adding student profile:", error.message);
      toast.error("Error adding student profile");
    }
  };


  return (
    <>
      <div className='mx-4 border-b border-gray-200'>
        <div className='pt-2 pb-3 flex justify-between'>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
              Add Student
            </p>
          </div>
          <div class="flex items-center justify-end gap-x-4">
          <Link to={`/admin/allStudents/${schoolId}`}>
            <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
          </Link>

          <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleSubmit}>Save</button>
        </div>
        </div>
      </div>

      <form>
        <div class="space-y-12 px-4 mx-auto my-0">


          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-md font-semibold leading-7 text-gray-900 mt-4  pl-2 my-2 border-l-4 font-sans border-blue-400">Personal Information</h2>
            {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-2 sm:col-start-1">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="firstName" placeholder="Enter your first name" value={formData.firstName} onChange={handleChange} required autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="middle-name" class="block text-sm font-medium leading-6 text-gray-900">Middle Name</label>
                <div class="mt-2">
                  <input type="text" name="middleName" placeholder="Enter your middle name" value={formData.middleName} onChange={handleChange} required autocomplete="additional-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your last name" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <select name="gender" value={formData.gender} onChange={handleChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                    <option value='' disabled>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Others'>Others</option>
                  </select>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="dob" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="birthdate" type="date" value={formData.birthdate} onChange={handleChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div class="mt-2">
                  <input name="email" type="email" value={formData.email} onChange={handleChange} required autocomplete="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your email address" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="contact-number" class="block text-sm font-medium leading-6 text-gray-900">Contact Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="contactNumber" value={formData.contactNumber} onChange={handleChange} required type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your contact number"  maxLength={10}/>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="alt-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Alternate Contact Number</label>
                <div class="mt-2">
                  <input name="alternativeNumber" value={formData.alternativeNumber} onChange={handleChange} type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your alternate contact number"  maxLength={10}/>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="aadhar-card-number" class="block text-sm font-medium leading-6 text-gray-900">Aadhar Card Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="aadharCardNumber" value={formData.aadharCardNumber} onChange={handleChange} required type="tel" autocomplete="on" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your Aadhar card number" maxLength={12}/>
                </div>
              </div>


              <div class="sm:col-span-2">
                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Select Mentor <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <select name="mentorName" value={formData.mentorName} onChange={handleMentorChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                    <option value="" disabled selected>
                      Select mentor
                    </option>
                    {mentors.map((mentor) => (
                      <option key={mentor.id} value={mentor.id}>
                        {mentor.mentor_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Select Class <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <select name="class" value={formData.classId} onChange={handleClassChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                    <option value="" disabled selected>
                      Select class
                    </option>
                    {classes.map((classItem) => (
                      <option
                        key={classItem.class_id}
                        value={classItem.class_id}
                      >
                        {classItem.class_id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>


              <div class="sm:col-span-2">
                <label for="pancard-number" class="block text-sm font-medium leading-6 text-gray-900">Profile Image <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="file"
                    accept="image/*"
                    onChange={handleFileChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

            </div>

            <h2 class="text-md font-semibold leading-7 text-gray-900 mt-6  pl-2 my-2 border-l-4 font-sans border-blue-400">Address Details</h2>
            {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Permanent Address <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="address" value={formData.address} onChange={handleChange} required autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your permanent address" />
                </div>
              </div>

              <div class="sm:col-span-2 sm:col-start-1">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="city" value={formData.city} onChange={handleChange} required autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your city" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="region" class="block text-sm font-medium leading-6 text-gray-900">State <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="state" value={formData.state} onChange={handleChange} required autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your State" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="zipCode" value={formData.zipCode} onChange={handleChange} required autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your ZIP code" maxLength={6}/>
                </div>
              </div>

            </div>
            <h2 class="text-md font-semibold leading-7 text-gray-900 mt-6  pl-2 my-2 border-l-4 font-sans border-blue-400">Family Details</h2>
            {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div class="sm:col-span-2 sm:col-start-1">
                <label for="father-name" class="block text-sm font-medium leading-6 text-gray-900">Father Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="fatherName" value={formData.fatherName} onChange={handleChange} required autocomplete="on" placeholder="Enter father's name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="father-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Emergency Contact Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="tel" name="fatherContactNumber" value={formData.fatherContactNumber} onChange={handleChange} required autocomplete="tel" placeholder="Enter father's contact number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" maxLength={10} />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="father-email" class="block text-sm font-medium leading-6 text-gray-900">Father Email</label>
                <div class="mt-2">
                  <input type="email" name="fatherEmail" value={formData.fatherEmail} onChange={handleChange} required autocomplete="email" placeholder="Enter father's email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="mother-name" class="block text-sm font-medium leading-6 text-gray-900">Mother Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="motherName" value={formData.motherName} onChange={handleChange} required autocomplete="on" placeholder="Enter mother's name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="mother-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Mother Contact Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="tel" name="motherContactNumber" value={formData.motherContactNumber} onChange={handleChange} required autocomplete="tel" placeholder="Enter mother's contact number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" maxLength={10}/>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="mother-email" class="block text-sm font-medium leading-6 text-gray-900">Mother Email</label>
                <div class="mt-2">
                  <input type="email" name="motherEmail" value={formData.motherEmail} onChange={handleChange} required autocomplete="email" placeholder="Enter mother's email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="guardian-name" class="block text-sm font-medium leading-6 text-gray-900">Guardian Name</label>
                <div class="mt-2">
                  <input type="text" name="guardianName" value={formData.guardianName} onChange={handleChange} required autocomplete="on" placeholder="Enter guardian's name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="guardian-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Guardian Contact Number</label>
                <div class="mt-2">
                  <input type="tel" name="guardianNumber" value={formData.guardianNumber} onChange={handleChange} required autocomplete="tel" placeholder="Enter guardian's contact number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" maxLength={10}/>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="guardian-email" class="block text-sm font-medium leading-6 text-gray-900">Guardian Email</label>
                <div class="mt-2">
                  <input type="email" name="guardianEmail" value={formData.guardianEmail} onChange={handleChange} required autocomplete="email" placeholder="Enter guardian's email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

            </div>

          </div>



          {/* ----------------------------------------------------- */}


        </div>

        {/* <div class="my-6 flex items-center justify-end gap-x-4">
          <Link to={`/admin/allStudents/${schoolId}`}>
            <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
          </Link>

          <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleSubmit}>Save</button>
        </div> */}
      </form>
    </>
  )
}

export default AdminCreateStudent