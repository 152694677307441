const DeleteCourseModal = ({ showDeleteModal, setShowDeleteModal, deleteCourse }) => {

    return (
        <>
            {
                showDeleteModal.show && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400" >


                        <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                            <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                                    <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                        <div className='flex w-full justify-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                            </svg>
                                        </div>
                                        <div className="flex justify-center py-2 px-3">
                                            <span className='font-bold'>Are you sure?</span>

                                        </div>
                                        <div className="flex justify-center py-2 px-3">
                                            <span className='text-xs text-gray-600'>Do you really want to delete this?This action cannot be undone.</span>

                                        </div>
                                        <div className="flex justify-around">
                                            <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowDeleteModal({ show: false, courseId: null, imported: false }) }}>Cancel</button>
                                            <button onClick={() => { deleteCourse(showDeleteModal.courseId); }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Yes, Delete it!</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
}

export default DeleteCourseModal;