// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useNavigate, Link } from "react-router-dom";
// import { BASEURL } from "../../../constants";
// import toast from 'react-hot-toast';
// // import coverPhoto from '../assets/coverPhoto.webp';
// import DiactoPublishCourseModal from './DiactoPublishCourseModal'
// import DeleteCourseModal from "../../Teacher/DeleteCourseModal";



// const DiactoDraftCourses = ({ searchTerm }) => {
//     const [userCourses, setUserCourses] = useState([]);

//     const [showDeleteModal, setShowDeleteModal] = useState({
//         state: false,
//         courseId: null,
//     });
//     const [filteredData, setFilteredData] = useState([]); // Filtered school data for display

//     const navigate = useNavigate();

//     const [dropdownState, setDropdownState] = useState({}); // State to track each dropdown's open state

//     const [showPublishModal, setShowPublishModal] = useState(false);

//     const [coverImages, setCoverImages] = useState({});



//     function checkImageExists(imageUrl) {
//         return new Promise((resolve, reject) => {
//             const image = new Image();
//             image.onload = () => resolve();
//             image.onerror = () => reject();
//             image.src = imageUrl;
//         });
//     }

//     const toggleDropdown = (courseId) => {
//         setDropdownState(prevState => ({
//             ...prevState,
//             [courseId]: !prevState[courseId]
//         }));
//     };

//     const handlePublishClick = (courseId) => {
//         setShowPublishModal({ state: true, courseId });
//     };

//     useEffect(() => {
//         const fetchCourses = async () => {
//             try {
//                 const userId = localStorage.getItem('auth');
//                 if (userId) {
//                     const response = await axios.post(`${BASEURL}/api/fetch-courses`, {
//                         user_id: userId,
//                     });

//                     const courseData = response.data.courseData;
//                     const draftCourses = courseData.filter(course =>
//                         ((course.verify_status === 'pending' || course.verify_status === 'rejected' || course.verify_status === 'not verified') &&
//                             (course.course_status === 'completed' || course.course_status === 'incompleted')) &&
//                         (course.publish_status === 'published' || course.publish_status === 'unpublished')
//                     );


//                     if (draftCourses.length === 0) {
//                         setUserCourses([]);
//                     } else {
//                         setUserCourses(draftCourses);
//                     }
//                     console.log('Draft Data:', draftCourses);
//                 }
//             } catch (error) {
//                 console.error("Error fetching user courses:", error);
//             }
//         };

//         fetchCourses();
//     }, []);

//     useEffect(() => {
//         const filtered = userCourses.filter(course =>
//             course.course_name && course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredData(filtered);
//     }, [searchTerm, userCourses]);

//     function deleteCourse(courseId) {
//         axios.delete(`${BASEURL}/api/delete-course`, {
//             data: { courseId: courseId },
//         })
//             .then((response) => {
//                 if (response.data.status === "success") {
//                     setUserCourses(userCourses => userCourses.filter(course => course.id !== courseId));
//                 }
//             });
//     }

//     function handleDelete(courseId) {
//         setShowDeleteModal({ state: true, courseId: courseId });
//     }

//     function handleCloseDeleteModal() {
//         setShowDeleteModal({ state: false, courseId: null });
//     }

//     function formatArrayToString(array) {
//         return array.join(', ');
//     }



//     const truncateText = (text, maxLength) => {
//         if (text.length <= maxLength) {
//             return text;
//         }
//         return text.substring(0, maxLength) + '...';
//     };

//     const handlePublishSuccess = (courseId, status) => {
//         console.log('Course publish status:', status); // Log the updated status
//         setUserCourses(prevCourses =>
//             prevCourses.map(course =>
//                 course.id === courseId
//                     ? { ...course, verify_status: 'pending', course_status: 'completed' }
//                     : course
//             )
//         );
//     };

//     const [tooltipState, setTooltipState] = useState({});

//     const handleMouseEnter = (courseId) => {
//         setTooltipState(prevState => ({
//             ...prevState,
//             [courseId]: true
//         }));
//     };

//     const handleMouseLeave = (courseId) => {
//         setTooltipState(prevState => ({
//             ...prevState,
//             [courseId]: false
//         }));
//     };


//     function checkImageExists(imageUrl) {
//         return new Promise((resolve, reject) => {
//             const image = new Image();
//             image.onload = () => resolve(true);
//             image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
//             image.src = imageUrl;
//         });
//     }


//     const fetchCoverImages = async () => {
//         const images = {};
//         for (const course of filteredData) {
//             try {
//                 console.log(`Fetching image for course ${course.id}`);
//                 const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
//                 console.log(`Received response for course ${course.id}:`, response.data);

//                 if (response.data.dataUrl) {
//                     const imageExists = await checkImageExists(response.data.dataUrl);
//                     if (imageExists) {
//                         images[course.id] = response.data.dataUrl;
//                     } else {
//                         console.log(`Image for course ${course.id} does not exist in S3 bucket`);
//                         images[course.id] = null; // or a default image URL
//                     }
//                 } else {
//                     console.log(`No image URL received for course ${course.id}`);
//                     images[course.id] = null; // or a default image URL
//                 }
//             } catch (error) {
//                 console.error(`Error fetching cover image for course ${course.id}:`, error);
//                 images[course.id] = null; // or a default image URL
//             }
//         }
//         console.log("All fetched images:", images);
//         setCoverImages(images);
//     };

//     useEffect(() => {
//         fetchCoverImages();
//     }, [filteredData]);

//     const deleteCourseImage = async (courseId) => {
//         try {
//             const response = await axios.post(`${BASEURL}/api/delete-course-image`, { courseId });
//             console.log(response.data.message);
//             // Update the state to reflect the image deletion
//             setCoverImages(prevImages => ({
//                 ...prevImages,
//                 [courseId]: null
//             }));
//             toast.success("Course image deleted successfully");
//         } catch (error) {
//             console.error("Error deleting course image:", error);
//             toast.error("Failed to delete course image");
//         }
//     };

//     const handleImageUpdate = async (courseId) => {
//         const input = document.createElement('input');
//         input.type = 'file';
//         input.accept = 'image/*';
//         input.onchange = async (e) => {
//             const file = e.target.files[0];
//             if (file) {
//                 try {
//                     const formData = new FormData();
//                     formData.append('coverImage', file);
//                     formData.append('courseId', courseId);

//                     const response = await axios.post(`${BASEURL}/api/update-course-image`, formData, {
//                         headers: {
//                             'Content-Type': 'multipart/form-data',
//                         },
//                     });

//                     console.log(response.data.message);
//                     fetchCoverImages(); // Re-fetch all images
//                     toast.success("Course image updated successfully");
//                 } catch (error) {
//                     console.error("Error updating course image:", error);
//                     toast.error("Failed to update course image");
//                 }
//             }
//         };
//         input.click();
//     };

//     // const updateCourseImage = async (courseId, file) => {
//     //     try {
//     //         const formData = new FormData();
//     //         formData.append('coverImage', file);
//     //         formData.append('courseId', courseId);

//     //         const response = await axios.post(`${BASEURL}/api/update-course-image`, formData, {
//     //             headers: {
//     //                 'Content-Type': 'multipart/form-data',
//     //             },
//     //         });

//     //         console.log(response.data.message);
//     //         // Update the state to reflect the new image
//     //         fetchCoverImages(); // Re-fetch all images
//     //         toast.success("Course image updated successfully");
//     //     } catch (error) {
//     //         console.error("Error updating course image:", error);
//     //         toast.error("Failed to update course image");
//     //     }
//     // };

//     function formatClassId(classId) {
//         // Check if classId is a string, if not, convert it to a string
//         const classIdString = typeof classId === 'string' ? classId : String(classId);

//         // Use regex to match all sequences of digits, sort them, and join them with commas and spaces
//         const sortedClassIds = classIdString.match(/\d+/g)?.map(Number).sort((a, b) => a - b).join(', ') || '';

//         return sortedClassIds;
//     }

//     function formatTags(tag) {
//         if (!tag || !Array.isArray(tag) || tag.length === 0) {
//             return 'No tags available'; // Handle case where tag is not an array, is empty, or has no elements
//         }

//         // Join the array elements into a comma-separated string
//         return tag.join(', ');
//     }


//     return (
//         <>
//             <DiactoPublishCourseModal
//                 isOpen={showPublishModal.state}
//                 onClose={() => setShowPublishModal({ state: false, courseId: null })}
//                 id={showPublishModal.courseId}
//                 onPublishSuccess={handlePublishSuccess} // Pass the onPublishSuccess callback
//             />
//             <DeleteCourseModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteCourse={deleteCourse} />
//             <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">
//                 {filteredData.length === 0 ? (
//                     <div className="col-span-full w-full my-0 mx-auto">
//                         <div className="flex justify-center my-0 mx-auto w-full">
//                             <div className="flex flex-col items-center text-center max-w-sm">
//                                 <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
//                                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 flex justify-center">
//                                         <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
//                                     </svg>
//                                 </p>
//                                 <p className="text-xl font-bold mt-2 text-gray-700">Oops! No courses found.</p>
//                                 <p className="mt-2">It looks like there are no courses here. Why not create one and get started?</p>
//                             </div>
//                         </div>
//                     </div>
//                 ) : (
//                     filteredData.map((course, index) => (
//                         <div className='bg-white rounded-md border-solid border border-gray-200 p-4 h-max max-w-md'>
//                             <div className="flex justify-between">

//                                 <h1 className="mb-4 text-md font-semibold text-gray-800 dark:text-white">
//                                     {truncateText(course.course_name, 20)}
//                                 </h1>
//                                 <div x-data="{ isOpen: true }" className="relative inline-block ">
//                                     <button
//                                         className="relative z-10 block p-1 text-gray-700  border border-transparent rounded-md dark:text-white focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:bg-gray-800 focus:outline-none"
//                                         onClick={() => toggleDropdown(course.id)}>
//                                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 font-bold">
//                                             <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
//                                         </svg>

//                                     </button>

//                                     {/* {dropdownState[course.id] && (
//                                         <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800"
//                                         >
//                                             <Link to={`/view/${course.id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
//                                                 </svg>


//                                                 <span className="mx-1">
//                                                     View Course
//                                                 </span>
//                                             </Link>

//                                             <Link to={`/course-builder/${course.id}`} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
//                                                 </svg>


//                                                 <span className="mx-1">
//                                                     Edit Course
//                                                 </span>
//                                             </Link>

//                                             <a href="/" className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
//                                                 </svg>

//                                                 <span className="mx-1">
//                                                     Delete Course
//                                                 </span>
//                                             </a>

//                                         </div>

//                                     )} */}

// {dropdownState[course.id] && (
//                                         <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800"
//                                         >
//                                             <Link to={`/admin-view/${course.id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
//                                                 </svg>
//                                                 <span className="mx-1">
//                                                     View Course
//                                                 </span>
//                                             </Link>

//                                             <Link to={`/admin-course-builder/${course.id}`} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
//                                                 </svg>


//                                                 <span className="mx-1">
//                                                     Edit Course
//                                                 </span>
//                                             </Link>

//                                             <span onClick={()=>{setShowDeleteModal({show: true , courseId : course.id})}} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                                     <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
//                                                 </svg>

//                                                 <span className="mx-1">
//                                                     Delete Course
//                                                 </span>
//                                             </span>

//                                         </div>

//                                     )}

//                                 </div>
//                             </div>

//                             {/* <img className="object-cover object-center w-full h-64 rounded-lg lg:h-40" src={coverPhoto} alt="" /> */}
//                             {/* {coverImages[course.id] ? (
//                                 <img
//                                     src={coverImages[course.id]}
//                                     alt={`${course.course_name} cover`}
//                                     className="object-cover object-center w-full h-64 rounded-lg lg:h-40"
//                                     onError={(e) => {
//                                         console.error(`Error loading image for course ${course.id}:`, e);
//                                         e.target.src = 'path/to/fallback/image.jpg'; // Add a fallback image
//                                     }}
//                                 />
//                             ) : (
//                                 <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center">
//                                     <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
//                                 </div>
//                             )} */}

//                             <div className="relative group">
//                                 {coverImages[course.id] ? (
//                                     <img
//                                         src={coverImages[course.id]}
//                                         alt={`${course.course_name} cover`}
//                                         className="object-cover object-center w-full h-64 rounded-lg lg:h-40 transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
//                                         onError={(e) => {
//                                             console.error(`Error loading image for course ${course.id}:`, e);
//                                             e.target.src = 'path/to/fallback/image.jpg';
//                                         }}
//                                     />
//                                 ) : (
//                                     <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
//                                         <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
//                                     </div>
//                                 )}

//                                 {/* Overlay with buttons */}
//                                 <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 space-y-4">
//                                     <button
//                                         onClick={() => handleImageUpdate(course.id)}
//                                         className="flex items-center justify-center bg-white dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white  text-xs font-medium p-2 rounded transition duration-300"
//                                     >
//                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
//                                         </svg>
//                                         <p> Edit Image</p>
//                                     </button>
//                                     <button
//                                         onClick={() => deleteCourseImage(course.id)}
//                                         className="flex items-center justify-center bg-white dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white text-xs font-medium p-2 rounded transition duration-300"
//                                     >
//                                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1.5">
//                                             <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
//                                         </svg>

//                                         <p> Delete Image</p>
//                                     </button>
//                                 </div>
//                             </div>
//                             <div className="mt-4">

//                                 {/* <h1 className="mb-4 text-md font-semibold text-gray-800 dark:text-white">
//                                     {course.subject_name}
//                                 </h1> */}

//                                 <p className="mt-2 text-gray-500 dark:text-gray-400 h-12 text-sm">
//                                     {truncateText(course.course_desc, 65)}
//                                 </p>

//                                 <div className="flex flex-col w-full gap-2 mt-1 rounded-sm">
//                                     <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
//                                         <span className="font-semibold mr-1">Grade :</span>  {formatClassId(course.class_id)}
//                                     </span>
//                                     <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
//                                         <span className="font-semibold mr-1">Difficulty Level :</span>
//                                         <span className="capitalize">{course.difficulty_level}</span>
//                                     </span>
//                                     <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
//                                         <span className="font-semibold mr-1">Tags :</span>  {formatTags(course.tags)}
//                                     </span>
//                                 </div>



//                                 <div className='flex flex-row justify-between gap-5 w-full px-1 mt-5'>
//                                     <div className="flex flex-col items-start">
//                                         <p className="text-xs text-gray-500">{course.slide_count ? course.slide_count : 0}</p>
//                                         <p className="text-sm font-medium text-gray-700 cursor-pointer">
//                                             Slides
//                                         </p>
//                                     </div>
//                                     <div className="flex flex-col items-start">
//                                         <p className="text-xs text-gray-500">{course.test_count ? course.test_count : 0}</p>
//                                         <p className="text-sm font-medium text-gray-700 cursor-pointer">
//                                             Tests
//                                         </p>
//                                     </div>

//                                     <div className="flex flex-row">
//                                         {/* Verify status or Publish button */}
//                                         {course.verify_status !== 'not verified' ? (
//                                             <div className="text-center flex flex-row relative">
//                                                 <div
//                                                     className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${course.verify_status === 'verified'
//                                                         ? 'bg-green-100'
//                                                         : course.verify_status === 'pending'
//                                                             ? 'bg-yellow-100'
//                                                             : course.verify_status === 'rejected'
//                                                                 ? 'bg-red-100'
//                                                                 : 'bg-rose-100'
//                                                         }`}
//                                                     onMouseEnter={() => handleMouseEnter(course.id)}
//                                                     onMouseLeave={() => handleMouseLeave(course.id)}
//                                                 >
//                                                     <span
//                                                         className={`h-1.5 w-1.5 rounded-full ${course.verify_status === 'verified'
//                                                             ? 'bg-green-500'
//                                                             : course.verify_status === 'pending'
//                                                                 ? 'bg-yellow-600'
//                                                                 : course.verify_status === 'rejected'
//                                                                     ? 'bg-red-500'
//                                                                     : 'bg-red-500'
//                                                             }`}
//                                                     ></span>

//                                                     <h2
//                                                         className={`text-xs font-normal ${course.verify_status === 'verified'
//                                                             ? 'text-green-500'
//                                                             : course.verify_status === 'pending'
//                                                                 ? 'text-yellow-600'
//                                                                 : course.verify_status === 'rejected'
//                                                                     ? 'text-red-500'
//                                                                     : 'text-red-500'
//                                                             }`}
//                                                     >
//                                                         {course.verify_status}
//                                                     </h2>

//                                                     {/* Conditional rendering of tooltip */}
//                                                     {course.verify_status === 'rejected' && tooltipState[course.id] && (
//                                                         <span className="absolute z-50 bottom-full mb-2 py-2 px-4 w-96 h-28 text-left overflow-y-scroll text-wrap text-xs text-red-500 bg-red-100 rounded-md shadow-md whitespace-nowrap">
//                                                             {course.comment ? course.comment : 'No comment'}
//                                                         </span>
//                                                     )}
//                                                 </div>
//                                             </div>

//                                         ) : (
//                                             <div className="text-center flex items-center">
//                                                 <button
//                                                     type="button"
//                                                     className="inline-flex truncate gap-2 justify-center items-center rounded-full bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-1 border border-blue-500 hover:border-transparent"
//                                                     onClick={() => handlePublishClick(course.id)}
//                                                 >
//                                                     <svg
//                                                         xmlns="http://www.w3.org/2000/svg"
//                                                         fill="none"
//                                                         viewBox="0 0 24 24"
//                                                         strokeWidth={1.5}
//                                                         stroke="currentColor"
//                                                         className="w-4 h-4 mx-1"
//                                                     >
//                                                         <path
//                                                             strokeLinecap="round"
//                                                             strokeLinejoin="round"
//                                                             d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
//                                                         />
//                                                     </svg>
//                                                     {/* <span className='text-xs'>Publish Course</span> */}
//                                                 </button>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>


//                     ))
//                 )}
//             </div>
//         </>
//     )
// }

// export default DiactoDraftCourses



import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { BASEURL } from "../../../constants";
import toast from 'react-hot-toast';
// import coverPhoto from '../assets/coverPhoto.webp';
import DiactoPublishCourseModal from './DiactoPublishCourseModal'
import DeleteCourseModal from "../../Teacher/DeleteCourseModal";

// Custom hook for debouncing


const DiactoDraftCourses = ({searchTerm}) => {
    const [userCourses, setUserCourses] = useState([]);

    const [showDeleteModal, setShowDeleteModal] = useState({
        show: false,
        courseId: null,
    });
    const [filteredData, setFilteredData] = useState([]); // Filtered school data for display
    
    const [publishCourseModal, setPublishCourseModal] = useState(false);
    const navigate = useNavigate();

    const [dropdownState, setDropdownState] = useState({}); // State to track each dropdown's open state

    const [showPublishModal, setShowPublishModal] = useState(false);


    const [file, setFile] = useState("");
    const [coverImages, setCoverImages] = useState({});



    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    }

    const toggleDropdown = (courseId) => {
        setDropdownState(prevState => ({
            ...prevState,
            [courseId]: !prevState[courseId]
        }));
    };

    const handlePublishClick = (courseId) => {
        setShowPublishModal({ state: true, courseId });
    };

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const userId = localStorage.getItem('auth');
                if (userId) {
                    const response = await axios.post(`${BASEURL}/api/fetch-courses`, {
                        user_id: userId,
                    });

                    const courseData = response.data.courseData;
                    const draftCourses = courseData.filter(course =>
                        ((course.verify_status === 'pending' || course.verify_status === 'rejected' || course.verify_status === 'not verified') &&
                            (course.course_status === 'completed' || course.course_status === 'incompleted')) &&
                        (course.publish_status === 'published' || course.publish_status === 'unpublished')
                    );


                    if (draftCourses.length === 0) {
                        setUserCourses([]);
                    } else {
                        setUserCourses(draftCourses);
                    }
                    console.log('Draft Data:', draftCourses);
                }
            } catch (error) {
                console.error("Error fetching user courses:", error);
            }
        };

        fetchCourses();
    }, []);

    useEffect(() => {
        const filtered = userCourses.filter(course =>
            course.course_name && course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchTerm, userCourses]);

    function deleteCourse(courseId) {
        axios.delete(`${BASEURL}/api/delete-course`, {
            params: { courseId: courseId },
        })
            .then((response) => {
                if (response.data.status === "success") {
                    setUserCourses(userCourses => userCourses.filter(course => course.id !== courseId));
                    setShowDeleteModal({show: false , courseId:null})
                }
            });
    }

    function handleDelete(courseId) {
        setShowDeleteModal({ state: true, courseId: courseId });
    }

    function handleCloseDeleteModal() {
        setShowDeleteModal({ state: false, courseId: null });
    }

    function formatArrayToString(array) {
        return array.join(', ');
    }



    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const handlePublishSuccess = (courseId, status) => {
        console.log('Course publish status:', status); // Log the updated status
        setUserCourses(prevCourses =>
            prevCourses.map(course =>
                course.id === courseId
                    ? { ...course, verify_status: 'pending', course_status: 'completed' }
                    : course
            )
        );
    };

    const [tooltipState, setTooltipState] = useState({});

    const handleMouseEnter = (courseId) => {
        setTooltipState(prevState => ({
            ...prevState,
            [courseId]: true
        }));
    };

    const handleMouseLeave = (courseId) => {
        setTooltipState(prevState => ({
            ...prevState,
            [courseId]: false
        }));
    };


    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
            image.src = imageUrl;
        });
    }


    const fetchCoverImages = async () => {
        const images = {};
        for (const course of filteredData) {
            try {
                console.log(`Fetching image for course ${course.id}`);
                const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
                console.log(`Received response for course ${course.id}:`, response.data);

                if (response.data.dataUrl) {
                    const imageExists = await checkImageExists(response.data.dataUrl);
                    if (imageExists) {
                        images[course.id] = response.data.dataUrl;
                    } else {
                        console.log(`Image for course ${course.id} does not exist in S3 bucket`);
                        images[course.id] = null; // or a default image URL
                    }
                } else {
                    console.log(`No image URL received for course ${course.id}`);
                    images[course.id] = null; // or a default image URL
                }
            } catch (error) {
                console.error(`Error fetching cover image for course ${course.id}:`, error);
                images[course.id] = null; // or a default image URL
            }
        }
        console.log("All fetched images:", images);
        setCoverImages(images);
    };

    useEffect(() => {
        fetchCoverImages();
    }, [filteredData]);

    const deleteCourseImage = async (courseId) => {
        try {
            const response = await axios.post(`${BASEURL}/api/delete-course-image`, { courseId });
            console.log(response.data.message);
            // Update the state to reflect the image deletion
            setCoverImages(prevImages => ({
                ...prevImages,
                [courseId]: null
            }));
            toast.success("Course image deleted successfully");
        } catch (error) {
            console.error("Error deleting course image:", error);
            toast.error("Failed to delete course image");
        }
    };

    const handleImageUpdate = async (courseId) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e) => {
            const file = e.target.files[0];
            if (file) {
                try {
                    const formData = new FormData();
                    formData.append('coverImage', file);
                    formData.append('courseId', courseId);

                    const response = await axios.post(`${BASEURL}/api/update-course-image`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    console.log(response.data.message);
                    fetchCoverImages(); // Re-fetch all images
                    toast.success("Course image updated successfully");
                } catch (error) {
                    console.error("Error updating course image:", error);
                    toast.error("Failed to update course image");
                }
            }
        };
        input.click();
    };

    // const updateCourseImage = async (courseId, file) => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('coverImage', file);
    //         formData.append('courseId', courseId);

    //         const response = await axios.post(`${BASEURL}/api/update-course-image`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         console.log(response.data.message);
    //         // Update the state to reflect the new image
    //         fetchCoverImages(); // Re-fetch all images
    //         toast.success("Course image updated successfully");
    //     } catch (error) {
    //         console.error("Error updating course image:", error);
    //         toast.error("Failed to update course image");
    //     }
    // };
    function formatClassId(classId) {
        // Check if classId is a string, if not, convert it to a string
        const classIdString = typeof classId === 'string' ? classId : String(classId);
   
        // Use regex to match all sequences of digits, sort them, and join them with commas and spaces
        const sortedClassIds = classIdString.match(/\d+/g)?.map(Number).sort((a, b) => a - b).join(', ') || '';
       
        return sortedClassIds;
    }
   
   
    function formatTags(tag) {
        if (!tag || !Array.isArray(tag) || tag.length === 0) {
            return 'No tags available'; // Handle case where tag is not an array, is empty, or has no elements
        }
   
        // Join the array elements into a comma-separated string
        return tag.join(', ');
    }
   
    return (
        <>
            <DiactoPublishCourseModal
                isOpen={showPublishModal.state}
                onClose={() => setShowPublishModal({ state: false, courseId: null })}
                id={showPublishModal.courseId}
                onPublishSuccess={handlePublishSuccess} // Pass the onPublishSuccess callback
            />
            <DeleteCourseModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteCourse={deleteCourse} />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">
                {filteredData.length === 0 ? (
                    <div className="col-span-full w-full my-0 mx-auto">
                        <div className="flex justify-center my-0 mx-auto w-full">
                            <div className="flex flex-col items-center text-center max-w-sm">
                                <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 flex justify-center">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>
                                </p>
                                <p className="text-xl font-bold mt-2 text-gray-700">Oops! No courses found.</p>
                                <p className="mt-2">It looks like there are no courses here. Why not create one and get started?</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    filteredData.map((course, index) => (
                        <div className='bg-white rounded-md border-solid border border-gray-200 p-4 h-max max-w-md'>
                            <div className="flex justify-between">

                                <h1 className="mb-4 text-md font-semibold text-gray-800 dark:text-white">
                                    {truncateText(course.course_name, 20)}
                                </h1>
                                <div x-data="{ isOpen: true }" className="relative inline-block ">
                                    <button
                                        className="relative z-10 block p-1 text-gray-700  border border-transparent rounded-md dark:text-white focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:bg-gray-800 focus:outline-none"
                                        onClick={() => toggleDropdown(course.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 font-bold">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                        </svg>

                                    </button>

                                    {dropdownState[course.id] && (
                                        <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800"
                                        >
                                            <Link to={`/admin-view/${course.id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg>
                                                <span className="mx-1">
                                                    View Course
                                                </span>
                                            </Link>

                                            <Link to={`/admin-course-builder/${course.id}`} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                </svg>


                                                <span className="mx-1">
                                                    Edit Course
                                                </span>
                                            </Link>

                                            <span onClick={()=>{setShowDeleteModal({show: true , courseId : course.id})}} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>

                                                <span className="mx-1">
                                                    Delete Course
                                                </span>
                                            </span>

                                        </div>

                                    )}

                                </div>
                            </div>

                            {/* <img className="object-cover object-center w-full h-64 rounded-lg lg:h-40" src={coverPhoto} alt="" /> */}
                            {/* {coverImages[course.id] ? (
                                <img
                                    src={coverImages[course.id]}
                                    alt={`${course.course_name} cover`}
                                    className="object-cover object-center w-full h-64 rounded-lg lg:h-40"
                                    onError={(e) => {
                                        console.error(`Error loading image for course ${course.id}:`, e);
                                        e.target.src = 'path/to/fallback/image.jpg'; // Add a fallback image
                                    }}
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center">
                                    <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
                                </div>
                            )} */}

                            <div className="relative group">
                                {coverImages[course.id] ? (
                                    <img
                                        src={coverImages[course.id]}
                                        alt={`${course.course_name} cover`}
                                        className="object-cover object-center w-full h-64 rounded-lg lg:h-40 transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
                                        onError={(e) => {
                                            console.error(`Error loading image for course ${course.id}:`, e);
                                            e.target.src = 'path/to/fallback/image.jpg';
                                        }}
                                    />
                                ) : (
                                    <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
                                        <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
                                    </div>
                                )}

                                {/* Overlay with buttons */}
                                <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 space-y-4">
                                    <button
                                        onClick={() => handleImageUpdate(course.id)}
                                        className="flex items-center justify-center bg-white dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white  text-xs font-medium p-2 rounded transition duration-300"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        <p> Edit Image</p>
                                    </button>
                                    <button
                                        onClick={() => deleteCourseImage(course.id)}
                                        className="flex items-center justify-center bg-white dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white text-xs font-medium p-2 rounded transition duration-300"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1.5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>

                                        <p> Delete Image</p>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-4">

                                {/* <h1 className="mb-4 text-md font-semibold text-gray-800 dark:text-white">
                                    {course.subject_name}
                                </h1> */}

                                <p className="mt-2 text-gray-500 dark:text-gray-400 h-12 text-sm">
                                    {truncateText(course.course_desc, 65)}
                                </p>
                                <div className="flex flex-col w-full gap-2 mt-1 rounded-sm">
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Grade :</span>  {formatClassId(course.class_id)}
                                    </span>
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Difficulty Level :</span>
                                        <span className="capitalize">{course.difficulty_level}</span>
                                    </span>
                                    <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                        <span className="font-semibold mr-1">Tags :</span>  {formatTags(course.tags)}
                                    </span>
                                </div>



                                <div className='flex flex-row justify-between gap-5 w-full px-1 mt-5'>
                                    <div className="flex flex-col items-start">
                                        <p className="text-xs text-gray-500">{course.slide_count ? course.slide_count : 0}</p>
                                        <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                            Slides
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <p className="text-xs text-gray-500">{course.test_count ? course.test_count : 0}</p>
                                        <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                            Tests
                                        </p>
                                    </div>
 
                                    <div className="flex flex-row">
                                        {/* Verify status or Publish button */}
                                        {course.verify_status !== 'not verified' ? (
                                            <div className="text-center flex flex-row relative">
                                                <div
                                                    className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${course.verify_status === 'verified'
                                                        ? 'bg-green-100'
                                                        : course.verify_status === 'pending'
                                                            ? 'bg-yellow-100'
                                                            : course.verify_status === 'rejected'
                                                                ? 'bg-red-100'
                                                                : 'bg-rose-100'
                                                        }`}
                                                    onMouseEnter={() => handleMouseEnter(course.id)}
                                                    onMouseLeave={() => handleMouseLeave(course.id)}
                                                >
                                                    <span
                                                        className={`h-1.5 w-1.5 rounded-full ${course.verify_status === 'verified'
                                                            ? 'bg-green-500'
                                                            : course.verify_status === 'pending'
                                                                ? 'bg-yellow-600'
                                                                : course.verify_status === 'rejected'
                                                                    ? 'bg-red-500'
                                                                    : 'bg-red-500'
                                                            }`}
                                                    ></span>
 
                                                    <h2
                                                        className={`text-xs font-normal ${course.verify_status === 'verified'
                                                            ? 'text-green-500'
                                                            : course.verify_status === 'pending'
                                                                ? 'text-yellow-600'
                                                                : course.verify_status === 'rejected'
                                                                    ? 'text-red-500'
                                                                    : 'text-red-500'
                                                            }`}
                                                    >
                                                        {course.verify_status}
                                                    </h2>
 
                                                    {/* Conditional rendering of tooltip */}
                                                    {course.verify_status === 'rejected' && tooltipState[course.id] && (
                                                        <span className="absolute z-50 bottom-full mb-2 py-2 px-4 w-96 h-28 text-left overflow-y-scroll text-wrap text-xs text-red-500 bg-red-100 rounded-md shadow-md whitespace-nowrap">
                                                            {course.comment ? course.comment : 'No comment'}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
 
                                        ) : (
                                            <div className="text-center flex items-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex truncate gap-2 justify-center items-center rounded-full bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-1 border border-blue-500 hover:border-transparent"
                                                    onClick={() => handlePublishClick(course.id)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-4 h-4 mx-1"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                                                        />
                                                    </svg>
                                                    {/* <span className='text-xs'>Publish Course</span> */}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>


                    ))
                )}
            </div>
        </>
    )
}

export default DiactoDraftCourses
