import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constants";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import '../Global/Styles/tabulatorStyles.css';
import { ReactTabulator } from 'react-tabulator';

import toast from "react-hot-toast";

// Debounce Hook
// This custom hook is used to debounce the search term input, preventing excessive re-renders or API calls when the user is typing.
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

const AdminAllStudents = () => {
    const { schoolId } = useParams();
    const navigate = useNavigate();
    const [studentsData, setStudentsData] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredStudents, setFilteredStudents] = useState(null);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [openPopup, setOpenPopup] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [studentToDelete, setStudentToDelete] = useState(null);
    const [rowData, setRowData] = useState(null);

    // Fetch teachers data for the specific school
    useEffect(() => {
        // Fetch students data for the specific school
        axios
            .get(`${BASEURL}/api/fetch-students/${schoolId}`)
            .then((response) => {
                setStudentsData(response.data.studentsData);
            })
            .catch((error) => {
                console.error("Error fetching students data:", error);
            });
    }, [schoolId]);

    // Filter teachers data based on the search term
    useEffect(() => {
        if (studentsData) {
            const lowercasedSearchTerm = debouncedSearchTerm.toLowerCase();
            const filtered = studentsData.filter(
                (student) =>
                    student.first_name.toLowerCase().includes(lowercasedSearchTerm) ||
                    student.last_name.toLowerCase().includes(lowercasedSearchTerm)
            );
            setFilteredStudents(filtered);
        }
    }, [debouncedSearchTerm, studentsData]);

    // Define columns for the Tabulator table
    const columns = [
        { title: "Sno.", field: "number", width: 70, hozAlign: "center", cssClass: "center-text", headerSort: true, resizable: true },
        { title: "Name", field: "name", headerSort: true, resizable: true },
        { title: "Email", field: "email", headerSort: true, resizable: true },
        { title: "Contact", field: "contact", headerSort: true, resizable: true },
        { title: "Location", field: "location", headerSort: true, resizable: true },
        { title: "SAP ID", field: "sapId", headerSort: true, resizable: true },
        { title: "Assigned Mentor", field: "mentor", headerSort: true, resizable: true },
        {
            title: 'Actions',
            formatter: () => {
                return '<button className="action-btn">•••</button>';
            },
            width: 100,
            cellClick: (e, cell) => {
                const rect = e.target.getBoundingClientRect();
                setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX - 280 }); // Adjusted position to the left
                setRowData(cell.getRow().getData());
                setOpenPopup(cell.getRow().getData().id); // Set the row ID to identify the open popup
            },
            headerSort: false
        }
    ];

    // Prepare table data for the ReactTabulator component
    const data = (filteredStudents || []).map((student, index) => ({
        id: student.id,
        number: index + 1,
        name: `${student.first_name} ${student.last_name}`,
        email: student.email,
        contact: student.contact_number,
        location: `${student.city}, ${student.state}`,
        sapId: student.sap_id,
        mentor: ` ${student.mentor_first_name} ${student.mentor_last_name}`
    }));

    // Navigate to the student's profile page
    const onViewDetails = (rowData) => {
        const { id } = rowData;
        navigate(`/admin/allStudents/${schoolId}/${id}`);
        console.log('schoolId', schoolId, 'id', id);
        setOpenPopup(null);
    };

    // Navigate to the edit student page
    const onEditStudent = (rowData) => {
        const { id } = rowData;
        console.log('schoolId', schoolId, 'id', id);
        navigate(`/admin/editStudent/${schoolId}/${id}`);
        setOpenPopup(null);
    };

    // Show the delete confirmation dialog
    const onDeleteWithConfirmation = (rowData) => {
        const { id } = rowData;
        setStudentToDelete(id);
        console.log("id", id);
        setShowDeleteConfirmation(true);
    };

    // Delete a student from the server
    const onDeleteStudent = async () => {
        try {
            const response = await axios.delete(`${BASEURL}/api/delete-student`, {
                data: { schoolId, userId: studentToDelete },
            });
            console.log(response.data.message);
            setShowDeleteConfirmation(false);
            toast.success("Student Profile deleted successfully!");
            axios.get(`${BASEURL}/api/fetch-students/${schoolId}`)
                .then(res => setStudentsData(res.data.studentsData || []));
            setOpenPopup(null);
        } catch (error) {
            console.error("Error deleting student:", error);
            toast.error("Error deleting student");
        }
    };

    // Cancel the delete operation
    const cancelDelete = () => {
        setStudentToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const options = {
        pagination: 'remote', // or 'local'
        paginationSize: 10, // number of rows per page
    };

    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Students
                        </p>
                    </div>
                    <div className='flex flex-row justify-between flex-x-2'>
                        <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
                            <div className="grid place-items-center w-12 text-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input
                                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                                type="text"
                                id="search"
                                placeholder="Search Student Name"
                                onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                        <Link to={`/admin/addStudent/${schoolId}`}>
                            <button type="button" className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                <span className='text-sm'>Add Student</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {data.length > 0 ? (
                <ReactTabulator
                    data={data}
                    columns={columns}
                    layout="fitColumns"
                    responsiveLayout="hide"
                    tooltips={true}
                    options={options}
                    className="custom-tabulator-styles"
                />
            ) : (
                <div className="col-span-full w-full my-0 mx-auto">
                    <div className="flex justify-center my-0 mx-auto w-full">
                        <div className="flex flex-col items-center text-center max-w-sm">
                            <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 dark:bg-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6 flex justify-center">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                </svg>
                            </p>
                            <p className="text-xl font-bold mt-2 text-gray-700">Oops! No students found.</p>
                            <p className="mt-2">It looks like there are no students here. Why not add one and get started?</p>
                        </div>
                    </div>
                </div>
            )}
            {openPopup && (
                <div
                    className="absolute bg-white shadow-lg rounded-lg py-2 w-48"
                    style={{ top: popupPosition.top, left: popupPosition.left }}
                >
                    <div className="cursor-pointer flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onViewDetails(rowData)}>
                        <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill="currentColor"></path>
                            <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" fill="currentColor"></path>
                        </svg>
                        <span className="mx-1">view profile</span>
                    </div>
                    <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onEditStudent(rowData)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>
                        <span className="mx-1">Edit Profile</span>
                    </div>
                    <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onDeleteWithConfirmation(rowData)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                        <span className="mx-1">Delete Profile</span>
                    </div>
                </div>
            )}
            {showDeleteConfirmation && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400">
                    <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl rtl:text-right dark:bg-gray-900 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                </svg>
                            </div>
                            <div className="mt-2 text-center">
                                <h3 className="text-lg font-medium leading-6 text-gray-800 capitalize dark:text-white" id="modal-title">Confirm Delete</h3>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this student?
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 sm:flex sm:items-center sm:justify-end">
                            <div className="sm:flex sm:items-center">
                                <button
                                    className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:mt-0 sm:w-auto sm:mx-2 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                                    onClick={cancelDelete}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-40"
                                    onClick={onDeleteStudent}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
    
};

export default AdminAllStudents;

