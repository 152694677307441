import { Link } from 'react-router-dom';
import bgImage from '../assets/profile_background.jpg'

const CourseCard = ({subject ,sharedCourseId, name , description , slideCount , masterCourseId , imageURL , completionPercentage}) => {
    console.log("details" , subject , name , description)
    const course = {
        id:1
    }

    return (<>
        <Link to={`/CourseDetails/${masterCourseId ? masterCourseId:'1'}/${sharedCourseId ? sharedCourseId:'1'}`}>
            <div className='p-1 '>
                <div className="flex w-96 h-48 p-2 bg-white gap-2 rounded-xl text-black " style={{border : 'solid 1px #e1deff' , boxShadow:'0 0 9.5px 0 rgba(57, 49, 133, 0.12)'}}>
                    <section name="image-container" className="w-2/5 h-full">
                        <img className='rounded-xl object-cover w-full h-full' src={imageURL ? imageURL:bgImage} />
                    </section>
                    <section className="w-3/5 h-full ">
                        <span className='rounded-full px-3 py-1 text-sm' style={{backgroundColor:'#ffe9d6'}}>{subject ? subject:'English'}</span>
                        <div className='mt-3 font-bold text-lg truncate'>{name ? name :'Know Basics of Noun'}</div> 
                        <div className='max-w-full truncate text-base mt-3'>{description ? description : 'Course description in two lines'}</div>
                        <div className='mt-3 text-xs'>
                            <span >Slides: <span className='font-semibold'>{slideCount ? slideCount:'18'}</span></span>
                            <span className='ml-4'>Max points: <span className='font-semibold'>100</span>
                            </span>
                        </div>
                        <div className='mt-2'>
                            {completionPercentage}% completed
                        </div>
                    </section>
                </div>
            </div>
        </Link>

    </>);
}

export default CourseCard;