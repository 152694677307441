import { useState } from "react";
import { BASEURL } from '../../../../constants';
import axios from "axios";


const AiContentGenerator = ({ aiToggle, setAiToggle, toggleSidebar }) => {
    console.log('ai toggle', aiToggle)

    const [loading, setLoading] = useState(false);
    const [contentNeeds, setContentNeeds] = useState('');
    const [textLength, setTextLength] = useState('');
    const [readingLevel, setReadingLevel] = useState('');
    const [result, setResult] = useState('');
    const [copied , setCopied ] = useState(false);
    
    function handleGenerateContent() {
        setLoading(true);
        //
        axios.post(`${BASEURL}/api/get-content`, {
            prompt: contentNeeds,
            maxTokens: textLength,
            readingLevel: readingLevel
        }).then((response) => {
            console.log('response ', response);
            setLoading(false);
            setCopied(false);
            let result = response.data.content;
            //convert into object 
            result = result.replace('"Answer":', '');
            result = result.slice(2, -2);
            setResult(result);
        })
    }

    async function OpenaiFetchAPI(promptValue) {
        try {
            console.log("Calling GPT3");
            var url = "https://api.openai.com/v1/chat/completions";
            var bearer = 'Bearer ' + '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';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "model": "gpt-4",
                    "messages": [{
                        "role": "user",
                        "content": `Given the summary of a dashboard, return a color palette of 6 matching colors and reason in json format. Please note that the output should only be in json \n expected output format = {
                            "colors" : ["#dbdbdb"],
                            "reason" : "describe why you recommended the colors"
                        } \n Here is the summary : \n ${promptValue}`
                    }]
                })
            });

            const data = await response.json();

            console.log(data);
            console.log(typeof data);
            console.log(Object.keys(data));
            console.log(data['choices'][0].message.content);

            const message = data['choices'][0].message.content;
            return JSON.parse(message);
        } catch (error) {
            console.log('Something bad happened ' + error);
            throw error; // Re-throw the error to be caught by the caller
        }
    }

    return (<>
        <div className="fixed inset-0 z-50 flex justify-end">
            <div className="w-2/6 h-full bg-white shadow-xl py-2 px-4 transform transition-transform duration-1000 ease-in-out">
                <div className="mt-1">
                    <div className="flex flex-row justify-between items-center">
                        <h2 className="text-lg font-semibold">AI Content Generator</h2>
                        <button onClick={toggleSidebar} className="text-gray-700 hover:text-red-500 ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="mb-2">
                        <label class="text-sm text-gray-700">
                            Describe your content needs
                        </label>
                        <input value={contentNeeds} onChange={(e) => { setContentNeeds(e.target.value) }} class="block mt-2 w-full px-2 py-2 text-xs text-gray-700 bg-white border border-gray-200 rounded-md " type="text" name="text" />
                    </div>
                    <div className="mb-2">
                        <label class="text-sm text-gray-700">
                            Number of words
                        </label>
                        <input type='number' min='0' value={textLength} onChange={(e) => { setTextLength(e.target.value) }} name="text"
                            class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md " />
                    </div>
                    <div className="mb-2">
                        <label class="text-sm text-gray-700">
                            Reading Level (Age)
                        </label>
                        <input autoComplete="off" value={readingLevel} onChange={(e) => { setReadingLevel(e.target.value) }} type="text" name="text"
                            class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md " />
                    </div>
                    <div className="mt-2 flex items-center justify-end gap-x-2">
                        <button disabled={loading} onClick={handleGenerateContent} className="px-2 py-1 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 ">
                            {
                                loading ? (
                                    <>
                                        <svg aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                        </svg>
                                        Generating
                                    </>
                                ) : (
                                    <>Generate</>
                                )
                            }
                        </button>
                    </div>
                    <div className="mt-2">
                        <label class="text-sm text-gray-700 ">
                            Result
                        </label>
                        <textarea value={result} rows={8} type="text" name="text"
                            className="text-xs block mt-2 w-full px-2 py-2 text-gray-700 bg-white border border-gray-200 rounded-md " />
                        <div className="text-right">
                            {
                                copied ? (
                                    <button className="px-2 py-1 text-sm mt-2 font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500">Copied</button>
                                ):(
                                    <button onClick={(e)=>{
                                        navigator.clipboard.writeText(result).then(function (){
                                            setCopied(true);
                                        })
                                    }} className="px-2 py-1 text-sm mt-2 font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500">Copy</button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AiContentGenerator;
