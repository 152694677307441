
export function truncateText(text , charecterLimit){

    if(text.length < charecterLimit){
        return text
    }

    let truncatedText = text.slice(0 , charecterLimit);

    return truncatedText.concat('...');
    // kkfk fkkdk kkdkf kdf dkfkskkakfk kaksdkf kskdkf ksdk fkaksdk fkskd fkskd ...
}