import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Item } from './Item';
import axios from 'axios';
import { BASEURL } from '../../../../../constants';

export function SortableItem({setCurrentSlideData , element , slideId , imagesArray , setSlidesArray , setImagesArray , setIsSorted ,setIsDataSaved , children 
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: element.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function deleteContent() {
    if (element.type === 'Image') {
      if (element.data.imgData) {
        if (imagesArray.some(obj => obj.id === element.id)) {
          //if image data is present and image is in imagesArray that means image was not saved in backend simply delete the image from frontend
          //array.some returns a boolean value.
          //also update the imageIdArray for that component
          setImagesArray((imagesArray) => {
            const newImagesArray = imagesArray.filter(imageObj => imageObj.id !== element.id);
            return newImagesArray;
          });
          setCurrentSlideData((currentSlide)=>{
            return { ...currentSlide , content : currentSlide.content.filter((contentObj) => contentObj.id !== element.id) ,  imageIdArray : currentSlide.imageIdArray.filter( imageKey => imageKey !== element.id)}
          })
          // setSlidesArray((slidesArray) => {
          //   const newSlidesData = 
          //   {
          //     ...slidesArray, slides: [...slidesArray.slides.map((slide) => {
          //       if (slide.id === slideId) {
          //         return {
          //           id: slide.id,
          //           content: [...slide.content.filter((contentObj) => contentObj.id !== element.id)]
          //         }
          //       } else {
          //         return { ...slide }
          //       }
          //     })]
          //   }
          //   return newSlidesData;
          // });
        } else {
          //we dont need to setImagesArray to null because imagesArray only contins images that have not been saved.
          //the image is in s3 bucket need to make delete API call
          console.log("props.element.id", element.id);
          axios.delete(`${BASEURL}/api/delete-image`, {
            data: {
              key: element.id
            }
          }).then((response) => {
            if (response.data.message === 'deleted successfully') {
              //also set slides data as well
              setCurrentSlideData((currentSlide)=>{
                return { ...currentSlide , content : currentSlide.content.filter((contentObj) => contentObj.id !== element.id) , imageIdArray : currentSlide.imageIdArray.filter( imageKey => imageKey !== element.id)}
              })
              // setSlidesArray((slidesArray) => {
              //   const newSlidesData = {
              //     ...slidesArray, slides: [...slidesArray.slides.map((slide) => {
              //       if (slide.id === slideId) {
              //         return {
              //           id: slide.id,
              //           content: [...slide.content.filter((contentObj) => contentObj.id !== element.id)]
              //         }
              //       } else {
              //         return { ...slide }
              //       }
              //     })]
              //   }
              //   return newSlidesData;
              // });
            }
          })
        }

      } else {
        setCurrentSlideData((currentSlide)=>{
          return { ...currentSlide , content : currentSlide.content.filter((contentObj) => contentObj.id !== element.id) , imageIdArray : currentSlide.imageIdArray.filter( imageKey => imageKey !== element.id)}
        })
        // setSlidesArray((slidesArray) => {
        //   const newSlidesData = {
        //     ...slidesArray, slides: [...slidesArray.slides.map((slide) => {
        //       if (slide.id === slideId) {
        //         return {
        //           id: slide.id,
        //           content: [...slide.content.filter((contentObj) => contentObj.id !== element.id)]
        //         }
        //       } else {
        //         return { ...slide }
        //       }
        //     })]
        //   }
        //   return newSlidesData;
        // });
      }
    } else if (element.type === 'Quiz') {
      //if image is uploaded then do futher processing if image was not uploaded directlt delete the component
      if (element.data.imageData.image) {
        //this means that image is there check if it is in s3 bucket or not 
        if (imagesArray.some(imageObj => imageObj.id === element.id)) {
          //means image was not saved to s3 bucket
          //just delete the component and image object from imagesArray 
          setImagesArray((imagesArray) => {
            const newImagesArray = imagesArray.filter(imageObj => imageObj.id === element.id);
            return newImagesArray;
          });
          setCurrentSlideData((currentSlide)=>{
            return { ...currentSlide , content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id), imageIdArray : currentSlide.imageIdArray.filter( imageKey => imageKey !== element.id)}
          })
        } else {
          //means image is saved to s3 bucket delete it and remove it from frontend 
          axios.delete(`${BASEURL}/api/delete-image`, {
            data: {
              key: element.id
            }
          }).then((response) => {
            if (response.data.message === 'deleted successfully') {
              //also set slides data as well
              setCurrentSlideData((currentSlide)=>{
                return { ...currentSlide , content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id) , imageIdArray : currentSlide.imageIdArray.filter( imageKey => imageKey !== element.id)}
              })
            }
          })
        }
      } else {
        //simply delete the component
        setCurrentSlideData((currentSlide)=>{
          return { ...currentSlide , content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id)}
        })
      }
    } else {
      //for all the content other than image and quiz hmm....
      console.log("last condition")
      setCurrentSlideData((currentSlide)=>{
        return { ...currentSlide , content: currentSlide.content.filter((contentObj) => contentObj.id !== element.id)}
      })
    }
    setIsSorted((isSorted) => !isSorted);//why is this state here?
    setIsDataSaved(false);
  }

  return (
    <Item ref={setNodeRef} style={style} >
      <div className='text-xs' {...attributes} {...listeners}><i class="fa-solid fa-grip-lines-vertical"></i></div>
      <div style={{ width: "100%" }}>{children}</div>
      {
        <div><i onClick={deleteContent} className="fa-regular fa-circle-xmark cursor-pointer text-xs"></i></div>
      }
    </Item>
  );
}