import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import CourseCard from "./CourseCard";
import axios from "axios";
import { BASEURL } from "../../constants";
import { jwtDecode } from "jwt-decode";

const StudentMyCourses = () => {

    const [studentCourses, setStudentCourses] = useState(null);
    const studentCoursesRef = useRef(null);
    const [coverImages, setCoverImages] = useState({});
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem('auth');

    const decoded = jwtDecode(token);
    const userRole = decoded.role;
    const userId = decoded.user_id;
    const schoolId = decoded.school_id;
    const classId = decoded.class_id;

    const debounce = (func, delay) => {
        let timer;

        return (...args) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    const debouncedSearch = debounce(handleSearch, 400);

    function handleSearch(e) {
        let searchString = e.target.value;
        if (e.target.value) {
            const newStudentCourses = studentCoursesRef.current.filter((course) => (
                course.course_name.toLowerCase().includes(searchString.toLowerCase()) || course.subject_name.toLowerCase().includes(searchString.toLowerCase())
            ))
            setStudentCourses(newStudentCourses);
        } else {
            setStudentCourses(studentCoursesRef.current);
        }
    }

    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
            image.src = imageUrl;
        });
    }
    useEffect(() => {
        setLoading(true)
        axios.get(`${BASEURL}/api/get-student-courses`, {
            params: {
                studentId: userId,
                schoolId: schoolId,
                classId: classId
            }
        }).then((response) => {

            studentCoursesRef.current = [...response.data];

            const courses = response.data;
            let imagePromiseArray = [];
            for (let course of courses) {
                const imagePromise = axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
                imagePromiseArray.push(imagePromise)
            }

            const images = {};
            Promise.all(imagePromiseArray)
                .then(async (responses) => {
                    console.log("image promise array response", responses);
                    // [{} , { }, response object]

                    for (let i = 0; i < responses.length; i++) {
                        if (responses[i].data.dataUrl) {
                            const imageExists = await checkImageExists(responses[i].data.dataUrl);
                            if (imageExists) {
                                images[courses[i].id] = responses[i].data.dataUrl;
                            } else {
                                console.log(`Image for course ${courses[i].id} does not exist in S3 bucket`);
                                images[courses[i].id] = null; // or a default image URL
                            }
                        } else {
                            console.log(`No image URL received for course ${courses[i].id}`);
                            images[courses[i].id] = null; // or a default image URL
                        }
                    }
                    setStudentCourses(response.data);
                    setCoverImages(images);
                    setLoading(false)
                })
        })
    }, [])

    return (<>
        <main className="h-dvh w-full pt-2 text-white flex flex-col" style={{ backgroundImage: 'linear-gradient(to bottom , #393185, #7570abae 68%,#f7f8fc00 0%) ' }}>
            <section className="h-40 px-4">
                <div>
                    <div className="h-12 relative">
                        <Link to={`/student/home`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                            </svg>
                        </Link>
                        <span className="text-lg font-semibold absolute left-1/2 top-0" style={{ transform: 'translate(-50%)' }}>Course List</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="p-2 w-full">
                            <span className="bg-white text-black flex p-2 px-4 border items-center w-full rounded-full">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                    </svg>
                                </span>
                                <input onChange={debouncedSearch} placeholder="Search..." className="w-full outline-none px-2"></input>
                            </span>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>
            <section className="rounded-t-xl grow px-4 mt-6 overflow-auto" style={{ backgroundColor: '#f5f4fd' }}>
                <h2 className="font-bold text-lg mt-4" style={{ color: '#171c29' }}>Courses</h2>
                {
                    studentCourses ? (
                        <main className="mt-4 w-full  flex flex-wrap gap-4 justify-center xl:justify-start">
                            {
                                studentCourses.length > 0 ? (
                                    studentCourses.map((course, index) => (
                                        <CourseCard key={index} completionPercentage={course.course_completion_percentage} subject={course.subject_name} name={course.course_name} description={course.course_desc} slideCount={course.slide_count} masterCourseId={course.id} sharedCourseId={course.course_id} imageURL={coverImages[course.id]} />
                                    ))
                                ) : (
                                    <div className="text-black">
                                        No Course To View
                                    </div>
                                )
                            }
                        </main>
                    ) : (
                        <div className="h-full flex justify-center items-center">
                            <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full" role="status" aria-label="loading">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    )
                }
            </section>
        </main>
    </>);
}

export default StudentMyCourses;