const MobileSidebar = ({ sidebarData, setSidebarToggle ,setSelectedSubtopicId,
    setSelectedTestId , setTestName , contentArray, setContentIndex}) => {

    /*
{topics : [ 
{
    id , name ,
    content: []
}
]}
    */
function handleSubTopicClick(chapId) {
    //we have to update the contentIndex 
    //find the index we need to set 
    const index = contentArray.findIndex(contentObj => contentObj.id === chapId);
    console.log('contentIndex' , index);
    setContentIndex(index);
    setSelectedSubtopicId(chapId);
    setSelectedTestId(null);
}

function handleTestClick(testId , testName) {
    const index = contentArray.findIndex(contentObj => contentObj.id === testId);
    setContentIndex(index);
    setSelectedTestId(testId);
    setTestName(testName)
    setSelectedSubtopicId(null);
}

    return (<>
        <div className="text-right">
            <button onClick={() => { setSidebarToggle(false) }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
        {
            sidebarData && (
                <div>
                    {
                        sidebarData.topics.map((topic, index) => {
                            return (
                                <div className="p-1">
                                    <div className="text-sm">{`${index + 1}.${topic.name}`}</div>
                                    <div name='content-list' className="flex flex-col gap-1 pl-2">
                                        {
                                            topic.content.map((contentObject) => {
                                                if (contentObject.type === 'slide-group') {
                                                    return (
                                                        <div className="flex gap-2 p-2 justify-between border rounded cursor-pointer">
                                                            <div className="flex gap-2 justify-start">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                                </svg>
                                                                <div className="border-l-2 border-l-gray-600"></div>
                                                                <div className="flex flex-col">
                                                                    <span className="text-xs">{contentObject.name}</span>
                                                                    <span className="text-xs">8 slides</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if (contentObject.type === 'test') {
                                                    return (
                                                        <div  className="flex gap-2 p-2 justify-between border rounded cursor-pointer">
                                                            <div className="flex gap-2 justify-start">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                                                </svg>
                                                                <div className="border-l-2 border-l-gray-600"></div>
                                                                <div className="flex flex-col">
                                                                    <span className="text-xs">{contentObject.name}</span>
                                                                    <span className="text-xs">10 questions</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            )


                        })
                    }
                </div>
            )
        }
    </>);
}

export default MobileSidebar;