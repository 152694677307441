import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURL } from '../../constants';
import toast from 'react-hot-toast';

const PublishCourseModal = ({ isOpen, onClose, id, onPublishSuccess }) => {

  useEffect(() => {
    if (isOpen && id !== null) {
      console.log("Modal opened for course Id:", id);
    }
  }, [isOpen, id])


  const [selectedOption, setSelectedOption] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);


  const handlePublish = async () => {
    setShowConfirmation(true); // Show confirmation before actual publish
  };


  const handleComplete = async () => {
    setIsPublishing(true);
    try {
      const visibility = selectedOption === 'ownSchool' ? 0 : 1;
      const response = await axios.post(`${BASEURL}/api/publish-course`, {
        id, // Use the provided `id` instead of `courseId`
        visibility,
      });
      console.log('Course published successfully', response.data);
      toast.success('Course published successfully');
      onPublishSuccess(id, response.data.status); // Call the onPublishSuccess callback with the course ID and the updated status
      onClose(); // Close the modal after successful publishing
    } catch (error) {
      console.error('Error publishing course:', error);
      toast.error('Error publishing course:');
    } finally {
      setIsPublishing(false);
      setShowConfirmation(false); // Reset confirmation state
    }
  };


  if (!isOpen) return null;

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Publish Course</h3>
              <button
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={onClose}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mb-4">
              {!showConfirmation ? (
                <div>
                  <div className="flex items-center mb-2">
                    <input
                      id="ownSchool"
                      type="checkbox"
                      className="form-checkbox h-4 w-4  text-blue-500"
                      checked={selectedOption === 'ownSchool'}
                      onChange={() => setSelectedOption('ownSchool')}
                    />
                    <label
                      htmlFor="ownSchool"
                      className="ml-2 text-gray-700 text-md font-medium"
                    >
                      Own School
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm mb-4">
                    Note: Select this option to share the course only within your
                    school
                  </p>
                  <div className="flex items-center mb-2">
                    <input
                      id="everyone"
                      type="checkbox"
                      className="form-checkbox h-4 w-4  text-blue-500"
                      checked={selectedOption === 'everyone'}
                      onChange={() => setSelectedOption('everyone')}
                    />
                    <label
                      htmlFor="everyone"
                      className="ml-2 text-gray-700 text-md font-medium"
                    >
                      Everyone
                    </label>
                  </div>
                  <p className="text-gray-500 text-sm">
                    Note: Select this option to make the course available to all
                    schools.
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-gray-700 my-4 leading-7">
                    Please confirm that the course is complete before publishing.
                    Once you mark the course as complete, you won't be able to
                    make any changes or updates to its content
                  </p>
                  <div className="flex justify-end">
                    <button
                      className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
                      onClick={() => setShowConfirmation(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={`px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none ${isPublishing ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      onClick={handleComplete}
                      disabled={isPublishing}
                    >
                      {isPublishing ? 'Completing...' : 'Complete'}
                    </button>
                  </div>
                </div>
              )}
            </div>
            {!showConfirmation && (
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
                  onClick={onClose}
                >
                  Close
                </button>
                <button
                  className={`px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none ${isPublishing || selectedOption === null
                    ? 'opacity-50 cursor-not-allowed'
                    : ''
                    }`}
                  onClick={handlePublish}
                  disabled={isPublishing || selectedOption === null}
                >
                  Publish
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PublishCourseModal;