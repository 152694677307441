import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../../constants";

const DiactoCreateSchool = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    aadharcardNumber: "",
    birthdate: "",
    adminContactNumber: "",
    adminAlternativeNumber: "",
    permanentAddress: "",
    adminCity: "",
    adminState: "",
    adminZipCode: "",
    schoolName: "",
    schoolAddress: "",
    schoolDocumentNumber: "",
    principalName: "",
    schoolCity: "",
    schoolState: "",
    schoolZipCode: "",
    schoolContactNumber: "",
    schoolAlternativeNumber: "",
    // fundsDeployed: "",
    selectedClass: "",
  });

  const notify = () => toast.success("School created successfully!");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    setFormData({ ...formData, selectedClass });

    if (selectedClass) {
      const classRange = Array.from({ length: parseInt(selectedClass) }, (_, i) => i + 1);
      console.log("Selected classes:", classRange.join(','));
    }
  };

  const handleCreateSchool = async (e) => {
    e.preventDefault();

    // Define required fields
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "aadharcardNumber",
      "birthdate",
      "adminContactNumber",
      "permanentAddress",
      "adminCity",
      "adminState",
      "adminZipCode",
      "schoolName",
      "schoolAddress",
      "schoolCity",
      "schoolState",
      "schoolZipCode",
      "schoolContactNumber",
      "principalName",
      "schoolDocumentNumber",
      // "fundsDeployed",
    ];

    // Check if all required fields are filled
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field.replace(/([A-Z])/g, " $1")}: Field is required`, {
          duration: 1000,
        });
        return;
      }
    }

    // Validate contact numbers for exactly 10 digits
    const tenDigitFields = [
      "adminContactNumber",
      "adminAlternativeNumber",
      "schoolContactNumber",
      "schoolAlternativeNumber",
    ];
    for (const field of tenDigitFields) {
      if (formData[field] && formData[field].length !== 10) {
        toast.error(`${field.replace(/([A-Z])/g, " $1")} must be exactly 10 digits`, {
          duration: 4000,
        });
        return;
      }
    }

    // Validate Aadhar Card for exactly 12 digits
    if (formData.aadharcardNumber && formData.aadharcardNumber.length !== 12) {
      toast.error("Aadhar Card must be exactly 12 digits", {
        duration: 4000,
      });
      return;
    }

    // Clear any existing toasts before showing a new one
    toast.dismiss();

    // Proceed with API request if all validations pass
    axios
      .post(`${BASEURL}/api/add-school`, formData)
      .then((response) => {
        console.log(response.data.message);
        notify();
        navigate("/diactoAdmin/allSchools");
      })
      .catch((error) => {
        console.error("Error adding school:", error);
        toast.error("Error adding school. Please try again.", {
          duration: 4000,
        });
      });
  };


  return (
    <>
      <div className='mx-4 border-b border-gray-200'>
        <div className='pt-2 pb-3 flex justify-between'>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
              Add New School
            </p>
          </div>
          <div class="flex items-center justify-end gap-x-4">
            <Link to={`/diactoAdmin/allSchools`}>
              <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
            </Link>

            <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleCreateSchool}>Save</button>
          </div>
        </div>
      </div>

      <form>
        <div class="space-y-12 px-4 mx-auto my-0">


          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-md font-semibold leading-7 text-gray-900 mt-4  pl-2 my-2 border-l-4 font-sans border-blue-400">School Information</h2>
            {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-full">
                <label for="school-name" class="block text-sm font-medium leading-6 text-gray-900">School Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="schoolName" value={formData.schoolName} onChange={handleInputChange} required autocomplete="school-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter school name" />
                </div>
              </div>
              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Address <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="schoolAddress" value={formData.schoolAddress} onChange={handleInputChange} required autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your permanent address" />
                </div>
              </div>

              <div class="sm:col-span-2 sm:col-start-1">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="schoolCity" value={formData.schoolCity} onChange={handleInputChange} required autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your city" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="region" class="block text-sm font-medium leading-6 text-gray-900">State <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="schoolState" value={formData.schoolState} onChange={handleInputChange} required autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your State" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="schoolZipCode" maxLength={6} value={formData.schoolZipCode} onChange={handleInputChange} required autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your ZIP code" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="contact-number" class="block text-sm font-medium leading-6 text-gray-900">Contact Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="schoolContactNumber" maxLength={10} value={formData.schoolContactNumber} onChange={handleInputChange} required type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your contact number" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="alt-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Alternate Contact Number</label>
                <div class="mt-2">
                  <input name="schoolAlternativeNumber" maxLength={10} value={formData.schoolAlternativeNumber} onChange={handleInputChange} type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your alternate contact number" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Principal Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="principalName" placeholder="Enter school principal name" value={formData.principalName} onChange={handleInputChange} required autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">School Government Registered ID <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="schoolDocumentNumber" placeholder="Enter School Government Registered ID" value={formData.schoolDocumentNumber} onChange={handleInputChange} required autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="selectedClass" className="block text-sm font-medium leading-6 text-gray-900">
                  Select Classes (Range) <span className="text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <select
                    name="selectedClass"
                    value={formData.selectedClass}
                    onChange={handleClassChange}
                    required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 "
                  >
                    <option value="" disabled>
                      Select class range
                    </option>
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        Class {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <h2 class="text-md font-semibold leading-7 text-gray-900 mt-6  pl-2 my-2 border-l-4 font-sans border-blue-400">School Admin Details</h2>
            {/* <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-2 sm:col-start-1">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="firstName" placeholder="Enter your first name" value={formData.firstName} onChange={handleInputChange} required autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="middle-name" class="block text-sm font-medium leading-6 text-gray-900">Middle Name</label>
                <div class="mt-2">
                  <input type="text" name="middleName" placeholder="Enter your middle name" value={formData.middleName} onChange={handleInputChange} required autocomplete="additional-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last Name <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="lastName" value={formData.lastName} onChange={handleInputChange} required autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your last name" />
                </div>
              </div>

              {/* <div class="sm:col-span-2">
                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <select name="gender" value={formData.gender} onChange={handleInputChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6 ">
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Others'>Others</option>
                  </select>
                </div>
              </div> */}

              <div class="sm:col-span-2">
                <label for="dob" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="birthdate" type="date" value={formData.birthdate} onChange={handleInputChange} required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="email" type="email" value={formData.email} onChange={handleInputChange} required autocomplete="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your email address" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="contact-number" class="block text-sm font-medium leading-6 text-gray-900">Contact Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="adminContactNumber" maxLength={10} value={formData.adminContactNumber} onChange={handleInputChange} required type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your contact number" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="alt-contact-number" class="block text-sm font-medium leading-6 text-gray-900">Alternate Contact Number</label>
                <div class="mt-2">
                  <input name="adminAlternativeNumber" maxLength={10} value={formData.adminAlternativeNumber} onChange={handleInputChange} type="tel" autocomplete="tel" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your alternate contact number" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="aadhar-card-number" class="block text-sm font-medium leading-6 text-gray-900">Aadhar Card Number <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input name="aadharcardNumber" maxLength={12} value={formData.aadharcardNumber} onChange={handleInputChange} required type="tel" autocomplete="on" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your Aadhar card number" />
                </div>
              </div>


              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Permanent Address <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="permanentAddress" value={formData.permanentAddress} onChange={handleInputChange} required autocomplete="street-address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your permanent address" />
                </div>
              </div>

              <div class="sm:col-span-2 sm:col-start-1">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="adminCity" value={formData.adminCity} onChange={handleInputChange} required autocomplete="address-level2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your city" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="region" class="block text-sm font-medium leading-6 text-gray-900">State <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="adminState" value={formData.adminState} onChange={handleInputChange} required autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your State" />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code <span className="text-red-600">*</span></label>
                <div class="mt-2">
                  <input type="text" name="adminZipCode" value={formData.adminZipCode} onChange={handleInputChange} required autocomplete="postal-code" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-inset focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring-2 focus:ring-opacity-40 px-4 sm:text-sm sm:leading-6" placeholder="Enter your ZIP code" maxLength={6}/>
                </div>
              </div>

            </div>

          </div>



          {/* ----------------------------------------------------- */}


        </div>

        {/* <div class="my-6 flex items-center justify-end gap-x-4">
          <Link to={`/diactoAdmin/home`}>
            <button type="button" class="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">Cancel</button>
          </Link>

          <button type="submit" class="rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500" onClick={handleCreateSchool}>Save</button>
        </div> */}
      </form>
    </>
  )
}

export default DiactoCreateSchool