import icons from './icons/icons'
import coins  from '../assets/Coins png.png'
import { useEffect, useState } from 'react';
import { BASEURL } from '../../constants';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

const CreditPoints = () => {
    const [pointsEarnedList , setPointsEarnedList] = useState([]);
    const [totalPoints , setTotalPoints] = useState(0);
    const navigate = useNavigate();
    
    useEffect(()=>{
        axios.get(`${BASEURL}/api/get-points-earned`)
        .then((response)=>{
            if(response.data.length){
                setPointsEarnedList(response.data);
                let total = 0;
                response.data.forEach((item) => {
                    total += item.points 
                })
                setTotalPoints(total);
            }
        })
    },[])
    

    return (
        <div className="h-dvh flex flex-col" style={{ backgroundImage: 'linear-gradient(to bottom , #393185 10%, #7570abae 40%,#f7f8fc00)' }}>
            {/* <Fireworks autorun={{ speed: 3 }} /> */}
            <div className='pt-2 pb-2 px-2 text-white'>
                <button onClick={() => { navigate('/student/home') }}>
                    {icons.chevronLeft}
                </button>
                <span className='absolute left-1/2 font-semibold' style={{ transform: 'translateX(-50%)' }}>Points</span>
            </div>
            <div className='grow flex flex-col '>
                <div className='flex justify-center h-36 relative'>
                    <div className='w-28 p-2 h-10 text-center rounded-xl text-black' style={{backgroundColor:'#dfddff'}}>Summary</div>
                    <div className='absolute top-2/3 rounded-2xl p-2' style={{  backgroundImage: 'linear-gradient(to bottom,#fe7d5f ,#f4b858 )' }}>
                        <div className='flex justify-around w-80 items-center' style={{backgroundImage:'linear-gradient(to bottom, #fe7d5f, #f4b858);'}}>
                            <div>
                                <img src={coins} />
                            </div>
                            <div className='flex flex-col text-white'> 
                                <div>{totalPoints} points</div>
                                <div>Total Earned</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grow pt-10 rounded-t-3xl px-4' style={{backgroundColor:'#f5f4fd'}}>
                    <div className='bg-white p-2 rounded-xl' style={{boxShadow:'0 0 12px 0 rgba(57, 49, 133, 0.08)'}}>
                        <h2 className='font-bold py-2'>Recent Earned Points</h2>
                        {
                            pointsEarnedList.length ? (
                                <div>
                                    {
                                        pointsEarnedList.map((item)=>{
                                            return (
                                                <div className='flex justify-between p-2 items-center'>
                                                    <div className='flex flex-col' >
                                                        <div className='w-20 rounded-full p-1 text-center text-sm' style={{backgroundColor:'#e5e3ff'}}>{item.subject_name}</div>
                                                        <div className='font-semibold'>
                                                            {item.course_name}
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <span className='text-bold'>{item.points}</span> <span className='font-semibold'>Points</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ):(
                                <div>
                                    No points earned yet
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CreditPoints;