import { useState, useEffect , useRef } from 'react';
import { useParams } from 'react-router-dom';

const NewPreviewSideBar = ({ sidebarData, setSelectedChapterId, setSelectedTestId,
    selectedChapterId, setTestName, selectedTestId, contentArray, setContentIndex
}) => {

    console.log('rendering child 1');
    const [currentSection, setCurrentSection] = useState("");
    const buttonRef = useRef();
    const { courseId } = useParams();

    function handleToggleClick(event) {
        const accordionHeader = event.currentTarget.parentNode.parentNode;

        accordionHeader.classList.toggle('active'); 

        const accordionBody = accordionHeader.nextElementSibling;
        if (accordionHeader.classList.contains("active")) {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
        } else {
            accordionBody.style.maxHeight = 0;
        }
    }


    useEffect(() => {
        const toggles = document.querySelectorAll('.toggle');
        console.log("toggles.length" , toggles.length)
        if (toggles.length > 0) {
            const firstToggle = toggles[0];
            console.log('firstToggle: preview', firstToggle);
            console.log('Element type: previe', firstToggle.constructor.name);

            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            firstToggle.dispatchEvent(event);
        }
    },[]);

    useEffect(() => {
        //this useEffect will run to update the height of the dropdown when user adds a slidegroup or a test
        console.log('updating the height')
        const accordionHeaders = document.querySelectorAll('div.accordion-header.active');

        accordionHeaders.forEach((accordionHeader) => {
            if (accordionHeader) {
                const accordionBody = accordionHeader.nextElementSibling;
                if (accordionHeader.classList.contains("active")) {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                } else {
                    accordionBody.style.maxHeight = 0;
                }
            }
        })

    }, [selectedChapterId, selectedTestId ])

    function handleSubTopicClick(chapId) {
        //we have to update the contentIndex 
        //find the index we need to set 
        const index = contentArray.findIndex(contentObj => contentObj.id === chapId);
        console.log('contentIndex', index);
        setContentIndex(index);
        setSelectedChapterId(chapId);
        setSelectedTestId(null);
    }

    function handleTestClick(testId, testName) {
        const index = contentArray.findIndex(contentObj => contentObj.id === testId);
        setContentIndex(index);
        setSelectedTestId(testId);
        setTestName(testName)
        setSelectedChapterId(null);
    }

    return (
        <div style={{ width: '15%', boxShadow: '0 12px 40px 0 rgba(200, 205, 212, 0.26)' }} className='h-full py-3 px-2 rounded-lg bg-white overflow-auto'>
            <div className="">
                <div className='flex justify-between items-center py-0 px-1'>
                    <span className='text-base font-bold'>Topics</span>
                </div>
            </div>
            {
                sidebarData && <div className='border-l-8 border-l-[#c7c1ff] rounded-2xl p-3 mt-2' style={{ backgroundColor: '#efeeff' }}>
                    {
                        sidebarData.topics.map((topic, index) => {
                            return (
                                <div className='w-full rounded mb-1'>
                                    <div className=''>
                                        <div className='accordion-header py-1 flex justify-between items-center bg-transparent'>
                                            <div className='flex items-center w-2/3'>
                                                <button className='toggle'
                                                    onClick={handleToggleClick}
                                                    ref={buttonRef}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4  cursor-pointer me-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                    </svg>
                                                </button>
                                                <span className='text-xs truncate w-full'>{topic.name}</span>
                                            </div>

                                        </div>
                                        <div className='accordion-body max-h-0 overflow-hidden transition-all duration-300 ease-in-out'>
                                            <div className='ml-2 px-1 pl-4'>
                                                {
                                                    topic.content.map((contentObject, index) => {
                                                        if (contentObject.type === 'slide-group') {
                                                            return (
                                                                <div name="single sub topic" className='flex 2xl:p-1 justify-between items-center mb-1 rounded-md' title={`${contentObject.name}`} style={selectedChapterId === contentObject.id ? { backgroundColor: '#bae6fd' } : {}}>
                                                                    <div className='flex w-2/3 items-center text-sm'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                                        </svg>

                                                                        <label onClick={() => { handleSubTopicClick(contentObject.id); }} className='w-full truncate text-xs'>{contentObject.name}</label>

                                                                    </div>
                                                                    {
                                                                        selectedChapterId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        if (contentObject.type === 'test') {
                                                            return (
                                                                <div name="single sub topic" className='flex justify-between items-center mb-1 rounded-md 2xl:p-1' title={`${contentObject.name}`}
                                                                    style={selectedTestId === contentObject.id ? { backgroundColor: '#bae6fd' } : {}}
                                                                >
                                                                    <div className='flex w-2/3 items-center text-sm'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                                                        </svg>
                                                                        <label onClick={() => { console.log('test clicked?'); handleTestClick(contentObject.id, contentObject.name); }} className='w-full truncate text-xs'>{contentObject.name}</label>
                                                                    </div>
                                                                    {
                                                                        selectedTestId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>);
}

export default NewPreviewSideBar;