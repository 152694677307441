import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURL } from '../../constants';
import toast from 'react-hot-toast';

const VerifyCourseModal = ({ isOpen, onClose, courseId, onCourseStatusChange, initialStatus , userId }) => {
    const [comment, setComment] = useState('');
    const [showTextarea, setShowTextarea] = useState(false);

    // Reset showTextarea whenever courseId changes
    useEffect(() => {
        setShowTextarea(false);
        setComment('');
    }, [courseId]);

    const handleReject = async () => {
        if (!showTextarea) {
            setShowTextarea(true);
            return;
        }
        if (comment.trim() === '') {
            alert('Please provide a reason for rejection.');
            return;
        }
        await handleVerifyStatus(courseId, 'rejected', comment);
        setComment('');
        onClose();
    };

    const handleVerifyStatus = async (courseId, action, reason = '') => {
        try {
            await axios.post(`${BASEURL}/api/diacto-verify-status`, {
                courseId,
                action,
                reason,
                userId
            });
            onCourseStatusChange(courseId, action === 'verify' ? 'verified' : action === 'rejected' ? 'rejected' : 'pending');
            toast.success(`Course ${action === 'verify' ? 'verified' : action === 'rejected' ? 'rejected' : 'unverified'} successfully!`);
            onClose(); // Close the modal after successful action
        } catch (error) {
            console.error(`Error verifying course:`, error);
            toast.error(`Failed to ${action} course.`);
        }
    };

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out ${isOpen ? 'block' : 'hidden'}`}>
            <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-xl">
                <div className="flex justify-between items-center pb-3">
                    <p className="text-2xl font-bold">{initialStatus === 'verified' ? 'Unverify Course' : 'Verify Course'}</p>
                    <button onClick={onClose} className="text-black">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="mb-5">
                    <p>Are you sure you want to {initialStatus === 'verified' ? 'unverify' : 'verify'} this course?</p>
                </div>
                {showTextarea && (
                    <div className="mt-3">
                        <p className="font-semibold">Reason for rejection:</p>
                        <textarea
                            className="w-full p-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500"
                            rows="3"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Enter reason for rejection..."
                        />
                    </div>
                )}
                <div className="flex justify-between mt-3">
                    {!showTextarea && initialStatus !== 'verified' && (
                        <button
                            onClick={() => setShowTextarea(true)}
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm'
                        >
                            Reject
                        </button>
                    )}
                    <div>
                        <button
                            onClick={onClose}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded text-sm ml-2"
                        >
                            Cancel
                        </button>
                        {showTextarea && (
                            <button
                                onClick={handleReject}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm ml-2"
                            >
                                Confirm Reject
                            </button>
                        )}
                        {!showTextarea && (
                            <button
                                onClick={() => handleVerifyStatus(courseId, initialStatus === 'verified' ? 'unverify' : 'verify')}
                                className={`${initialStatus === 'verified' ? 'bg-red-500 hover:bg-red-700' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded text-sm ml-2`}
                            >
                                {initialStatus === 'verified' ? 'Unverify' : 'Verify'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyCourseModal;
