import { ReactComponent as Finish } from '../../assets/Check tick svg 2.svg'
import { ReactComponent as Speaker } from '../../assets/solar_user-speak-broken Svg 2.svg'
import { ReactComponent as ArrowRight } from '../../assets/Chevron gradient svg 1.svg'
import { ReactComponent as ArrowLeft } from '../../assets/Chevron gradient left svg 1.svg'
import { useState } from 'react'

const TestFooter = ({ questionsArray, currentQuestionId, disablePrevious, disableNext, previousQuestion, nextQuestion, isSpeaking, handleSpeechStop, handleTextToSpeech, handleTestSubmit }) => {
    const [showSubmitModal , setShowSubmitModal] = useState(false);
    console.log("Test footer is called ");
    return (

        <>
            {
                showSubmitModal &&
                <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                            <div style={{ minWidth: '70%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                <div className='flex w-full justify-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"  viewBox="0 0 24 24" strokeWidth={1.5} stroke="#f4b858" className="size-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                    </svg>
                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='font-bold'>Are you sure?</span>

                                </div>
                                <div className="flex items-center py-2 px-3 flex-col">
                                    <span className='text-xs text-gray-600'>{`You want to submit the test`}</span>
                                    <span className='text-xs font-bold'>{`Attempted: ${questionsArray.filter( q => q.attempted).length} / ${questionsArray.length}`}</span>
                                </div>
                                <div className="flex justify-around">
                                    <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowSubmitModal(false) }}>Cancel</button>
                                    <button onClick={handleTestSubmit} className='px-3 py-1 bg-[#f4b858] text-white rounded-lg text-xs'>Yes, submit it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='flex relative px-4 py-0 mt-4 mb-4 items-center justify-center h-10'>
                {
                    isSpeaking ? (
                        <span onClick={() => {
                            handleSpeechStop();
                        }} className='absolute top-0 left-6 mt-2 cursor-pointer text-white'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                            </svg>
                        </span>
                    ) : (
                        <span onClick={() => {
                            //what ever content there is needs to be spoken 
                            handleTextToSpeech()
                        }} className='absolute top-0 left-6 mt-2 cursor-pointer text-white'>
                            <Speaker />
                        </span>
                    )
                }
                <div className='flex items-center text-white gap-3'>

                    <ArrowLeft style={disablePrevious()} onClick={previousQuestion} />

                    <button key={currentQuestionId} onClick={(e) => {

                    }}>
                    </button>

                    <ArrowRight style={disableNext()} onClick={nextQuestion} />

                </div>
                <div className='absolute h-full top-0 right-5 flex items-center'>
                    <button onClick={() => {setShowSubmitModal(true)}} className='p-3 rounded-xl text-white' style={{ backgroundColor: '#fa9248' }}>Submit</button>
                </div>
            </div>


        </>
    );
}

export default TestFooter;