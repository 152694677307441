import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });



  return (
    <div ref={setNodeRef} className={`bg-white flex ${props.content.length ? "justify-start" : "justify-center"} items-center flex-col
      relative w-full p-3 min-h-full h-full rounded-2xl overflow-auto gap-2`} style={{border:'1px solid gray'}}>
      {props.children}
      {
        props.selectedQuizId ? null : (
          <div className='flex flex-nowrap w-full'>
            <div style={{ width: '1px' }}></div>
            <div className={`bg-white flex justify-center items-center p-3 ${props.content.length ? "border rounded" : ""}`}
              style={{ margin: '0px 20px', width: '100%', flexDirection: "column" }}>
              <i className="fa-solid fa-plus text-gray-400 text-sm" ></i>
              <div className='text-gray-400 text-sm'>drop here</div>
            </div>
            <div style={{ width: '10px' }}></div>
          </div>
        )
      }
    </div>
  );
}