// dateFormatter.js
import {format} from 'date-fns';

const formatDate = (dateString, formatString = 'dd-MMM-yyyy') => {
  if (!dateString) return 'N/A';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';

  return format(date, formatString);
};

export default formatDate;