import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASEURL } from '../../../../constants';

const NewPreviewCourseCreator = ({ contentArray, contentIndex, setSelectedChapterId, setContentIndex,
    setSelectedTestId, imageIdArray, setImageIdArray,
    type, currentSlideData, currentSlideId, setCurrentSlideId, slidesArray, setSlidesArray, setCurrentSlideData ,setPrevious
}) => {

    const [loadingImage, setLoadingImage] = useState(false);
    const { courseId } = useParams();
    const [isSpeaking, setIsSpeaking] = useState(false);
    let currentUtterance = useRef(null);

    function nextSlide() {
        //[{id}{id}{id}]
        const currentSlideIndex = slidesArray.findIndex((slide) => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex + 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex + 1]);
    }

    function previousSlide() {
        const currentSlideIndex = slidesArray.findIndex(slide => slide.id === currentSlideId);
        setCurrentSlideId(slidesArray[currentSlideIndex - 1].id);
        setCurrentSlideData(slidesArray[currentSlideIndex - 1]);

    }

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentSlideId === slidesArray[slidesArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentSlideId === slidesArray[0].id) return disableStyle;
        return {}
    }

    function chunkText(text, maxChunkSize = 150) {
        const chunks = [];
        let start = 0;
        while (start < text.length) {
            let end = start + maxChunkSize;
            if (end < text.length) {
                // Ensure we don't split a word by finding the last space within the chunk
                while (end > start && text[end] !== ' ') {
                    end--;
                }
                if (end === start) {
                    end = start + maxChunkSize; // No space found, force split at maxChunkSize
                }
            }
            chunks.push(text.slice(start, end).trim());
            start = end + 1; // Skip the space at the split point
        }
        return chunks;
    }

    function speakChunks(chunks, index = 0) {
        if (index < chunks.length) {
            const utterance = new SpeechSynthesisUtterance(chunks[index]);
            utterance.lang = 'hi-IN';
            utterance.onend = () => {
                console.log('Utterance finished:', chunks[index]);
                speakChunks(chunks, index + 1);
                if (index === (chunks.length - 1)) {
                    setIsSpeaking(false)
                }
            };
            utterance.onerror = (event) => {
                console.error('SpeechSynthesisUtterance.onerror', event);
            };
            console.log('Speaking chunk:', chunks[index]);
            currentUtterance = utterance;
            window.speechSynthesis.speak(utterance);
        }
    }

    function handleTextToSpeech() {
        //we have currentSlideData
        let text = '';
        currentSlideData.content.forEach(element => {
            if (element.type === 'Heading') {
                text += element.data;
            }
            if (element.type === 'Text') {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = element.data;
                const t = tempDiv.textContent;
                text += t;
            }
        });
        console.log('text', text);
        if (text) {
            setIsSpeaking(true);
            const chunks = chunkText(text);
            speechSynthesis.cancel();
            speakChunks(chunks);
        } else {
            alert('No text in the slide')
        }

    }

    function handleSpeechStop() {
        if (currentUtterance) {
            window.speechSynthesis.cancel(); // Cancel the current utterance
            setIsSpeaking(false); // Update the speaking state
            currentUtterance = null;
        }
    }

    function disableJumpNext() {
        //contentArray 
        //contentIndex
        if ((contentArray.length - 1) === contentIndex) {
            //on the last contentObject disable it
            return disableStyle;
        } else {
            return {}
        }
    }

    function disableJumpBack(){
        if(contentIndex === 0){
            return disableStyle;
        }else{
            return {}
        }
    }

    function nextContent() {
        console.log('contentArray', contentArray);
        const nextContent = contentArray[contentIndex + 1];
        console.log('next content', nextContent, contentArray, contentIndex);
        if (nextContent.type === 'slide-group') {
            setSelectedChapterId(nextContent.id);
            setSelectedTestId(null);
            setContentIndex(contentIndex + 1)
        }
        if (nextContent.type === 'test') {
            setSelectedTestId(nextContent.id);
            setSelectedChapterId(null);
            setContentIndex(contentIndex + 1)
        }
    }

    function previousContent(){
        const previousContent = contentArray[contentIndex -1];
        if (previousContent.type === 'slide-group') {
            setPrevious(true)
            setSelectedChapterId(previousContent.id);
            setSelectedTestId(null);
            setContentIndex(contentIndex - 1)
        }
        if (previousContent.type === 'test') {
            setPrevious(true)
            setSelectedTestId(previousContent.id);
            setSelectedChapterId(null);
            setContentIndex(contentIndex - 1)
        }
    }

    //if the slides data is null you give a message to teacher that there is no data in the semester.
    return (<>
        {
            slidesArray ? (
                <div style={{ width: '85%' }} className='w-full h-full overflow-auto overflow-x-hidden' >
                    {/* above div had class course_creator_container */}
                    <div className='flex flex-col h-full'>
                        <div className='h-full overflow-auto py-0 px-4 relative'>
                            {/*above div had class slide  */}
                            {
                                <>
                                    <div className={`bg-white flex flex-col
                                    border border-solid border-[#e4e7eb] relative w-full p-3 min-h-full h-full rounded-2xl
                                    overflow-auto gap-2 
                                    `} >
                                        {/* <button className='cta__button' style={{position:'absolute' , top:'4px' , right:'3px'}} >Mark finish</button> */}
                                        {
                                            currentSlideData.content.map((contentObj) => {
                                                if (!contentObj.data) return;
                                                if (contentObj.type === "Heading") {
                                                    return (
                                                        <div>
                                                            <span style={{ fontSize: "20px", fontWeight: '400', lineHeight: '145%', width: '100%', color: 'black' }}>{contentObj.data}</span>
                                                        </div>
                                                    )
                                                }
                                                if (contentObj.type === "Text") {
                                                    return (
                                                        <div className='ql-snow'>
                                                            <div dangerouslySetInnerHTML={{ __html: contentObj.data }} className='ql-editor'>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                if (contentObj.type === "Quiz") {
                                                    return (
                                                        <>
                                                            {
                                                                contentObj.data ? (
                                                                    <div className="w-full">
                                                                        <div className="w-full mb-1 border rounded-md p-1 text-xs min-h-20"  >{contentObj.data.question}</div>
                                                                        <div className="flex justify-between items-center mb-2">
                                                                        </div>
                                                                        {
                                                                            contentObj.data.imageData.image ? (
                                                                                <div style={{ width: contentObj.data.imageData.width || '400px', height: contentObj.data.imageData.height || '400px' }}>
                                                                                    <img src={contentObj.data.imageData.image} style={{ height: "100%", width: "100%" }}></img>
                                                                                </div>
                                                                            ) : (null)
                                                                        }
                                                                        {
                                                                            contentObj.data.type !== 'audio' ? (
                                                                                <>
                                                                                    <section
                                                                                        className="text-xs"
                                                                                        style={{
                                                                                            display: 'grid',
                                                                                            gridTemplateColumns: 'auto auto',
                                                                                            gridTemplateRows: `repeat(${Math.ceil((contentObj.data.options.length) / 2)}, 80px)`,
                                                                                            gap: '1rem'
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            contentObj.data.type === 'single' ? (
                                                                                                contentObj.data.options.map((option, optionIndex) => {
                                                                                                    return (
                                                                                                        <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                                                                            <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500" >{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                                                                            <div className="w-4/5 outline-none text-xs" type="text" >{option}</div>
                                                                                                            <input className="text-xl" type="radio" name="radio" />
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            ) : (
                                                                                                contentObj.data.type === 'multiple' ? (
                                                                                                    contentObj.data.options.map((option, optionIndex) => {
                                                                                                        return (
                                                                                                            <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                                                                                <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                                                                                <div className="w-4/5 outline-none text-xs" type="text" >{option}</div>
                                                                                                                <input className="text-xl" type="checkbox" name="radio" />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    )
                                                                                                ) : (
                                                                                                    null
                                                                                                    // return component for audio 
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </section>
                                                                                </>
                                                                            ) : (null)
                                                                        }
                                                                    </div>
                                                                ) : (null)
                                                            }
                                                        </>


                                                    )
                                                }

                                                if (contentObj.type === "Image") {
                                                    return (
                                                        <div style={{ width: "100%", display: "flex", justifyContent: contentObj.data.align ? contentObj.data.align : 'center' }}>
                                                            {contentObj.data.imgData && (
                                                                <div style={{ width: contentObj.data.width ? contentObj.data.width : 'auto', height: contentObj.data.height ? contentObj.data.height : 'auto' }}>
                                                                    <img id={"imgId"} src={contentObj.data.imgData} style={{ height: "100%", width: "100%" }}></img>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                                if (contentObj.type === "Video") {
                                                    return (
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            {contentObj.data.ytData.videoId &&
                                                                <iframe
                                                                    className='w-2/5 h-60 2xl:h-96'
                                                                    src={`https://www.youtube.com/embed/${contentObj.data.ytData.videoId}?rel=0`}
                                                                    title="YouTube Video"
                                                                    frameBorder="0"
                                                                    allowFullScreen={true}
                                                                ></iframe>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                        <div style={{ height: '50px' }}>

                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='flex relative px-4 py-0 items-center justify-center h-10'>
                            {
                                isSpeaking ? (
                                    <span onClick={() => {
                                        handleSpeechStop();
                                    }} className='absolute top-0 left-6 mt-2 cursor-pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                                        </svg>
                                    </span>
                                ) : (
                                    <span onClick={() => {
                                        //what ever content there is needs to be spoken 
                                        handleTextToSpeech()
                                    }} className='absolute top-0 left-6 mt-2 cursor-pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                                        </svg>

                                    </span>
                                )
                            }
                            <div className='flex items-center '>
                                {
                                    slidesArray[0].id === currentSlideId ? (
                                        <svg style={disableJumpBack()} onClick={previousContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                        </svg>
                                    ) : (
                                        <svg style={disablePrevious()} onClick={previousSlide} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>
                                    )
                                }

                                <button key={currentSlideId} onClick={(e) => {
                                    const button = e.currentTarget;
                                    button.style.backgroundColor = 'green';
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </button>
                                {
                                    slidesArray[slidesArray.length - 1].id === currentSlideId ? (
                                        <svg style={disableJumpNext()} onClick={nextContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    ) : (
                                        <svg style={disableNext()} onClick={nextSlide} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>) : <div className='flex justify-center items-center w-full h-hull'>
                No content has been added yet
            </div>
        }
    </>);
}

export default NewPreviewCourseCreator;


