import React, { useEffect, useState } from "react";
import Avatar from "../assets/avatar.jpg";
import { useParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const TeacherProfile = () => {

    const { userId } = useParams();
    const [decodedToken, setDecodedToken] = useState(null);
    const navigate = useNavigate();
    const [file, setFile] = useState("");
    const [newImage, setNewImage] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(null);


    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    }

    useEffect(() => {
        // Fetch and decode the token when the component mounts
        const token = localStorage.getItem("auth");
        if (token) {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
        }
    }, []);

    const replacePlaceholders = (string, dataObject) => {
        return string.replace(
            /{(\w+)}/g,
            (match, key) => (dataObject && dataObject[key]) || "N/A"
        );
    };

    const [selectedDetails, setSelectedDetails] = useState('personal');

    const handleDetailsChange = (details) => {
        setSelectedDetails(details);
    };

    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 font-bold cursor-pointer">
                            My Profile
                        </p>
                    </div>

                </div>

            </div>

            {decodedToken ? (
                <div className="py-6 mx-auto flex gap-4 h-full">
                    <div className="bg-white rounded-md border-solid border border-gray-200 p-3 h-full w-1/5">
                        <img className="object-cover object-center w-full 2xl:h-96 rounded-lg lg:h-56" src={Avatar} alt="Placeholder" />

                        <div className="flex flex-col mt-4 ml-1">
                            <p className="text-2xl text-blue-500 font-bold cursor-pointer">{replacePlaceholders("{first_name}", decodedToken)}{" "}
                                {replacePlaceholders("{last_name}", decodedToken)}</p>

                            <div className="flex my-2 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                                </svg>

                                <p className="text-sm text-slate-900">{replacePlaceholders(
                                    "{school_name}",
                                    decodedToken
                                )}</p>
                            </div>
                            <div className="flex mb-2 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>
                                <p className="text-sm text-slate-900">{replacePlaceholders("SAP ID - {sap_id}", decodedToken)}</p>
                            </div>

                            <div className="flex mb-2 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                                </svg>

                                <p className="text-sm text-slate-900 capitalize">{replacePlaceholders("{role}", decodedToken)}</p>
                            </div>
                            <div className="flex mb-2 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
                                </svg>

                                <p className="text-sm text-slate-900">{replacePlaceholders("{email}", decodedToken)}</p>
                            </div>

                        </div>

                        {/* <div className="text-center flex items-center w-full mt-2">
                            <button
                                type="button"
                                className="inline-flex w-full truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"

                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                                <span className='text-xs ml-1'>Edit Profile</span>
                            </button>
                        </div> */}

                    </div>



                    <div className="bg-white rounded-md border-solid border border-gray-200 p-4 h-full w-4/5">
                        <div className="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap">
                            <button
                                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'personal'
                                    ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                                    : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                                    } sm:text-base whitespace-nowrap focus:outline-none`}
                                onClick={() => handleDetailsChange('personal')}
                            >
                                Personal Details
                            </button>
                            <button
                                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'address'
                                    ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                                    : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                                    } sm:text-base whitespace-nowrap cursor-base focus:outline-none`}
                                onClick={() => handleDetailsChange('address')}
                            >
                                Address Details
                            </button>
                        </div>

                        <div className="overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap">
                            <table className="w-full">
                                <tbody>
                                    {/* <!-- Entry 1 --> */}
                                    {selectedDetails === 'personal' && (
                                        <>
                                            <tr className="flex text-start border-b border-gray-200 mt-2">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>First Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders(
                                                        "{first_name}",
                                                        decodedToken
                                                    )}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 2 --> */}
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Middle Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders(
                                                        "{middle_name}",
                                                        decodedToken
                                                    )}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 3 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Last Name</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders(
                                                        "{last_name}",
                                                        decodedToken
                                                    )}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 4 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Gender</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders("{gender}", decodedToken)}
                                                </td>
                                            </tr>

                                            {/* <!-- Entry 5 --> */}

                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Date of Birth</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders("{birthday}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Aadhar Card Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders("{aadhar_card_number}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Pancard Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders("{pan_card}", decodedToken)}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {/* <!-- Address Details --> */}
                                    {selectedDetails === 'address' && (
                                        <>
                                            <tr className="flex text-start border-b border-gray-200 mt-2">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Permanent Address</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                    {replacePlaceholders(
                                                        "{permanent_address}",
                                                        decodedToken
                                                    )}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>City</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                {replacePlaceholders("{city}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>State</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                {replacePlaceholders("{state}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Zip Code</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                {replacePlaceholders("{zip_code}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Email</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                {replacePlaceholders("{email}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start border-b border-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Contact Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                {replacePlaceholders("{contact_number}", decodedToken)}
                                                </td>
                                            </tr>
                                            <tr className="flex text-start">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium"
                                                >
                                                    <button className="flex items-center gap-x-3 focus:outline-none">
                                                        <span>Alternate Number</span>
                                                    </button>
                                                </th>
                                                <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500">
                                                {replacePlaceholders("{alternative_number}", decodedToken)}
                                                </td>
                                            </tr>
                                            
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>) : (
                <div>Loading</div>
            )}





        </>
    )
}

export default TeacherProfile