import { jwtDecode } from "jwt-decode";
import { useRef, useState, useEffect } from "react";
import StudentSidebar from "./StudentSidebar";
import applogoImage from '../assets/Logo.png'
import mainImage from '../assets/Graphic Image Svg 1.svg';
import circle from '../assets/Eclipse Background 1.png';
import star from '../assets/Star gradient 1.png';
import puzzles from '../assets/Graphic Puzzle 1.png';
import go from '../assets/Arrow Gradient 1.png';
import axios from "axios";
import { BASEURL } from "../../constants";
import { truncateText } from '../Student/util/formatting'
import { Link } from "react-router-dom";

const StudentHome = () => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const sidebarRef = useRef(null);
    const [studentCourses, setStudentCourses] = useState(null);
    const [pointsEarnedToday, setPointsEarnedToday] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const navRef = useRef(null);

    const token = localStorage.getItem('auth');

    const decoded = jwtDecode(token);
    const userRole = decoded.role;
    const userId = decoded.user_id;
    const schoolId = decoded.school_id;
    const classId = decoded.class_id;
    const firstName = decoded.first_name;
    const lastName = decoded.last_name;

    useEffect(() => {

        const container = document.getElementById('container');

        if (container) {
            container.addEventListener('scroll', (e) => {

                let lastKnownScrollPosition = e.target.scrollTop;
                const navbar = navRef.current;

                if (lastKnownScrollPosition) {
                    if (lastKnownScrollPosition > 20) {
                        navbar.style.backgroundColor = '#393185';
                    } else {
                        navbar.style.backgroundColor = 'transparent';
                    }
                }
            });
        }

        return () => {
            const container = document.getElementById('container');
            if (container) {
                container.removeEventListener('scroll', (e) => { console.log("scroll event removed") });

            }
        };
    }, [studentCourses]);

    useEffect(() => {

        axios.get(`${BASEURL}/api/get-student-courses`, {
            params: {
                studentId: userId,
                schoolId: schoolId,
                classId: classId
            }
        }).then((response) => {

            setStudentCourses(response.data);
        })
    }, [])

    useEffect(() => {
        axios.get(`${BASEURL}/api/get-total-points-earned`)
            .then((response) => {
                console.log("response", response);
                if (response.data.length) {
                    setTotalPoints(response.data[0].totalPoints ?  response.data[0].totalPoints: 0)
                } else {
                    setTotalPoints(0)
                }
            })
    }, [])

    useEffect(() => {
        // Get the current date
        const now = new Date();

        // Get the start of the day in UTC
        const startTimeUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0));

        // Get the end of the day in UTC
        const endTimeUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59));

        // Convert to the desired format (YYYY-MM-DD HH:MM:SS)
        const formatDateTimeUTC = (date) => {
            return date.toISOString().slice(0, 19).replace('T', ' ');
        };

        const formattedStartTimeUTC = formatDateTimeUTC(startTimeUTC);
        const formattedEndTimeUTC = formatDateTimeUTC(endTimeUTC);

        console.log(formattedStartTimeUTC, formattedEndTimeUTC);

        axios.get(`${BASEURL}/api/get-points-earned-today`, {
            params: {
                startTime: formattedStartTimeUTC,
                endTime: formattedEndTimeUTC
            }
        }).then((response) => {
            console.log("points earned today", response);
            setPointsEarnedToday(response.data[0].pointsEarnedToday);
        });
    }, []);

    function toggleSidebar() {
        setSidebarToggle(!sidebarToggle);
    }

    return (
        <>
            {
                studentCourses && (
                    <div id="container" className="h-dvh  flex flex-col overflow-y-scroll" style={{ backgroundImage: 'linear-gradient(to bottom , #393185 10%, #7570abae 40%,#f7f8fc00)' }}>
                        <StudentSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} user={decoded} />

                        <div id="navbar" ref={navRef} className='pt-2 pb-2 flex w-full justify-between items-center px-2 text-white mb-6 fixed z-40'>
                            <span>
                                <button onClick={toggleSidebar}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                                        />
                                    </svg>
                                </button>
                            </span>
                            <span className="absolute right-1/2" style={{ translate: '50%' }}>
                                <img className="" src={applogoImage} />
                            </span>
                            <span className="flex gap-2">
                                {/* <button>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                                        />
                                    </svg>
                                </button> */}
                                <button>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </div>

                        <div className="mt-14">
                            <span className="w-full text-center text-white">
                                <div className="text-sm">Hello, {firstName}</div>
                                <div className="text-sm">Have a great day learning!</div>
                            </span>
                            <div className="h-96 relative flex flex-col justify-end mt-2 mb-2 shrink-0">
                                <img className="absolute right-1/2 top-10 z-10" style={{ translate: '50%' }} src={mainImage} />
                                <div className="h-52 ml-2 mr-2">
                                    <div className="h-36 relative z-0 rounded-2xl overflow-hidden" style={{ backgroundColor: '#393185' }}>
                                        <div className="h-full flex justify-center items-center">
                                            <div className="text-white text-center">
                                                <p>Exciting rewards comming soon!</p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <img className="absolute -top-12 -left-12" src={circle} />
                                        <img className="absolute -bottom-12 -right-12" src={circle} />
                                    </div>
                                    <div className="relative -top-5 mx-5">
                                        <div className="h-20 w-40 bg-white absolute left-0 rounded-2xl p-3">
                                            <img className="absolute -right-1 -top-1" src={star} />
                                            <p className="text-lg font-bold">{`${pointsEarnedToday}`} points</p>
                                            <p>Today earned</p>
                                        </div>
                                        <div className="h-20 w-40 bg-white absolute right-0 rounded-2xl p-3">
                                            <img className="absolute -right-1 -top-1" src={star} />
                                            <p className="text-lg font-bold">{`${totalPoints}`} points</p>
                                            <p>On the way</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="h-52 relative bg-gray-50 mx-2 rounded-2xl p-4 overflow-hidden" style={{ backgroundImage: 'linear-gradient(to right, #ff9741 , #ffeedf)' }}>
                                    <img className="absolute -right-5 top-1/2" src={puzzles} style={{ transform: 'translateY(-50%)' }} />
                                    <span className="rounded-full px-3  text-xs font-semibold" style={{ backgroundColor: '#ffbb83' }}>Earn Extra Points</span>
                                    <div className="mt-3 text-base font-bold w-40">Pond Puzzles: Ducklings and Fractions</div>
                                    <div>
                                        <button className="px-2 text-xs text-white rounded-full mt-3" style={{ backgroundColor: '#393185' }}>Start</button>
                                    </div>

                                </div>
                            </div>
                            <div className="flex justify-between m-2">
                                <span className="font-semibold">For you</span>
                                <Link to={`/student/myCourses`}>
                                    <span className="font-semibold" style={{ color: '#fa9248' }}>See All</span>
                                </Link>
                            </div>
                            {
                                studentCourses.length > 0 ? (
                                    <div className="grid grid-cols-2 grid-rows-4 mx-2 gap-4 mb-5">
                                        {
                                            studentCourses.slice(0, 4).map((course, index) => (

                                                <div className={`row-span-2 border rounded-xl p-2 flex flex-col ${index % 2 === 0 ? 'bg-indigo-900 text-white' : 'bg-white'}`} style={{ boxShadow: '0 4px 4px 0 rgba(57, 49, 133, 0.08)' }}>
                                                    <Link to={`/CourseDetails/${course.id}/${course.course_id}`} className="h-full">
                                                        <div className="flex flex-col justify-between h-full">
                                                            <div className="mb-3">
                                                                <span className="px-3 rounded-full py-1 text-xs" style={index % 2 === 0 ? { backgroundColor: '#4d4698' } : { backgroundColor: '#ddd9fc' }}>{course.subject_name}</span>
                                                            </div>
                                                            <div className="grow">
                                                                <div className="w-2/3 py-2 font-bold text-base mb-3" >
                                                                    {course.course_name}
                                                                </div>
                                                                <div className="mb-3">
                                                                    {truncateText(course.course_desc, 40)}
                                                                </div>
                                                            </div>
                                                            <div className="text-right">
                                                                <button ><img src={go} /></button>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="mx-2 mb-5 text-center">
                                        No Courses To View
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )
            }
        </>
    );
}

export default StudentHome;