import { ReactComponent as IconButton } from '../../assets/Icon Button.svg';
import { ReactComponent as TestSvg } from '../../assets/Test svg.svg';
import { ReactComponent as Notes } from '../../assets/Notes svg 1.svg';
import TestModal from './TestModal';
import { useState } from 'react';
import Check from '../icons/Check';

const Sidebar = ({attemptNumber, setIsTestSubmitted,setShowPassModal,setShowFailModal,setIsTestStarted,setAttemptNumber , setTestStartTime , setTestName, sidebarToggle, setSidebarToggle, sidebarData, selectedSubtopicId, setSelectedSubtopicId, setTopicName, setSubtopicName , setSelectedTestId }) => {
    console.log("rendering sidebar ", sidebarToggle);
    const [testModal, setTestModal] = useState({
        showModal:false, 
        testId: null
    });

    const defaultStyle = {
        margin: ' 12px 0 0', boxShadow: '0 0 9.5px 0 rgba(57, 49, 133, 0.04)', backgroundColor: '#fcfbfd'
    }
    return (
        <>
            {
                testModal.showModal &&
                    <TestModal attemptNumber={attemptNumber} setIsTestSubmitted={setIsTestSubmitted} setShowPassModal={setShowPassModal} setShowFailModal={setShowFailModal} setIsTestStarted={setIsTestStarted} setAttemptNumber={setAttemptNumber} setTestStartTime={setTestStartTime} setSidebarToggle={setSidebarToggle} setTestModal={setTestModal} testId={testModal.testId} setSelectedTestId={setSelectedTestId} setSelectedSubtopicId={setSelectedSubtopicId}/>
            }
            <div className={`absolute left-0 top-0 transition-all duration-300 
                        ${sidebarToggle ? "w-full" : "w-0"} 
                        h-dvh overflow-hidden pb-0 z-40`}

                style={{ backgroundColor: '#f5f4fd' }}
            >
                <div className="flex justify-between p-2 pt-4">
                    <span className="font-bold">Topics</span>
                    <button onClick={() => {
                        setSidebarToggle(false)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {
                    sidebarData && (
                        <section className="p-2">
                            {
                                sidebarData.topics.map((topic, index) => (
                                    <div className="p-2">
                                        <div className="flex gap-3">
                                            <div className="w-6 h-6 rounded-md text-center font-medium" style={{ backgroundColor: '#e6e3fa' }} >{index + 1}</div>
                                            <div className="font-medium">{topic.name}</div>
                                        </div>
                                        <div>
                                            {
                                                topic.content.map((contentObject) => {
                                                    if (contentObject.type === 'slide-group') {
                                                        return (
                                                            <div onClick={() => {
                                                                setSelectedSubtopicId(contentObject.id);
                                                                setSidebarToggle(false);
                                                                setTopicName(topic.name);
                                                                setSubtopicName(contentObject.name);
                                                                setSelectedTestId(null)
                                                            }} className='flex justify-around items-center gap-3 p-2 px-3 rounded-xl' style={selectedSubtopicId === contentObject.id ? { ...defaultStyle, border: 'solid 1px #fa9248' } : { ...defaultStyle, border: 'solid 1px #ede8ff' }}>
                                                                <div className='flex justify-center items-center w-10 h-10 rounded-full' style={{ backgroundColor: '#dfdcf9' }} >
                                                                    <Notes />
                                                                </div>
                                                                <div className='grow'>
                                                                    <div className='font-medium'>{contentObject.name}</div>
                                                                    <div className='font-medium'>{contentObject.slideCount} slides</div>
                                                                </div>
                                                                {
                                                                    contentObject.status === 'complete' ? (
                                                                        <Check />
                                                                    ):(
                                                                        null
                                                                    )
                                                                }
                                                                <div>
                                                                    <IconButton />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    if (contentObject.type === 'test') {
                                                        return (
                                                            <div onClick={() => {
                                                                setTestModal({showModal:true , testId:contentObject.id});
                                                                setTopicName(topic.name);
                                                                setTestName(contentObject.name)
                                                            }} className='flex justify-around items-center gap-3 p-2 px-3 rounded-xl' style={{ border: 'solid 1px #ede8ff', margin: ' 12px 0 0', boxShadow: '0 0 9.5px 0 rgba(57, 49, 133, 0.04)', backgroundColor: '#fcfbfd' }}>
                                                                <div className='flex justify-center items-center w-10 h-10 rounded-full' style={{ backgroundColor: '#dfdcf9' }} >
                                                                    <TestSvg />
                                                                </div>
                                                                <div className='grow'>
                                                                    <div className='font-medium'>{contentObject.name}</div>
                                                                    <div className='font-medium'>{contentObject.numberOfQuestions} questions</div>
                                                                </div>
                                                                {
                                                                    contentObject.status === 'complete' ? (
                                                                        <Check />
                                                                    ):(
                                                                        null
                                                                    )
                                                                }
                                                                <div>
                                                                    <IconButton />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </section>
                    )
                }
            </div>
        </>
    );
}

export default Sidebar;