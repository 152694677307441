import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = ({baseRoute , isDataSaved , setIsDataSaved , setShowSaveDataModal ,showSaveDataModal, courseInfo, selectedChapterId, selectedTestId, handleSaveCourse, handleAllCoursesClick }) => {

    const navigate = useNavigate();
    const { courseId } = useParams();

    
    return (    
        <>
        {
            showSaveDataModal && (
                <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                            <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                <div className='flex w-full justify-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                    </svg>
                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='font-bold'>Save changes</span>

                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='text-xs text-gray-600'>Save your latest changes first!</span>

                                </div>
                                <div className="flex justify-around">
                                    <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setIsDataSaved(true); setShowSaveDataModal(false) }}>Cancel</button>
                                    <button onClick={() => { handleSaveCourse(); setShowSaveDataModal(false) }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
            {
                courseInfo && (
                    <div className="flex justify-between rounded-xl items-center px-4 py-2 bg-white" style={{border:'1px solid rgba(207, 202, 255, 0.7)' , boxShadow:'2px 2px 12px 0 rgba(57, 49, 133, 0.08)'}}>
                        <div className="flex items-center">
                            {
                                baseRoute === 'course-builder' && (
                                    <Link to="/myCourses/draftCourses" onClick={handleAllCoursesClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </Link>
                                )
                            }
                            {
                                baseRoute === 'admin-course-builder'  && (
                                    <Link to="/diactoAdmin/courses" onClick={handleAllCoursesClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </Link>
                                )
                            } 
                           
                            <div className="flex flex-col items-start py-0 px-4">
                                <span className="border text-black py-1 px-2 uppercase rounded-xl text-xs 2xl:text-base font-semibold" style={{backgroundColor:'#ffe8d8'}}> {courseInfo.subject_name}</span>
                                <span className="text-base 2xl:text-lg font-bold">{courseInfo.course_name}</span>
                                <span className="text-gray-500 text-xs truncate max-w-96 2xl:text-lg" title={courseInfo.course_desc}>{courseInfo.course_desc}</span>
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            {/* <i class="fa-solid fa-palette icon-medium bordered-icon"></i> */}
                            <button onClick={() => {
                                if(isDataSaved){
                                    navigate(`/quick-preview/${courseId}`)
                                }else{
                                    setShowSaveDataModal(true);
                                }
                                
                            }} class="text-xs 2xl:text-base py-1.5 px-2 rounded-md cursor-pointer bg-transparent text-[#fa9248] border-2 border-solid border-[#fa9248] flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" width={'1rem'} height={'1rem'} stroke="#fa9248" class="icon-small">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                View as student
                            </button>
                            <span className="h-6 border-l-2 border-[#d3cfff] mx-3"></span>
                            <button class="text-xs 2xl:text-base py-1.5 px-2 rounded-md cursor-pointer bg-[#fa9248]  text-white" onClick={() => { handleSaveCourse('normal') }}>Save course</button>
                            {/* <button class="text-xs py-1.5 px-2 rounded-md cursor-pointer bg-slate-800 text-gray-100 border border-solid border-slate-500 font-bold">Publish</button> */}
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Header;