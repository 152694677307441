import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASEURL } from "../../../constants.js";
// import toast from 'react-hot-toast'
// import coverPhoto from '../../assets/coverPhoto.webp'
import DiactoCreateCourseModal from "./DiactoCreateCourseModal";

import DiactoPublishedCourses from "./DiactoPublishedCourses.jsx";
import DiactoDraftCourses from "./DiactoDraftCourses.jsx";
// import PublishCourseModal from "./PublishCourseModal.jsx";

// Custom hook for debouncing
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const DiactoMyCourses = () => {
  const [userCourses, setUserCourses] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    state: false,
    courseId: null,
  });
  // const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState({ state: false, courseId: null });
  const [filteredData, setFilteredData] = useState([]); // Filtered school data for display
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Apply debouncing
  const [courseDetails, setCourseDetails] = useState(null);
  const [render, setRender] = useState(false)
  const [publishCourseModal, setPublishCourseModal] = useState(false);
  const navigate = useNavigate();

  const [dropdownState, setDropdownState] = useState({}); // State to track each dropdown's open state
  const [activeTab, setActiveTab] = useState('published'); // Default to 'published' tab

  const toggleDropdown = (courseId) => {
    setDropdownState(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId]
    }));
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const userId = localStorage.getItem('auth');
        if (userId) {
          const response = await axios.post(`${BASEURL}/api/fetch-courses`, { user_id: userId });
          const courseData = response.data.courseData;
          setUserCourses(courseData.length ? courseData : []);
        }
      } catch (error) {
        console.error("Error fetching user courses:", error);
      }
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    const filtered = userCourses.filter(course =>
      course.course_name && course.course_name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [debouncedSearchTerm, userCourses]);

  function deleteCourse(courseId) {
    axios.delete(`${BASEURL}/api/delete-course`, {
      data: { courseId: courseId },
    })
      .then((response) => {
        if (response.data.status === "success") {
          setUserCourses(userCourses => userCourses.filter(course => course.id !== courseId));
        }
      });
  }

  function handleDelete(courseId) {
    setShowDeleteModal({ state: true, courseId: courseId });
  }

  function handleCloseDeleteModal() {
    setShowDeleteModal({ state: false, courseId: null });
  }

  const fetchCourseData = async (courseId) => {
    try {
      const response = await axios.get(`${BASEURL}/api/courses/${courseId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching course data:', error);
      return null;
    }
  };

  function formatArrayToString(array) {
    return array.join(', ');
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
    <>
      <DiactoCreateCourseModal isOpen={isModalOpen} onClose={handleCloseModal} />


      <div className='mx-4'>
        <div className='pt-2 pb-3 flex justify-between '>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
              My Courses
            </p>
          </div>
          <div className='flex flex-row justify-between flex-x-2'>
            <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
              <div className="grid place-items-center w-12 text-gray-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Search Course Name"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button type="button" className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " onClick={handleOpenModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
              <span className='text-sm'>Create Course</span>
            </button>
          </div>
        </div>
        <div class="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap dark:border-gray-700">
          <button
            className={`inline-flex items-center h-10 px-4 -mb-px text-sm font-medium text-center ${activeTab === 'published' ? 'text-blue-600 bg-transparent border-b-2 border-blue-500' : 'text-gray-700 bg-transparent border-b-2 border-transparent'
              } sm:text-base dark:border-blue-400 dark:text-blue-300 whitespace-nowrap cursor-pointer focus:outline-none`}
            onClick={() => setActiveTab('published')}>
            Published Courses
          </button>
          <button
            className={`inline-flex items-center h-10 px-4 -mb-px text-sm font-medium text-center ${activeTab === 'drafts' ? 'text-blue-600 bg-transparent border-b-2 border-blue-500' : 'text-gray-700 bg-transparent border-b-2 border-transparent'
              } sm:text-base dark:border-blue-400 dark:text-blue-300 whitespace-nowrap cursor-pointer focus:outline-none`}
            onClick={() => setActiveTab('drafts')}>
            Drafts Courses
          </button>
        </div>
        <div className="py-6 mx-auto">
          {activeTab === 'published' ? <DiactoPublishedCourses searchTerm={debouncedSearchTerm} /> :
            <DiactoDraftCourses searchTerm={debouncedSearchTerm} />}
        </div>
      </div>
    </>
  )
}

export default DiactoMyCourses



