import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constants";
import toast from 'react-hot-toast'
import coverPhoto from '../assets/coverPhoto.webp'
import { RxCross2 } from "react-icons/rx";
import VerifyCourseModal from "./VerifyCourseModal";

// Custom hook for debouncing
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const DiactoVerifyCourses = () => {


    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userId, setUserId] = useState(null);
    const [filteredData, setFilteredData] = useState([]); // Filtered school data for display
    const [searchTerm, setSearchTerm] = useState(""); // Search term state
    const debouncedSearchTerm = useDebounce(searchTerm, 500); // Apply debouncing
    const navigate = useNavigate();

    const [dropdownState, setDropdownState] = useState({}); // State to track each dropdown's open state

    const [coverImages, setCoverImages] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCourseId, setCurrentCourseId] = useState(null);


    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = imageUrl;
        });
    }

    const toggleDropdown = (courseId) => {
        setDropdownState(prevState => ({
            ...prevState,
            [courseId]: !prevState[courseId]
        }));
    };


    const fetchCoverImages = async (courses) => {
        const images = {};
        for (const course of courses) {
            try {
                console.log(`Fetching image for course ${course.id}`);
                const response = await axios.post(`${BASEURL}/api/retrieve-cover-image`, { courseId: course.id });
                console.log(`Received response for course ${course.id}:`, response.data);

                if (response.data.dataUrl) {
                    const imageExists = await checkImageExists(response.data.dataUrl);
                    if (imageExists) {
                        images[course.id] = response.data.dataUrl;
                    } else {
                        console.log(`Image for course ${course.id} does not exist in S3 bucket`);
                        images[course.id] = null; // or a default image URL
                    }
                } else {
                    console.log(`No image URL received for course ${course.id}`);
                    images[course.id] = null; // or a default image URL
                }
            } catch (error) {
                console.error(`Error fetching cover image for course ${course.id}:`, error);
                images[course.id] = null; // or a default image URL
            }
        }
        console.log("All fetched images:", images);
        setCoverImages(images);
    };

    useEffect(() => {

        const fetchCourses = async () => {
            try {
                const response = await axios.post(`${BASEURL}/api/diacto-fetch-courses-for-verification`);

                fetchCoverImages(response.data.courses);
                setCourses(response.data.courses || []);
                console.log("Courses", response.data)
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching courses:", error);
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchCourses();
    }, []);


    // Effect for filtering schools based on the debounced search term
    useEffect(() => {
        const filtered = courses.filter(course =>
            course.course_name && course.course_name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    }, [debouncedSearchTerm, courses]);

    // useEffect(() => {

    // }, []);


    const handleOpenModal = (courseId, userId) => {
        setCurrentCourseId(courseId);
        setIsModalOpen(true);
        setUserId(userId);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentCourseId(null);
    };

    const handleCourseStatusChange = (courseId, newStatus) => {
        const updatedCourses = courses.map((c) =>
            c.id === courseId
                ? { ...c, verify_status: newStatus }
                : c
        );
        setCourses(updatedCourses);
    };

    function formatArrayToString(array) {
        return array.join(', ');
    }


    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const truncateGrade = (grade, maxLength) => {
        if (grade.length <= maxLength) {
            return `Grade: ${grade}`;
        } else {
            const truncatedGrade = grade.substring(0, maxLength);
            return `Grade: ${truncatedGrade} + more`;
        }
    };





    function checkImageExists(imageUrl) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(true);
            image.onerror = () => resolve(false); // We're using resolve(false) instead of reject() to handle the case gracefully
            image.src = imageUrl;
        });
    }

    function formatClassId(classId) {
        // Check if classId is a string, if not, convert it to a string
        const classIdString = typeof classId === 'string' ? classId : String(classId);

        // Use regex to match all sequences of digits, sort them, and join them with commas and spaces
        const sortedClassIds = classIdString.match(/\d+/g)?.map(Number).sort((a, b) => a - b).join(', ') || '';

        return sortedClassIds;
    }


    function formatTags(tag) {
        if (!tag || !Array.isArray(tag) || tag.length === 0) {
            return 'No tags available'; // Handle case where tag is not an array, is empty, or has no elements
        }

        // Join the array elements into a comma-separated string
        return tag.join(', ');
    }


    return (
        <>
            <VerifyCourseModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                courseId={currentCourseId}
                onCourseStatusChange={handleCourseStatusChange}
                initialStatus={courses.find(c => c.id === currentCourseId)?.verify_status || 'pending'}
                userId={userId}
            />

            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Verify Courses
                        </p>
                    </div>
                    <div className='flex flex-row justify-between flex-x-2'>
                        <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
                            <div className="grid place-items-center w-12 text-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input
                                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                                type="text"
                                id="search"
                                placeholder="Search Course Name"
                                onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                </div>

            </div>

            <div className=" py-6 mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6">
                {filteredData.map((course, index) => (

                    <div className='bg-white rounded-md border-solid border border-gray-200 p-4 h-max max-w-md relative m-1'>
                        {course.verify_status === 'verified' && (
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10 absolute -top-2.5 right-2">
                                Verified
                            </span>
                        )}
                        {course.verify_status === 'pending' && (
                            <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10 absolute -top-2.5 right-2">
                                Pending
                            </span>
                        )}
                        {course.verify_status === 'rejected' && (
                            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10 absolute -top-2.5 right-2">
                                Rejected
                            </span>
                        )}
                        <div className="flex justify-between">

                            <h1 className="mb-4 text-md font-semibold text-gray-800 dark:text-white">
                                {truncateText(course.course_name, 20)}
                            </h1>
                            <div x-data="{ isOpen: true }" className="relative inline-block ">
                                <button
                                    className="relative z-10 block p-1 text-gray-700 border border-transparent rounded-md dark:text-white focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring dark:bg-gray-800 focus:outline-none"
                                    onClick={() => toggleDropdown(course.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4 font-bold">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                    </svg>
                                </button>

                                {dropdownState[course.id] && (
                                    <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800">
                                        <Link to={`/verify-view/${course.id}`} className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                            </svg>
                                            <span className="mx-1">
                                                View Course
                                            </span>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>

                        {coverImages[course.id] ? (
                            <img
                                src={coverImages[course.id]}
                                alt={`${course.course_name} cover`}
                                className="object-cover object-center w-full h-64 rounded-lg lg:h-40 transition-all duration-300 group-hover:blur-sm group-hover:brightness-75"
                                onError={(e) => {
                                    console.error(`Error loading image for course ${course.id}:`, e);
                                    e.target.src = 'path/to/fallback/image.jpg';
                                }}
                            />
                        ) : (
                            <div className="w-full h-64 bg-gray-200 rounded-lg lg:h-40 flex items-center justify-center group-hover:blur-sm group-hover:brightness-75">
                                <p>{coverImages[course.id] === undefined ? 'Loading image...' : 'No image available'}</p>
                            </div>
                        )}


                        <div className="mt-4">
                            <p className="mt-2 text-gray-500 dark:text-gray-400 h-12 text-sm">
                                {truncateText(course.course_desc, 65)}
                            </p>

                            <div className="flex flex-col w-full gap-2 mt-1 rounded-sm">
                                <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                    <span className="font-semibold mr-1">Grade :</span>  {formatClassId(course.class_id)}
                                </span>
                                <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                    <span className="font-semibold mr-1">Difficulty Level :</span>
                                    <span className="capitalize">{course.difficulty_level}</span>
                                </span>
                                <span className="bg-blue-100 flex items-center text-gray-700 justify-start text-xs rounded-sm py-2 px-3 w-full">
                                    <span className="font-semibold mr-1">Tags :</span>  {formatTags(course.tags)}
                                </span>
                            </div>


                            <div className='flex flex-row gap-2 justify-between px-1 flex-wrap mt-3'>
                                <div className="flex flex-col items-start mt-4">
                                    <p className="text-xs text-gray-500 dark:text-gray-400">{truncateText(course.teacher_name, 14)}</p>
                                    <p className="text-sm font-medium text-gray-700 dark:text-gray-300 cursor-pointer">
                                        Creator Name
                                    </p>
                                </div>
                                <div className="flex flex-col items-start mt-4">
                                    <p className="text-xs text-gray-500">{course.slide_count ? course.slide_count : 0}</p>
                                    <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                        Slides
                                    </p>
                                </div>
                                <div className="flex flex-col items-start mt-4">
                                    <p className="text-xs text-gray-500">{course.test_count ? course.test_count : 0}</p>
                                    <p className="text-sm font-medium text-gray-700 cursor-pointer">
                                        Tests
                                    </p>
                                </div>

                                <div className="text-center flex items-center mt-4  rounded-full">

                                    {course.verify_status === 'verified' ? (
                                        <div className="hidden"></div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="inline-flex truncate gap-2 justify-center items-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 border border-blue-500 hover:border-transparent rounded-full"
                                            onClick={() => handleOpenModal(course.id, course.user_id)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-4 h-4 text-xs font-bold"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m4.5 12.75 6 6 9-13.5"
                                                />
                                            </svg>
                                        </button>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default DiactoVerifyCourses