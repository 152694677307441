import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NewStudentPreviewComponent from './New_Preview_Components/NewPreviewComponent.jsx'
import MobilePreview from "./MobileComponents/MobilePreview.jsx";
import axios from "axios";
import { BASEURL } from '../../../constants.js';
import { useLocation } from "react-router-dom";

const Preview = () => {
    const [type, setType] = useState('desktop');
    const [courseInfo, setCourseInfo] = useState(null);

    const location = useLocation();
    const baseRoute = location.pathname.split('/')[1];
    const { courseId } = useParams();
    useEffect(() => {
        axios.get(`${BASEURL}/api/fetch-course-info`, {
            params: {
                courseId: courseId
            }
        }).then((res) => {
            console.log(res.data.rows, res.data);
            setCourseInfo(res.data.rows)
        })
    }, [])

    return (
        <>
            {
                courseInfo ? (
                    <div className="flex flex-col h-screen bg-[#f6f5ff] p-4">
                        <div className="flex relavtive justify-between rounded-xl items-center px-4 py-2 bg-white" style={{border:'1px solid rgba(207, 202, 255, 0.7)' , boxShadow:'2px 2px 12px 0 rgba(57, 49, 133, 0.08)'}}>
                            <div className="flex items-center">
                                {
                                    baseRoute === 'quick-preview' && (
                                        <Link to={`/course-builder/${courseId}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        </Link>)
                                }
                                {
                                    baseRoute === 'publish-view' && (<Link to="/myCourses/publishedCourses">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </Link>)

                                }
                                {
                                    baseRoute === 'draft-view' && (<Link to="/myCourses/draftCourses">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </Link>)

                                }
                                {
                                    baseRoute === 'admin-view' && (<Link to="/diactoAdmin/courses">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </Link>)

                                }
                                {
                                    baseRoute === 'verify-view' && (<Link to="/diactoAdmin/verifyCourses">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </Link>)

                                }
                                {
                                    baseRoute === 'store-view' && (<Link to="/store">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10 text-gray-900 hover:text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </Link>)

                                }
                                <div className="flex flex-col items-start py-0 px-4">
                                    <span className="border bg-slate-200 text-gray-600 py-1 px-2 uppercase rounded-xl text-xs"> {courseInfo?.subject_name}</span>
                                    <span className="text-base 2xl:text-lg font-bold">{courseInfo?.course_name}</span>
                                    <span className="text-gray-500 text-xs truncate max-w-96 2xl:text-lg" title={courseInfo.course_desc}>{courseInfo?.course_desc}</span>
                                </div>
                            </div>
                            <div className="flex gap-2 items-center absolute right-1/2" style={{ translate: '50%' }}>
                                <button onClick={() => { setType('mobile') }} className={`${type === 'mobile' ? 'border-b-2 border-b-sky-500' : ''} text-xs`}>Mobile</button>
                                <button onClick={() => { setType('desktop') }} className={`${type === 'desktop' ? 'border-b-2 border-b-sky-500' : ''} text-xs`}>Desktop</button>
                            </div>
                        </div>
                        <section className="flex grow h-1 py-3 px-2">
                            {
                                type === 'mobile' ? (
                                    <div className="flex justify-center items-center h-full w-full">
                                        <MobilePreview />
                                    </div>
                                ) : (
                                    <div className="flex w-full h-full">
                                        <NewStudentPreviewComponent />
                                    </div>
                                )
                            }
                        </section>
                    </div>
                ) : (null)
            }
        </>
    )

}

export default Preview;