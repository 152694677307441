import TopicSubTopicView from "./TopicSubTopicView";
import SlideView from "./SlideView";
import Question from "./Question";
import TestFooter from "./TestFooter";
import { useState, useRef } from 'react';
import CountdownTimer from "./CountdownTimer";
import { jwtDecode } from "jwt-decode";
import { BASEURL } from "../../../constants";
import axios from 'axios';
import {ReactComponent as CompletedSVG } from '../../assets/Completed svg.svg'
import TestInfoView from "../components/TestInfoView/TestInfoView";

const TestConsumption = ({showFailModal,setShowFailModal,showPassModal,setShowPassModal, isTestSubmitted,setIsTestSubmitted , setIsTestStarted, setSidebarToggle, attemptNumber, testStartTime, sharedCourseId, courseId, selectedTestId, testTimeMinutes, topicName, testName, questionsArray, currentQuestionId, question, setCurrentQuestionId, setQuestion, setQuestionsArray }) => {
    console.log("Test Consumption is called" , questionsArray);

    const [isSpeaking, setIsSpeaking] = useState(false);

    let currentUtterance = useRef(null);

    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);
    const studentId = decoded.user_id;

    function chunkText(text, maxChunkSize = 150) {
        const chunks = [];
        let start = 0;
        while (start < text.length) {
            let end = start + maxChunkSize;
            if (end < text.length) {
                // Ensure we don't split a word by finding the last space within the chunk
                while (end > start && text[end] !== ' ') {
                    end--;
                }
                if (end === start) {
                    end = start + maxChunkSize; // No space found, force split at maxChunkSize
                }
            }
            chunks.push(text.slice(start, end).trim());
            start = end + 1; // Skip the space at the split point
        }
        return chunks;
    }

    function speakChunks(chunks, index = 0) {
        if (index < chunks.length) {
            const utterance = new SpeechSynthesisUtterance(chunks[index]);
            utterance.lang = 'hi-IN';
            utterance.onend = () => {
                console.log('Utterance finished:', chunks[index]);
                speakChunks(chunks, index + 1);
            };
            utterance.onerror = (event) => {
                console.error('SpeechSynthesisUtterance.onerror', event);
            };
            console.log('Speaking chunk:', chunks[index]);
            currentUtterance = utterance;
            window.speechSynthesis.speak(utterance);
        }
    }

    function handleTextToSpeech() {
        let text = question.text;
        
        if (text) {
            const chunks = chunkText(text);
            speechSynthesis.cancel();
            speakChunks(chunks);
        } else {
            alert('No text in the slide')
        }

    }

    function handleSpeechStop() {
        if (currentUtterance) {
            window.speechSynthesis.cancel(); // Cancel the current utterance
            setIsSpeaking(false); // Update the speaking state
            currentUtterance = null;
        }
    }
    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentQuestionId === questionsArray[questionsArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentQuestionId === questionsArray[0].id) return disableStyle;
        return {}
    }

    function nextQuestion() {
        //[{id}{id}{id}]
        const currentQuestionIndex = questionsArray.findIndex((questionsArray) => questionsArray.id === currentQuestionId);
        setCurrentQuestionId(questionsArray[currentQuestionIndex + 1].id);
        setQuestion(questionsArray[currentQuestionIndex + 1]);
    }

    function previousQuestion() {
        const currentQuestionIndex = questionsArray.findIndex(questionsArray => questionsArray.id === currentQuestionId);
        setCurrentQuestionId(questionsArray[currentQuestionIndex - 1].id);
        setQuestion(questionsArray[currentQuestionIndex - 1]);

    }

    function onFinish() {
        console.log('auto submit test');
    }

    function handleTestSubmit() {
        //send the student answers 
        const testFinishTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
        axios.post(`${BASEURL}/api/submit-student-answers`, {
            testId: selectedTestId,
            courseId: sharedCourseId,
            studentAnswers: questionsArray,
            studentId: studentId,
            startTime: testStartTime,
            finishTime: testFinishTime,
            attemptNumber: attemptNumber ? (attemptNumber + 1) : 1
        }).then((response) => {
            //pass fail 
            // reset the students answers { answers : [] , attempted : true } 
            setQuestionsArray((questionsArray)=>{
                return questionsArray.map((question)=>{
                    return { ...question , answers: [] , attempted: false }
                })
            })
            setQuestion((question)=>{
                const firstQuestion = questionsArray[0];
                return {...firstQuestion , answers: [] , attempted: false }
            })
            setCurrentQuestionId(questionsArray[0].id);
            setIsTestSubmitted(true);
            setIsTestStarted(false);
            if (response.data.result === 'pass') {
                setShowPassModal(true)
            }
            if (response.data.result === 'fail') {
                setShowFailModal(true)
            }
        })
    }

    if ( showFailModal ) {
        return (
            <div className="h-full flex justify-center items-center">
                <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                    <div className='flex w-full justify-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                        </svg>
                    </div>
                    <div className='font-bold text-center'>
                        Test Failed!
                    </div>
                    <div className="text-center">
                        <button onClick={() => {
                            setSidebarToggle(true);
                            setIsTestStarted(false);
                        }} className='p-3 rounded-xl text-white' style={{ backgroundColor: '#fa9248' }}>Go to course and try again</button>
                    </div>
                </div>
            </div>
        )
    }

    if (showPassModal) {
        return (
            <div className="h-full flex justify-center items-center">
                <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">
                    <div className="flex justify-center">
                        <CompletedSVG />
                    </div>
                    <div className='font-bold text-center'>
                        Test Passed!
                    </div>
                    <div className="flex justify-center">
                        <button onClick={() => {
                            setSidebarToggle(true);
                            setIsTestStarted(false);
                            setShowFailModal(false);
                        }} className='p-3 rounded-xl text-white' style={{ backgroundColor: '#fa9248' }}>Go to course</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col h-full">
            <TopicSubTopicView nameOne={topicName} nameTwo={testName} />
            <TestInfoView questionsArray={questionsArray} currentQuestionId={currentQuestionId} />
            <CountdownTimer time={testTimeMinutes} onFinish={handleTestSubmit} isTestSubmitted={isTestSubmitted} />
            <Question question={question} setQuestion={setQuestion} setQuestionsArray={setQuestionsArray} />
            <TestFooter
                currentQuestionId={currentQuestionId}
                disablePrevious={disablePrevious}
                disableNext={disableNext}
                nextQuestion={nextQuestion}
                isSpeaking={isSpeaking}
                previousQuestion={previousQuestion}
                handleTextToSpeech={handleTextToSpeech}
                handleTestSubmit={handleTestSubmit}
                questionsArray={questionsArray}
            />
        </div>
    );
}

export default TestConsumption;