import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURL } from '../../../constants';
import toast from 'react-hot-toast';
import Select from 'react-select';

const ShareCourseModal = ({ isOpen, onClose, courseDetails }) => {
  const [courseName, setCourseName] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseId, setCourseId] = useState('');
  const [classOptions, setClassOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [studentsData, setStudentsData] = useState({});

  useEffect(() => {
    if (courseDetails) {
      setCourseName(courseDetails.courseName || '');
      setSubjectName(courseDetails.subjectName || '');
      setCourseDescription(courseDetails.courseDescription || '');
      setCourseId(courseDetails.id || '');
    }
  }, [courseDetails]);

  useEffect(() => {
    const fetchClassOptions = async () => {
      try {
        const response = await axios.get(`${BASEURL}/api/get-classes`);
        setClassOptions(response.data.classes.map(c => ({
          value: c.class_id.toString(),
          label: c.class_name
        })));
      } catch (error) {
        console.error('Error fetching classes:', error);
        // toast.error("Failed to fetch class data.");
      }
    };

    fetchClassOptions();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      const studentsMap = {};
      try {
        await Promise.all(selectedData.map(async classData => {
          const response = await axios.get(`${BASEURL}/api/get-students/${classData.class}`);
          studentsMap[classData.class] = response.data.students.map(s => ({
            value: s.student_id.toString(),
            label: `${s.first_name} ${s.last_name}`
          }));
        }));
        setStudentsData(studentsMap);
      } catch (error) {
        console.error("Error fetching students:", error);
        toast.error("Failed to fetch students.");
      }
    };

    if (selectedData.length > 0) {
      fetchStudents();
    }
  }, [selectedData]);

  const handleClassChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedData(prevData => {
        const newData = prevData.filter(item => item.class !== parseInt(selectedOption.value));
        newData.push({ class: parseInt(selectedOption.value), student: [] });
        return newData;
      });
    }
  };

  const handleStudentChange = (selectedOptions, selectedClass) => {
    setSelectedData(prevData => {
      return prevData.map(item => {
        if (item.class === selectedClass) {
          return { ...item, student: selectedOptions.map(option => parseInt(option.value)) };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    console.log("Updated Formatted Data:", selectedData);
  }, [selectedData]);

  const handleSubmit = async () => {
    if (!courseId || selectedData.length === 0) {
      toast.error("Please select at least one class to share the course.");
      return;
    }

    try {
      const response = await axios.post(`${BASEURL}/api/share-course`, {
        id: courseId,
        sharedData: selectedData
      });

      if (response.data.success) {
        toast.success("Course shared successfully!");
        onClose();
      } else {
        toast.error("Failed to share the course. Please try again.");
      }
    } catch (error) {
      console.error("Error sharing course:", error);
      toast.error("An error occurred while sharing the course.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400">
      <div className="relative inline-block 2xl:w-4/12 2xl:h-4/5 lg:w-6/12 lg:h-4/5 lg:overflow-y-auto 2xl:overflow-y-auto px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all duration-300 transform bg-white rounded-lg shadow-xl dark:bg-gray-900 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Share Course</h3>
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}
          >
            <svg
              className="h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
  
        <div className="flex-grow overflow-y-auto">
          <div className="mb-2">
            <label className="text-sm text-gray-700 dark:text-gray-200">Title</label>
            <input type="text" value={courseName} disabled className="block mt-2 w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
          </div>
          <div className="mb-2 mt-4">
            <label className="text-sm text-gray-700 dark:text-gray-200">Course Description</label>
            <input type="text" value={courseDescription} disabled className="block mt-2 w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
          </div>
          <div className="mb-2 mt-4">
            <label className="text-sm text-gray-700 dark:text-gray-200">Subject</label>
            <input type="text" value={subjectName} disabled className="block mt-2 w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
          </div>
  
          <div className="mb-2 mt-4">
            <label className="text-sm text-gray-700 dark:text-gray-200">Add Class</label>
            <Select
              options={classOptions}
              onChange={handleClassChange}
              placeholder="Select Class"
              className="mt-2"
            />
          </div>
  
          {selectedData.map((classData) => (
            <div key={classData.class} className="mb-4 mt-4 flex justify-between">
              <h4 className="text-sm font-semibold w-1/5 my-auto mx-0">Class {classData.class}</h4>
              <Select
                isMulti
                options={studentsData[classData.class] || []}
                value={classData.student.map(s => ({ value: s.toString(), label: studentsData[classData.class]?.find(o => o.value === s.toString())?.label || s.toString() }))}
                onChange={(options) => handleStudentChange(options, classData.class)}
                placeholder="Select Students"
                className="mt-2 w-4/5"
              />
            </div>
          ))}
        </div>
  
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none"
            onClick={handleSubmit}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default ShareCourseModal;



// 2xl:w-4/12 2xl:h-4/5 lg:w-6/12 lg:h-4/5