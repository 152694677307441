const MobileTestComponent = ({ questionsArray, testName, topicName, question, currentQuestionId, setCurrentQuestionId,
    setQuestion, contentArray, contentIndex, setSelectedSubtopicId, setSelectedTestId,
    setContentIndex, setPrevious }) => {
    console.log('mobile questions array', questionsArray)

    function nextQuestion() {
        //[{id}{id}{id}]
        const currentSlideIndex = questionsArray.findIndex((slide) => slide.id === currentQuestionId);
        setCurrentQuestionId(questionsArray[currentSlideIndex + 1].id);
        setQuestion(questionsArray[currentSlideIndex + 1]);
    }

    function previousQuestion() {
        const currentSlideIndex = questionsArray.findIndex(slide => slide.id === currentQuestionId);
        setCurrentQuestionId(questionsArray[currentSlideIndex - 1].id);
        setQuestion(questionsArray[currentSlideIndex - 1]);

    }

    const disableStyle = {
        pointerEvents: "none",
        color: "grey"
    }

    function disableNext() {
        if (currentQuestionId === questionsArray[questionsArray.length - 1].id) return disableStyle;
        return {}
    }

    function disablePrevious() {
        if (currentQuestionId === questionsArray[0].id) return disableStyle;
        return {}
    }

    function disableJumpNext() {
        //contentArray 
        //contentIndex
        if ((contentArray.length - 1) === contentIndex) {
            //on the last contentObject disable it
            return disableStyle;
        } else {
            return {}
        }
    }

    function disableJumpBack() {
        if (contentIndex === 0) {
            return disableStyle;
        } else {
            return {}
        }
    }

    function nextContent() {
        console.log('contentArray', contentArray);
        const nextContent = contentArray[contentIndex + 1];
        console.log('next content', nextContent, contentArray, contentIndex);
        if (nextContent.type === 'slide-group') {
            setSelectedSubtopicId(nextContent.id);
            setSelectedTestId(null);
            setContentIndex(contentIndex + 1)
        }
        if (nextContent.type === 'test') {
            console.log('setting test id')
            setSelectedTestId(nextContent.id);
            setSelectedSubtopicId(null);
            setContentIndex(contentIndex + 1)
        }
    }

    function previousContent() {
        const previousContent = contentArray[contentIndex - 1];
        if (previousContent.type === 'slide-group') {
            setPrevious(true)
            setSelectedSubtopicId(previousContent.id);
            setSelectedTestId(null);
            setContentIndex(contentIndex - 1)
        }
        if (previousContent.type === 'test') {
            setPrevious(true)
            setSelectedTestId(previousContent.id);
            setSelectedSubtopicId(null);
            setContentIndex(contentIndex - 1)
        }
    }
    return (<>
        {
            questionsArray && (
                <div className="flex flex-col h-full">
                    <div>
                        {`${topicName}/${testName}`}
                    </div>
                    <div className="flex gap-2 p-1 bg-gray-500">
                        {
                            questionsArray && (
                                questionsArray.map(question => {
                                    return (
                                        <div className={`w-10 h-2 rounded-lg ${currentQuestionId === question.id ? 'bg-sky-500' : 'bg-gray-50'}`}>

                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                    <div className={`bg-white w-full grow flex flex-col border border-solid border-slate-300 relative p-1 rounded-2xl overflow-auto gap-2 h-20`}>

                        <div>
                            {question.text}
                        </div>
                        {
                            question.image_data.image ? (

                                <div style={{ width: question.image_data.width, height: question.image_data.height }}>
                                    <img src={question.image_data.image} style={{ height: "100%", width: "100%" }}></img>
                                </div>

                            ) : (null)
                        }
                        <div className="">
                            {
                                question.answer_type !== 'audio' ? (
                                    <>
                                        <section
                                            className="text-xs flex flex-col gap-2"
                                        >
                                            {
                                                question.answer_type === 'single' ? (
                                                    question.options.map((option, optionIndex) => {
                                                        return (
                                                            <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                                <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500" >{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                                <input className="w-4/5 outline-none text-xs" type="text" placeholder={`option ${optionIndex + 1}`} value={option} />
                                                                <input className="text-xl" type="radio" name="radio" />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    question.answer_type === 'multiple' ? (
                                                        question.options.map((option, optionIndex) => {
                                                            console.log('maping options ', option, question.correct_answer.includes(option));

                                                            return (
                                                                <div className="flex gap-1 border border-solid border-gray-300 items-center px-2 py-0 rounded-lg" key={optionIndex} >
                                                                    <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl text-gray-500">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                                    <input className="w-4/5 outline-none text-xs" type="text" placeholder={`option ${optionIndex + 1}`} value={option} />
                                                                    <input className="text-xl" type="checkbox" name="radio" />
                                                                </div>
                                                            )
                                                        }
                                                        )
                                                    ) : (
                                                        null
                                                        // return component for audio 
                                                    )
                                                )
                                            }
                                        </section>
                                    </>
                                ) : (null)
                            }
                        </div>
                    </div>
                    <div className='flex relative px-4 py-0 mb-4 items-center justify-center h-10'>
                        {
                            // isSpeaking ? (
                            //     <span onClick={() => {
                            //         handleSpeechStop();
                            //     }} className='absolute top-0 left-6 mt-2 cursor-pointer text-white'>
                            //         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            //             <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                            //         </svg>
                            //     </span>
                            // ) : (
                            //     <span onClick={() => {
                            //         //what ever content there is needs to be spoken 
                            //         handleTextToSpeech()
                            //     }} className='absolute top-0 left-6 mt-2 cursor-pointer text-white'>
                            //         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            //             <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                            //         </svg>

                            //     </span>
                            // )
                        }
                        <div className='flex items-center text-white '>
                            {
                                questionsArray[0].id === currentQuestionId ? (
                                    <svg style={disableJumpBack()} onClick={previousContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                    </svg>
                                ) : (
                                    <svg style={disablePrevious()} onClick={previousQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                )
                            }
                            <button key={currentQuestionId} onClick={(e) => {
                                const button = e.currentTarget;
                                button.style.backgroundColor = 'green';
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="size-10">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                            {
                                questionsArray[questionsArray.length - 1].id === currentQuestionId ? (
                                    <svg style={disableJumpNext()} onClick={nextContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                    </svg>
                                ) : (
                                    <svg style={disableNext()} onClick={nextQuestion} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                )
                            }
                        </div>
                    </div>

                </div>
            )
        }
    </>);
}

export default MobileTestComponent;