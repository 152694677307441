import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ time, onFinish , isTestSubmitted }) => {
  const [secondsLeft, setSecondsLeft] = useState(time * 60);

  useEffect(() => {
    if (!isTestSubmitted && secondsLeft > 0) {
      const timer = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (secondsLeft === 0) {
      if (onFinish) {
        onFinish();
      }
    }
  }, [secondsLeft, onFinish , isTestSubmitted]);

  const formatTime = () => {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className=' px-2 text-center text-xl' style={{color:'#fa9248'}}>
      <p>{formatTime()} remaining</p>
    </div>
  );
};

export default CountdownTimer;
