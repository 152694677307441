import { useState, useEffect } from "react";
import MobileSidebar from "./MobileSidebar";
import { BASEURL } from '../../../../constants';
import axios from 'axios';
import { useParams } from "react-router-dom";
import MobilePreviewCourseCreator from "./MobilePreviewCourseCreator";
import MobileTestComponent from "./MobileTestComponent";
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'

const MobilePreview = () => {

    const [contentArray, setContentArray] = useState(null);
    const [contentIndex, setContentIndex] = useState(null);

    const [currentSlideData, setCurrentSlideData] = useState(null);
    const [currentSlideId, setCurrentSlideId] = useState(null);
    const [slidesArray, setSlidesArray] = useState(null);

    const [questionsArray, setQuestionsArray] = useState(null);
    const [question, setQuestion] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [numberOfQuestions, setNumberOfQuestions] = useState(null);
    const [testTimeHours, setTestTimeHours] = useState(null);
    const [testTimeMinutes, setTestTimeMinutes] = useState(null);

    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [sidebarData, setSidebarData] = useState(null);
    const [topicName, setTopicName] = useState(null);
    const [subtopicName, setSubtopicName] = useState(null);
    const [testName, setTestName] = useState(null)

    const [selectedSubtopicId, setSelectedSubtopicId] = useState(null);
    const [selectedTestId, setSelectedTestId] = useState(null);
    const [courseInfo, setCourseInfo] = useState(null);

    // states for preview of content





    const [previous, setPrevious] = useState(false);
    const [imageIdArray, setImageIdArray] = useState(null)
    const { courseId } = useParams();

    useEffect(() => {
        axios.get(`${BASEURL}/api/get-sidebar-hierarchy`, {
            params: {
                courseId: courseId
            }
        }).then((response) => {
            console.log(response.data);

            const sidebarHierarchy = response.data;
            setSidebarData(response.data);
            if (sidebarHierarchy.topics[0].content[0].type === 'slide-group') {
                setTopicName(sidebarHierarchy.topics[0].name);
                setSelectedSubtopicId(sidebarHierarchy.topics[0].content[0].id);
                setSubtopicName(sidebarHierarchy.topics[0].content[0].name)
            } else {
                setTopicName(sidebarHierarchy.topics[0].name);
                setSelectedTestId(sidebarHierarchy.topics[0].content[0].id);
                setTestName(sidebarHierarchy.topics[0].content[0].name);
            }

            /*
                 * creating a contentArray  
                    for jumping slide-groups and tests 
                 */
            let contentArray = [];
            sidebarHierarchy.topics.forEach((topic) => {
                /* 
                  topic ->  { id , name , content : [] }
                */
                if (topic.content.length) {
                    topic.content.forEach((contentObject) => {
                        /* 
                            contentObject -> { id , type , sequence , name }
                        */
                        contentArray.push(contentObject);
                    })
                }
            })

            if (contentArray.length) {
                console.log('contentArray ', contentArray)
                setContentArray(contentArray);
                setContentIndex(0);
            } else {
                setContentArray([]);
            }
        })
    }, [])

    useEffect(() => {
        if (sidebarData) {
            console.log('running useEffect')
            if (selectedSubtopicId) {
                console.log('selectedSubtopicId', selectedSubtopicId);
                axios.get(`${BASEURL}/api/get-sub-topic-data`, {
                    params: {
                        subTopicId: selectedSubtopicId
                    }
                }).then((response) => {
                    console.log('response  ', response);
                    //----------------------------------------------------
                    const slidesArray = response.data;
                    const arrayOfImageIdArray = slidesArray.map((slideObject, index) => {
                        if (slideObject.imageIdArray) {
                            return slideObject.imageIdArray;
                        } else {
                            return []
                        }
                    })

                    console.log('arrayOfImageIdArray', arrayOfImageIdArray)
                    //array of imageIdArray will have an empty array for slides which dont have any images so it will always have length

                    const imageKeysArray = arrayOfImageIdArray.reduce((acc, item) => acc.concat(item));
                    console.log('images to be fetched array', imageKeysArray);
                    //if imageKeysArray is empty there are no images to be fetched just set the data
                    if (imageKeysArray.length) {
                        const imagesPromiseArray = imageKeysArray.map((key, index) => {
                            return axios.get(`${BASEURL}/api/get-image`, {
                                params: {
                                    imageId: key,
                                    index: index
                                }
                            })
                        })
                        //let single = arr.reduce((elem1, elem2) => elem1.concat(elem2));
                        //the response we will get will have { id , index , dataURL }
                        Promise.all(imagesPromiseArray)
                            .then((imageResponse) => {
                                //response will be array of array ? [ { data : { dataUrl : '' } } {} ]
                                console.log('imageResponse', imageResponse);
                                imageResponse.forEach((resObject, index) => {
                                    const imageUrl = resObject.data.dataUrl;
                                    const contentObjectId = imageKeysArray[index];

                                    //how to find slideIndex 
                                    //the slide where the key is present in the imageIdArray 
                                    const slideIndex = slidesArray.findIndex(slide => slide.imageIdArray.includes(contentObjectId));
                                    //find the contentObjectIndex 
                                    const targetSlide = slidesArray[slideIndex];
                                    const contentObjectIndex = targetSlide.content.findIndex(contentObject => contentObject.id === contentObjectId);

                                    if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Image') {
                                        slidesArray[slideIndex].content[contentObjectIndex].data.imgData = imageUrl;

                                    }
                                    if (slidesArray[slideIndex].content[contentObjectIndex].type === 'Quiz') {
                                        slidesArray[slideIndex].content[contentObjectIndex].data.imageData.image = imageUrl;
                                    }
                                })
                                setSlidesArray(slidesArray);
                                if (previous) {
                                    setPrevious(false);
                                    setCurrentSlideId(slidesArray[slidesArray.length - 1].id);
                                    setCurrentSlideData(slidesArray[slidesArray.length - 1]);
                                } else {
                                    setCurrentSlideId(slidesArray[0].id);
                                    setCurrentSlideData(slidesArray[0]);
                                }

                            })
                    } else {
                        setSlidesArray(slidesArray);
                        if (previous) {
                            setPrevious(false);
                            setCurrentSlideId(slidesArray[slidesArray.length - 1].id);
                            setCurrentSlideData(slidesArray[slidesArray.length - 1]);
                        } else {
                            setCurrentSlideId(slidesArray[0].id);
                            setCurrentSlideData(slidesArray[0]);
                        }
                    }
                })
            } else {
                //this will execute on mount as well as on subtopic delete
                setSlidesArray(null);
                setCurrentSlideData(null);
                setCurrentSlideId(null);
            }

            if (selectedTestId) {
                console.log('getting test data')
                axios.get(`${BASEURL}/api/get-test-data`, {
                    params: {
                        testId: selectedTestId
                    }
                }).then((response) => {

                    axios.get(`${BASEURL}/api/get-test-duration`, {
                        params: {
                            testId: selectedTestId
                        }
                    }).then((getDurationResponse) => {
                        //response should be questionsArray 
                        //well we are not setting anything here 
                        const questionsArray = response.data;
                        const { testTimeHours, testTimeMinutes, numberOfQuestions } = getDurationResponse.data[0];

                        //[{ } , { } ]
                        const imagekeyArray = questionsArray.map((question) => {
                            if (question.image_data.image) {
                                return question.image_key
                            }
                        }).filter((key) => key)

                        if (imagekeyArray.length) {
                            const imagePromisesArray = imagekeyArray.map((key) => {
                                if (key) {
                                    return axios.get(`${BASEURL}/api/get-image`, {
                                        params: {
                                            imageId: key
                                        }
                                    })
                                }
                            })

                            Promise.all(imagePromisesArray)
                                .then((imagesResponse) => {
                                    //[{ dataUrl: urlObject, status: "success" }]

                                    imagesResponse.forEach((responseObj, index) => {
                                        const key = imagekeyArray[index];
                                        const questionIndex = questionsArray.findIndex(question => question.image_key === key);
                                        questionsArray[questionIndex] = { ...questionsArray[questionIndex], image_data: { ...questionsArray[questionIndex].image_data, image: responseObj.data.dataUrl } }
                                    })
                                    setQuestionsArray(questionsArray);
                                    if (previous) {
                                        setPrevious(false);
                                        setQuestion(questionsArray[questionsArray.length - 1]);
                                        setCurrentQuestionId(questionsArray[questionsArray.length - 1].id);
                                    } else {
                                        setQuestion(questionsArray[0]);
                                        setCurrentQuestionId(questionsArray[0].id);
                                    }
                                    setNumberOfQuestions(numberOfQuestions ? numberOfQuestions : 0);
                                    setTestTimeHours(testTimeHours ? testTimeHours : 0);
                                    setTestTimeMinutes(testTimeMinutes ? testTimeMinutes : 0);
                                })
                        } else {
                            console.log('setting test data' ,questionsArray )
                            setQuestionsArray(questionsArray);
                            if (previous) {
                                setPrevious(false);
                                setQuestion(questionsArray[questionsArray.length - 1]);
                                setCurrentQuestionId(questionsArray[questionsArray.length - 1].id);
                            } else {
                                setQuestion(questionsArray[0]);
                                setCurrentQuestionId(questionsArray[0].id);
                            }
                            setNumberOfQuestions(numberOfQuestions ? numberOfQuestions : 0);
                            setTestTimeHours(testTimeHours ? testTimeHours : 0);
                            setTestTimeMinutes(testTimeMinutes ? testTimeMinutes : 0);
                        }
                    })
                })
            } else {
                setQuestionsArray(null);
                setQuestion(null);
                setCurrentQuestionId(null);
            }
        }
    }, [selectedSubtopicId, selectedTestId])


    useEffect(() => {
        axios.get(`${BASEURL}/api/fetch-course-info`, {
            params: {
                courseId: courseId
            }
        }).then((res) => {
            console.log(res.data.rows, res.data);
            setCourseInfo(res.data.rows)
        })
    }, [])

    return (<>
        <DeviceFrameset device="iPhone X" color="gold" zoom={'0.55'}>
            <div className="flex flex-col w-full relative h-full mt-4 ">
                <navbar className='flex justify-between p-1 items-center bg-gray-500'>
                    <div className="flex items-center">
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                            </svg>
                        </button>
                        <span className="text-xs text-white">
                            {
                                courseInfo?.course_name
                            }
                        </span>
                    </div>
                    <span onClick={() => {
                        setSidebarToggle((sidebarToggle) => {
                            return !sidebarToggle
                        })
                    }} className="cursor-pointer text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </span>
                </navbar>

                {
                    sidebarToggle && (
                        <div className="absolute top-0 z-20 bg-white w-full h-full">
                            <MobileSidebar
                                sidebarData={sidebarData}
                                setSidebarToggle={setSidebarToggle}
                                setSelectedSubtopicId={setSelectedSubtopicId}
                                setSelectedTestId={setSelectedTestId}
                                setTestName={setTestName}
                                contentArray={contentArray}
                                setContentIndex={setContentIndex}
                            />

                        </div>
                        // selectedSubtopicId, selectedTestId
                    )
                }
                {
                    selectedTestId ? (
                        <MobileTestComponent
                            questionsArray={questionsArray}
                            question={question}
                            currentQuestionId={currentQuestionId}
                            testName={testName}
                            topicName={topicName}
                            setCurrentQuestionId={setCurrentQuestionId}
                            setQuestion={setQuestion}
                            contentArray={contentArray}
                            contentIndex={contentIndex}
                            setSelectedSubtopicId={setSelectedSubtopicId}
                            setSelectedTestId={setSelectedTestId}
                            setContentIndex={setContentIndex}
                            setPrevious={setPrevious}
                        />
                        // questionsArray , testName , topicName , question , currentQuestionId , setCurrentQuestionId , setQuestion , contentArray , contentIndex , setSelectedSubtopicId , setSelectedTestId , setContentIndex , setPrevious
                    ) : (
                        <div className="w-full grow">
                            <MobilePreviewCourseCreator
                                slidesArray={slidesArray}
                                currentSlideData={currentSlideData}
                                currentSlideId={currentSlideId}
                                setCurrentSlideData={setCurrentSlideData}
                                setSlidesArray={setSlidesArray}
                                imageIdArray={imageIdArray}
                                setCurrentSlideId={setCurrentSlideId}
                                setImageIdArray={setImageIdArray}
                                topicName={topicName}
                                subtopicName={subtopicName}
                                setContentIndex={setContentIndex}
                                contentArray={contentArray}
                                contentIndex={contentIndex}
                                setPrevious={setPrevious}
                                setSelectedSubtopicId={setSelectedSubtopicId}
                                setSelectedTestId={setSelectedTestId}
                            />
                        </div>
                    )
                }
            </div>
        </DeviceFrameset>

    </>);
}

export default MobilePreview;