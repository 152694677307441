// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import { BASEURL } from "../../constants";
// import 'react-tabulator/lib/styles.css';
// import 'react-tabulator/lib/css/tabulator.min.css';
// import '../Global/Styles/tabulatorStyles.css';
// import { ReactTabulator } from 'react-tabulator';

// import toast from "react-hot-toast";

// // Debounce Hook
// // This custom hook is used to debounce the search term input, preventing excessive re-renders or API calls when the user is typing.
// function useDebounce(value, delay) {
//     const [debouncedValue, setDebouncedValue] = useState(value);
//     useEffect(() => {
//         const handler = setTimeout(() => {
//             setDebouncedValue(value);
//         }, delay);
//         return () => {
//             clearTimeout(handler);
//         };
//     }, [value, delay]);
//     return debouncedValue;
// }

// const DiactoAllSchools = () => {
//     const { schoolId } = useParams();
//     const navigate = useNavigate();
//     const [schoolData, setSchoolData] = useState([]);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [filteredData, setFilteredData] = useState([]);
//     const debouncedSearchTerm = useDebounce(searchTerm, 500);
//     const [openPopup, setOpenPopup] = useState(null);
//     const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
//     const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
//     const [schoolToDelete, setSchoolToDelete] = useState(null);
//     const [rowData, setRowData] = useState(null);

//     useEffect(() => {
//         axios
//             .get(`${BASEURL}/api/fetch-school-data`)
//             .then((res) => {
//                 const data = res.data.schoolData || [];
//                 console.log("Fetched school data:", data);
//                 setSchoolData(data);
//                 setFilteredData(data);
//             })
//             .catch((error) => {
//                 console.error("Error fetching school data:", error);
//             });
//     }, []);

//     // Effect for filtering schools based on the debounced search term
//     useEffect(() => {
//         const filtered = schoolData.filter((school) =>
//             school.school_name
//                 .toLowerCase()
//                 .includes(debouncedSearchTerm.toLowerCase())
//         );
//         setFilteredData(filtered);
//     }, [debouncedSearchTerm, schoolData]);

//     // Define columns for the Tabulator table
//     const columns = [
//         { title: "Sno.", field: "number", width: 70, hozAlign: "center", cssClass: "center-text", headerSort: true, resizable: true },
//         { title: "School Name", field: "schoolName", headerSort: true, resizable: true },
//         { title: "Pricipal Name", field: "principalName", headerSort: true, resizable: true },
//         { title: "Contact", field: "contact", headerSort: true, resizable: true },
//         { title: "Location", field: "location", headerSort: true, resizable: true },
//         // { title: "Funds Deployed", field: "fundsDeployed", headerSort: true, resizable: true },
//         { title: "Creators", field: "creators", headerSort: true, resizable: true },
//         { title: "Students", field: "students", headerSort: true, resizable: true },

//         {
//             title: 'Actions',
//             formatter: () => {
//                 return '<button className="action-btn">•••</button>';
//             },
//             width: 100,
//             cellClick: (e, cell) => {
//                 const rect = e.target.getBoundingClientRect();
//                 setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX - 280 }); // Adjusted position to the left
//                 setRowData(cell.getRow().getData());
//                 setOpenPopup(cell.getRow().getData().id); // Set the row ID to identify the open popup
//             },
//             headerSort: false
//         }
//     ];

//     // Prepare table data for the ReactTabulator component
//     const data = (filteredData || []).map((school, index) => ({
//         id: school.id,
//         number: index + 1,
//         schoolName: school.school_name,
//         principalName: school.principal_name,
//         contact: school.school_contact_number,
//         location: `${school.school_city}, ${school.school_state}`,
//         creators: school.total_creators,
//         students: school.total_students,
//     }));

//     // Navigate to the teacher's profile page
//     const onViewDetails = (rowData) => {
//         const { id } = rowData;
//         navigate(`/diactoAdmin/allSchools/${id}`);
//         console.log('schoolId', id);
//         setOpenPopup(null);
//     };

//     // Navigate to the edit teacher page
//     const onEditSchool = (rowData) => {
//         const { id } = rowData;
//         console.log('schoolId', id);
//         navigate(`/diactoAdmin/editSchool/${id}`);
//         setOpenPopup(null);
//     };

//     const onDeleteWithConfirmation = (rowData, totalCreators, totalStudents) => {
//         const { id } = rowData;
//         console.log("Delete requested for school ID:", schoolId);
//         if (parseInt(totalCreators, 10) > 0 || parseInt(totalStudents, 10) > 0) {
//             toast.error("Cannot delete the school. Delete all teachers and students first.");
//         } else {
//             setSchoolToDelete(schoolId);
//             setShowDeleteConfirmation(true);
//         }
//     };



//     const confirmDelete = async () => {
//         console.log("Starting delete process for school ID:", schoolToDelete);
//         console.log("Current schoolData:", schoolData);

//         try {
//             const schoolToDeleteDetails = schoolData.find(
//                 (school) => school.school_id === schoolToDelete
//             );

//             console.log("School to delete details:", schoolToDeleteDetails);

//             if (!schoolToDeleteDetails) {
//                 console.error("School not found in schoolData. School ID:", schoolToDelete);
//                 toast.error("Error: School not found in current data. Please refresh and try again.");
//                 return;
//             }

//             const totalCreators = parseInt(schoolToDeleteDetails.total_teachers, 10);
//             const totalStudents = parseInt(schoolToDeleteDetails.total_students, 10);

//             console.log("Total teachers:", totalCreators, "Total students:", totalStudents);

//             if (isNaN(totalCreators) || isNaN(totalStudents) || totalCreators > 0 || totalStudents > 0) {
//                 toast.error("Cannot delete the school. Ensure all teachers and students are removed first.");
//                 return;
//             }

//             // Proceed with deletion
//             const response = await axios.delete(`${BASEURL}/api/delete-school`, {
//                 data: { schoolId: schoolToDelete },
//             });

//             console.log("Delete API response:", response.data.message);

//             if (response.data.message) {
//                 // Fetch updated school data
//                 const fetchResponse = await axios.get(`${BASEURL}/api/fetch-school-data`);
//                 setSchoolData(fetchResponse.data.schoolData || []);
//                 toast.success("School deleted successfully!");
//             } else {
//                 toast.error(response.data.message || "Error deleting school");
//             }
//         } catch (error) {
//             console.error("Error in delete process:", error);
//             toast.error(error.response?.data?.message || "Error deleting school");
//         } finally {
//             setShowDeleteConfirmation(false);
//             setSchoolToDelete(null);
//         }
//     };


//     // Cancel deletion
//     const cancelDelete = () => {
//         setSchoolToDelete(null);
//         setShowDeleteConfirmation(false);
//     };

//     const options = {
//         pagination: 'remote', // or 'local'
//         paginationSize: 10, // number of rows per page
//     };

//     return (
//         <>
//             <div className='mx-4 border-b border-gray-200'>
//                 <div className='pt-2 pb-3 flex justify-between'>
//                     <div className="flex items-center overflow-x-auto whitespace-nowrap">
//                         <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
//                             Schools
//                         </p>
//                     </div>
//                     <div className='flex flex-row justify-between flex-x-2'>
//                         <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
//                             <div className="grid place-items-center w-12 text-gray-300">
//                                 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
//                                 </svg>
//                             </div>
//                             <input
//                                 className="peer w-full outline-none text-sm text-gray-700 pr-2"
//                                 type="text"
//                                 id="search"
//                                 placeholder="Search School Name"
//                                 onChange={(e) => setSearchTerm(e.target.value)} />
//                         </div>
//                         <Link to={`/diactoAdmin/createSchool`}>
//                             <button type="button" className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " >
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
//                                 </svg>
//                                 <span className='text-sm'>Add School</span>
//                             </button>
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//             {/* Render the ReactTabulator component with the table data and columns */}
//             <ReactTabulator
//                 data={data}
//                 columns={columns}
//                 layout="fitColumns"
//                 responsiveLayout="hide"
//                 tooltips={true}
//                 options={options}
//                 className="custom-tabulator-styles"
//             />

//             {/* Render the popup menu when a row is clicked */}
//             {openPopup && (
//                 <div
//                     className="absolute bg-white shadow-lg rounded-lg py-2 w-48"
//                     style={{ top: popupPosition.top, left: popupPosition.left }}
//                 >
//                     <div className="cursor-pointer flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onViewDetails(rowData)}>
//                         <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill="currentColor"></path>
//                             <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" fill="currentColor"></path>
//                         </svg>

//                         <span className="mx-1">
//                             view school
//                         </span>
//                     </div>

//                     <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onEditSchool(rowData)}>
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-1">
//                             <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
//                         </svg>


//                         <span className="mx-1">
//                             Edit school
//                         </span>
//                     </div>

//                     {/* <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onDeleteWithConfirmation(rowData.id, rowData.teachers, rowData.students)}>

//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
//                             <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
//                         </svg>


//                         <span className="mx-1">
//                             Delete school
//                         </span>
//                     </div> */}
//                 </div>
//             )}

//             {/* Render the delete confirmation dialog */}
//             {showDeleteConfirmation && (
//                 <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400">
//                     <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl rtl:text-right dark:bg-gray-900 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
//                         <div>
//                             <div className="flex items-center justify-center">
//                                 {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
//                                 </svg> */}
//                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
//                                     <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
//                                 </svg>

//                             </div>
//                             <div className="mt-2 text-center">
//                                 <h3 className="text-lg font-medium leading-6 text-gray-800 capitalize dark:text-white" id="modal-title">Confirm Delete</h3>
//                                 <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
//                                     Are you sure you want to delete this school?
//                                 </p>
//                             </div>
//                         </div>
//                         <div className="mt-5 sm:flex sm:items-center sm:justify-end">
//                             <div className="sm:flex sm:items-center">
//                                 <button
//                                     className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:mt-0 sm:w-auto sm:mx-2 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
//                                     onClick={cancelDelete}
//                                 >
//                                     Cancel
//                                 </button>
//                                 <button
//                                     className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-40"
//                                     onClick={confirmDelete}
//                                 >
//                                     Delete
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}


//         </>
//     );
// };

// export default DiactoAllSchools;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constants";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import '../Global/Styles/tabulatorStyles.css';
import { ReactTabulator } from 'react-tabulator';

import toast from "react-hot-toast";

// Debounce Hook
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

const DiactoAllSchools = () => {
    const { schoolId } = useParams();
    const navigate = useNavigate();
    const [schoolData, setSchoolData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [openPopup, setOpenPopup] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [schoolToDelete, setSchoolToDelete] = useState(null);
    const [rowData, setRowData] = useState(null);

    useEffect(() => {
        axios
            .get(`${BASEURL}/api/fetch-school-data`)
            .then((res) => {
                const data = res.data.schoolData || [];
                console.log("Fetched school data:", data);
                setSchoolData(data);
                setFilteredData(data);
            })
            .catch((error) => {
                console.error("Error fetching school data:", error);
            });
    }, []);

    useEffect(() => {
        const filtered = schoolData.filter((school) =>
            school.school_name
                .toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    }, [debouncedSearchTerm, schoolData]);
    

    const columns = [
        { title: "Sno.", field: "number", width: 70, hozAlign: "center", cssClass: "center-text", headerSort: true, resizable: true },
        { title: "School Name", field: "schoolName", headerSort: true, resizable: true },
        { title: "Principal Name", field: "principalName", headerSort: true, resizable: true },
        { title: "Contact", field: "contact", headerSort: true, resizable: true },
        { title: "Location", field: "location", headerSort: true, resizable: true },
        { title: "Creators", field: "creators", headerSort: true, resizable: true },
        { title: "Students", field: "students", headerSort: true, resizable: true },
        // {
        //     title: "Active Status",
        //     field: "isActive",
        //     headerSort: true,
        //     resizable: true,
        //     formatter: (cell) => {
        //         const isActive = cell.getValue();
        //         return `
        //       <label class="relative inline-flex items-center cursor-pointer">
        //         <input type="checkbox" class="sr-only peer" ${isActive ? 'checked' : ''}>
        //         <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        //       </label>
        //     `;
        //     },
        //     cellClick: (e, cell) => {
        //         const id = cell.getRow().getData().id;
        //         const currentStatus = cell.getValue();
        //       console.log(id);
        //       console.log(currentStatus);
        //     }
        // },
        {
            title: 'Actions',
            formatter: () => {
                return '<button class="action-btn">•••</button>';
            },
            width: 100,
            cellClick: (e, cell) => {
                const rect = e.target.getBoundingClientRect();
                setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX - 280 });
                setRowData(cell.getRow().getData());
                setOpenPopup(cell.getRow().getData().id);
            },
            headerSort: false
        }
    ];

    const data = (filteredData || []).map((school, index) => ({
        id: school.id,
        number: index + 1,
        schoolName: school.school_name,
        principalName: school.principal_name,
        contact: school.school_contact_number,
        location: `${school.school_city}, ${school.school_state}`,
        creators: school.total_creators,
        students: school.total_students,
        // isActive: school.is_active !== undefined ? school.is_active : true, // Adjust this line
      }));

      

    const onViewDetails = (rowData) => {
        const { id } = rowData;
        navigate(`/diactoAdmin/allSchools/${id}`);
        console.log('schoolId', id);
        setOpenPopup(null);
    };

    const onEditSchool = (rowData) => {
        const { id } = rowData;
        console.log('schoolId', id);
        navigate(`/diactoAdmin/editSchool/${id}`);
        setOpenPopup(null);
    };

    const onDeleteWithConfirmation = (rowData, totalCreators, totalStudents) => {
        const { id } = rowData;
        console.log("Delete requested for school ID:", schoolId);
        if (parseInt(totalCreators, 10) > 0 || parseInt(totalStudents, 10) > 0) {
            toast.error("Cannot delete the school. Delete all teachers and students first.");
        } else {
            setSchoolToDelete(schoolId);
            setShowDeleteConfirmation(true);
        }
    };

    const confirmDelete = async () => {
        console.log("Starting delete process for school ID:", schoolToDelete);
        console.log("Current schoolData:", schoolData);

        try {
            const schoolToDeleteDetails = schoolData.find(
                (school) => school.school_id === schoolToDelete
            );

            console.log("School to delete details:", schoolToDeleteDetails);

            if (!schoolToDeleteDetails) {
                console.error("School not found in schoolData. School ID:", schoolToDelete);
                toast.error("Error: School not found in current data. Please refresh and try again.");
                return;
            }

            const totalCreators = parseInt(schoolToDeleteDetails.total_teachers, 10);
            const totalStudents = parseInt(schoolToDeleteDetails.total_students, 10);

            console.log("Total teachers:", totalCreators, "Total students:", totalStudents);

            if (isNaN(totalCreators) || isNaN(totalStudents) || totalCreators > 0 || totalStudents > 0) {
                toast.error("Cannot delete the school. Ensure all teachers and students are removed first.");
                return;
            }

            // Proceed with deletion
            const response = await axios.delete(`${BASEURL}/api/delete-school`, {
                data: { schoolId: schoolToDelete },
            });

            console.log("Delete API response:", response.data.message);

            if (response.data.message) {
                // Fetch updated school data
                const fetchResponse = await axios.get(`${BASEURL}/api/fetch-school-data`);
                setSchoolData(fetchResponse.data.schoolData || []);
                toast.success("School deleted successfully!");
            } else {
                toast.error(response.data.message || "Error deleting school");
            }
        } catch (error) {
            console.error("Error in delete process:", error);
            toast.error(error.response?.data?.message || "Error deleting school");
        } finally {
            setShowDeleteConfirmation(false);
            setSchoolToDelete(null);
        }
    };

    const cancelDelete = () => {
        setSchoolToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const options = {
        pagination: 'remote',
        paginationSize: 10,
        rowFormatter: function (row) {
            const data = row.getData();
            if (!data.isActive) {
                row.getElement().style.backgroundColor = "#f3f4f6";
                row.getElement().style.color = "#9ca3af";
            }
        }
    };

    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Schools
                        </p>
                    </div>
                    <div className='flex flex-row justify-between flex-x-2'>
                        <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
                            <div className="grid place-items-center w-12 text-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input
                                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                                type="text"
                                id="search"
                                placeholder="Search School Name"
                                onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                        <Link to={`/diactoAdmin/createSchool`}>
                            <button type="button" className="inline-flex truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 " >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                <span className='text-sm'>Add School</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <ReactTabulator
                data={data}
                columns={columns}
                layout="fitColumns"
                responsiveLayout="hide"
                tooltips={true}
                options={options}
                className="custom-tabulator-styles"
            />

            {openPopup && (
                <div
                    className="absolute bg-white shadow-lg rounded-lg py-2 w-48"
                    style={{ top: popupPosition.top, left: popupPosition.left }}
                >
                    <div className="cursor-pointer flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onViewDetails(rowData)}>
                        <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill="currentColor"></path>
                            <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" fill="currentColor"></path>
                        </svg>

                        <span className="mx-1">
                            view school
                        </span>
                    </div>

                    <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onEditSchool(rowData)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>

                        <span className="mx-1">
                            Edit school
                        </span>
                    </div>

                    {/* <div className="cursor-pointer flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onDeleteWithConfirmation(rowData.id, rowData.teachers, rowData.students)}>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>

                        <span className="mx-1">
                            Delete school
                        </span>
                    </div> */}
                </div>
            )}

            {showDeleteConfirmation && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400">
                    <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl rtl:text-right dark:bg-gray-900 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700 dark:text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                </svg>
                            </div>
                            <div className="mt-2 text-center">
                                <h3 className="text-lg font-medium leading-6 text-gray-800 capitalize dark:text-white" id="modal-title">Confirm Delete</h3>
                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this school?
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 sm:flex sm:items-center sm:justify-end">
                            <div className="sm:flex sm:items-center">
                                <button
                                    className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:mt-0 sm:w-auto sm:mx-2 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
                                    onClick={cancelDelete}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-full px-4 py-2 mt-2 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-red-600 rounded-md sm:w-auto sm:mt-0 hover:bg-red-500 focus:outline-none focus:ring focus:ring-red-300 focus:ring-opacity-40"
                                    onClick={confirmDelete}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DiactoAllSchools;