import { useDraggable } from "@dnd-kit/core";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { NewItem } from "../SortableItem/NewItem";

export default function Draggable(props) {
    console.log('inside draggable , ', props.data, props)
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.data.id,
        disabled: props.disabled
    });

    const style = props.disabled ? { backgroundColor: "#E0E0E0" } : null;

    return (
        <NewItem ref={setNodeRef} {...listeners} {...attributes}>
            <div className="flex flex-col items-center">
                <div className="flex justify-center border border-solid rounded text-center px-3 py-2 2xl:px-4 2xl:py-3 2xl:text-base text-xs  relative cursor-pointer " data-tooltip-id="my-tooltip" style={{...style , backgroundColor:'rgba(246, 245, 255, 0.7)'}} data-tooltip-content={props.disabled ? "disabled" : `Drag to add ${props.data.id}`} data-tooltip-place="left-start"  >
                    <i className={`${props.data.icon}`}></i>
                </div>
                <p className="text-xs text-center mb-0 2xl:text-base">{props.data.id}</p>
            </div>
            <Tooltip id="my-tooltip" />
        </NewItem>
    )
} 