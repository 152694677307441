import React, { useState } from 'react';
import { jwtDecode } from "jwt-decode"; // Correct the import as jwtDecode is a function, not a named export
import { Link, useNavigate } from 'react-router-dom';


const Navbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const token = localStorage.getItem('auth');
    const decoded = jwtDecode(token);
    const schoolName = decoded.school_name;
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    function handleLogout(){
        console.log('handling logout')
        localStorage.removeItem('auth');
        navigate('/login')
    }
    return (
        <nav className="flex items-center justify-between h-12 px-4 py-2 bg-white border-b">
            <div className="flex items-center">
                <div className="flex flex-col items-center">
                    <h2 className="text-xl font-bold text-center text-gray-700">BlissIQ</h2>
                </div>
                <div className="h-6 border-l-2 border-gray-300 mx-4"></div>
                <div className="flex flex-col items-center">
                    <span className="text-lg font-medium text-gray-700">{schoolName}</span>
                </div>
            </div>

            <div className="relative">
                <img
                    className="object-cover w-8 h-8 rounded-full cursor-pointer"
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
                    alt="User avatar"
                    onClick={toggleDropdown}
                />

                {isDropdownOpen && (
                    <div className="absolute right-0 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl">
                        <Link to='/profile' className="flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                            </svg>

                            <span className="mx-1">View Profile</span>
                        </Link>
                        <button  onClick={handleLogout} className="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform hover:bg-gray-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                            </svg>
                            <span className="mx-1">Log Out</span>
                        </button>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
