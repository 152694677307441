import React from 'react'
import { Link } from 'react-router-dom'
import { PiHandshake } from "react-icons/pi";
import { LuClock } from "react-icons/lu";

const DiactoAllSponsors = () => {
  return (
    <div className="flex flex-col items-center justify-center p-6 space-y-6 text-center bg-white dark:bg-gray-800 my-auto">
      <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
        <PiHandshake className='w-6 h-6'/>
      </span>

      <h1 className="text-3xl font-semibold text-gray-700 capitalize dark:text-white">Sponsors</h1>

      <p className="max-w-2xl text-gray-500 dark:text-gray-300">
        Discover and connect with our valued sponsors who support education and innovation. Our platform brings together a diverse range of sponsors, creating opportunities for collaboration and growth in the educational ecosystem.
      </p>

      <div className="flex items-center text-lg text-blue-500 capitalize transition-colors duration-300 transform dark:text-blue-400">
        <span className="mx-1">Coming Soon</span>
        <svg className="w-5 h-5 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
      </div>
    </div>
  )
}

export default DiactoAllSponsors