import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useEmail } from "./EmailContext"; // Adjust the path accordingly
import { BASEURL } from "../../../constants.js";
import Image from '../../assets/image.png'

const ResetPassword = () => {

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { email } = useEmail();
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initially disable the button
    const navigate = useNavigate();

    useEffect(() => {
        // Disable the button initially when the component mounts or when the route is '/resetPassword'
        setIsButtonDisabled(true);
    }, []);

    useEffect(() => {
        // Enable the button only if the passwords match and are not empty
        setIsButtonDisabled(!(passwordsMatch && newPassword && confirmPassword));
    }, [passwordsMatch, newPassword, confirmPassword]);

    const handleResetPassword = async () => {
        try {
            // Check if passwords match
            if (passwordsMatch) {
                // Send a request to update the password
                const response = await axios.post(`${BASEURL}/api/reset-password`, {
                    email,
                    newPassword,
                });

                console.log(response.data.message); // Log success or error message

                // If the password reset was successful, navigate to the login page
                if (response.data.message === "Password reset successful") {
                    navigate("/login"); // Assuming 'navigate' is the result of 'useNavigate'
                }
                // Optionally, you can redirect the user to a login page or display a success message
            }
        } catch (error) {
            console.error("Error:", error);
            // Handle error, e.g., show an error message to the user
        }
    };

    const handleTogglePasswordVisibility = (field) => {
        // Update the state to toggle visibility
        if (field === "showPassword") {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    // Function to handle changes in the password inputs
    const handlePasswordChange = (field, value) => {
        // Update the state with the new password information
        if (field === "newPassword") {
            setNewPassword(value);
        } else {
            setConfirmPassword(value);
        }

        // Check if both passwords have been entered and match
        setPasswordsMatch(
            field === "newPassword"
                ? value === confirmPassword
                : newPassword === value
        );
    };


    return (
        <div>

            <div className="bg-white dark:bg-gray-900">
                <div className="flex justify-center h-screen">
                    <div class="lg:w-3/5 bg-black h-full hidden lg:block ">
                        <img class="object-cover w-full h-full bg-gray-900 bg-opacity-30" src={Image} alt="Side" />
                    </div>
                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/5">
                        <div className="flex-1">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">BlissIQ</h2>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Reset Password</p>

                                <p className="max-w-xl mt-3 text-gray-400"> Don't worry! We're here to help you regain control of your
                                    account.</p>
                            </div>

                            <div className="mt-8">
                                <form>
                                    <div className="relative">
                                        <label htmlFor="password" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                                            New Password
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                id="password"
                                                placeholder="Set New Password"
                                                value={newPassword}
                                                onChange={(e) =>
                                                    handlePasswordChange("newPassword", e.target.value)
                                                }
                                                className="block w-full pr-10 pl-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                            />
                                            {/* <input
                                                type={showPassword ? "text" : "password"}
                                                className={`textbox ${passwordsMatch ? "" : "password-error"
                                                    }`}
                                                placeholder="Set New Password"
                                                value={newPassword}
                                                onChange={(e) =>
                                                    handlePasswordChange("newPassword", e.target.value)
                                                }
                                            /> */}
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-3 pt-8">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6 text-gray-400"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        <div className="relative">
                                            <label htmlFor="password" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                                                Confirm Password
                                            </label>
                                            <div className="flex items-center">
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    name="password"
                                                    placeholder="Confirm New Password"
                                                    value={confirmPassword}
                                                    onChange={(e) =>
                                                        handlePasswordChange("confirmPassword", e.target.value)
                                                    }
                                                    className="block w-full pr-10 pl-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                                                />

                                                {/* <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className={`textbox ${passwordsMatch ? "" : "password-error"
                                                        }`}
                                                    placeholder="Confirm New Password"
                                                    value={confirmPassword}
                                                    onChange={(e) =>
                                                        handlePasswordChange("confirmPassword", e.target.value)
                                                    }
                                                /> */}
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-3 pt-8">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6 text-gray-400"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            disabled={isButtonDisabled}
                                            onClick={handleResetPassword}
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Reset Password
                                        </button>
                                    </div>

                                </form>

                                <p className="mt-6 text-sm text-center text-gray-400"><Link to="/login" className="text-blue-500 focus:outline-none hover:text-red-500">Return to main page</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword