const Question = ({ question , setQuestion , setQuestionsArray}) => {
    
    function handleSingleChoiceAnswer(e){
        const value = e.target.value;
        setQuestion((question)=>{
            return {...question , answers:[value] , attempted:true }
        })
        setQuestionsArray((questionsArray)=>{
            return questionsArray.map((q)=>{
                if(q.id === question.id){
                    return {...q , answers:[value] , attempted:true }
                }else{
                    return q
                }
            })
            
        })
    }
    function handleMultipleChoiceAnswer(e){
        let arr = [...question.answers];
        const value = e.target.value;
        if( e.target.checked ){
            arr.push(value)
        }else{
            arr = arr.filter((item) => item !== value);
        }
        
        const attemptedOrNot = arr.length > 0 ? true:false
        setQuestion((question)=>{
            return {...question , answers:arr , attempted:attemptedOrNot }
        })
        setQuestionsArray((questionsArray)=>{
            return questionsArray.map((q)=>{
                if(q.id === question.id){
                    return {...q , answers:arr , attempted:attemptedOrNot }
                }else{
                    return q
                }
            })
        })
    }

    return (<>
        <div className={`bg-white w-full grow flex flex-col
            border border-solid border-slate-300 relative p-2 rounded-t-3xl
            overflow-auto gap-2 h-20 pt-8 px-4
            `}>
            <div className="font-bold">
                {question.text}
            </div>
            {
                question.answer_type !== 'audio' ? (
                    <>
                        <section
                            className="text-xs flex flex-col gap-2"
                        >
                            {
                                question.answer_type === 'single' ? (
                                    question.options.map((option, optionIndex) => {
                                        return (
                                            <div className="flex gap-1 items-center px-2 py-3 rounded-lg" style={{border:'solid 0.5px #e1deff'}} key={optionIndex} >
                                                <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl font-semibold" >{`${String.fromCharCode(65 + optionIndex)}`}</span>
                                                <input className="w-4/5 outline-none text-xs font-semibold" type="text" placeholder={`option ${optionIndex + 1}`} value={option} />
                                                <input className="text-xl" type="radio" name="radio" checked={question.answers.includes(option)} value={option} onChange={handleSingleChoiceAnswer} />   
                                            </div>
                                        )
                                    })
                                ) : (
                                    question.answer_type === 'multiple' ? (
                                        question.options.map((option, optionIndex) => {
                                            console.log('maping options ', option, question.correct_answer.includes(option));

                                            return (
                                                <div className="flex gap-1 items-center px-2  py-3 rounded-lg" style={{border:'solid 0.5px #e1deff'}} key={optionIndex} >
                                                    <span className="flex items-center pr-2 border-r border-solid border-gray-300 h-full text-xl font-semibold">{`${String.fromCharCode(65 + optionIndex)})`}</span>
                                                    <input className="w-4/5 outline-none text-xs font-semibold" type="text" placeholder={`option ${optionIndex + 1}`} value={option} />
                                                    <input className="text-xl" type="checkbox" value={option} checked={question.answers.includes(option)} name="radio" onChange={handleMultipleChoiceAnswer}/>
                                                </div>
                                            )
                                        }
                                        )
                                    ) : (
                                        null
                                        // return component for audio 
                                    )
                                )
                            }
                        </section>
                    </>
                ) : (null)
            }
        </div>
    </>);
}

export default Question;