import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Avatar from "../assets/avatar.jpg";
import { BASEURL } from "../../constants";
import { jwtDecode } from "jwt-decode";
import formatDate from "./dateformatter";
import toast from "react-hot-toast";

const AdminStudentDetails = () => {

  const { schoolId, userId } = useParams();
  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState(null);
  const [file, setFile] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);

  const dropdownRef = useRef(null);

  useEffect(() => {
    // Fetch student details for the specific school and user
    axios
      .get(
        `${BASEURL}/api/fetch-student-details/${schoolId}/${userId}`
      )
      .then((response) => {
        setStudentDetails(response.data.studentDetails);
      })
      .catch((error) => {
        console.error("Error fetching student details:", error);
      });
  }, [schoolId, userId]);

  function checkImageExists(imageUrl) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve();
      image.onerror = () => reject();
      image.src = imageUrl;
    });
  }

  useEffect(() => {
    // Fetch teacher image
    axios
      .get(`${BASEURL}/api/retrieve-profile-image/${userId}/student`)
      .then(async (response) => {
        const imageUrl = response.data.dataUrl;

        try {
          // Check if the image exists in the S3 bucket
          await checkImageExists(imageUrl);

          // If the image exists, set the file state
          setFile(imageUrl);
        } catch (error) {
          // If the image doesn't exist, set the file state to the default image
          setFile(Avatar);
        }
      })
      .catch((error) => {
        console.error("Error fetching student image:", error);
        // Set file to an empty string when an error occurs
        setFile("");
      });

    // Add a global click event listener to close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("click", handleClickOutside);
    };
  }, [schoolId, userId]);

  const replacePlaceholders = (string, dataObject) => {
    return string.replace(/{(\w+)}/g, (match, key) => {
      if (key === 'birthday') {
        const birthdateValue = dataObject && dataObject[key];
        return formatDate(birthdateValue); // Use the formatDate function
      }
      return (dataObject && dataObject[key]) || 'N/A';
    });
  };
  
  const handleEditClick = () => {
    // Trigger file input click when the "edit" is clicked
    document.getElementById("fileInput").click();
  };

  const onEditStudent = (userId) => {
    navigate(`/admin/editStudent/${schoolId}/${userId}`);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    // Check if the selected file is larger than 5 MB
    if (selectedFile.size > 5 * 1024 * 1024) {
      // Show an error toast message
      // Replace the following line with your actual toast library/error message handling
      toast.error("File too large, limit is 5 MB");
      // Optionally, you can display a toast notification to the user here
      return;
    }

    // Update state with the selected file
    setNewImage(selectedFile);

    // Prepare form data
    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    try {
      // Call update-profile-image API
      const response = await axios.put(
        `${BASEURL}/api/update-profile-image/${userId}/student`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Log success message or handle as needed
      console.log("Image updated successfully:", response.data);

      window.location.reload();
      // Set file to the new image URL after successful update
      setFile(response.data.dataUrl);

      // Close the dropdown or perform any other necessary UI update
      setDropdownVisible(false);
    } catch (error) {
      console.error("Error updating image:", error);
      toast.error("Oops! Something went wrong");
      // Handle error as needed
    }
  };

  const handleDeleteImage = () => {
    // Send request to delete profile image
    axios
      .delete(`${BASEURL}/api/delete-profile-image/${userId}/student`)
      .then((response) => {
        console.log("Image deleted successfully:", response.data.message);
        // Set file to an empty string after successful deletion
        setFile("");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  };

  const [selectedDetails, setSelectedDetails] = useState('personal');

  const handleDetailsChange = (details) => {
      setSelectedDetails(details);
  };

  return (
    <>
      <div className='mx-4 border-b border-gray-200'>
        <div className='pt-2 pb-3 flex justify-between'>
          <div className="flex items-center overflow-x-auto whitespace-nowrap">
            <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
              Profile
            </p>
          </div>

        </div>

      </div>

      {studentDetails ? (
        <div className="py-6 mx-auto flex gap-4 h-full">
          <div className="bg-white rounded-md border-solid border border-gray-200 p-3 h-full w-1/5">
            <div className="relative">
              <img src={file || Avatar} className="image" alt='teacherIMG' />
              <div className="absolute right-5 bottom-3 bg-white p-1.5 rounded-full ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer" onClick={() => setDropdownVisible(!dropdownVisible)}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
              </div>
              {dropdownVisible && (
                <div className="absolute right-2 z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800">
                  <div class="cursor-pointer flex items-center p-3 text-sm text-gray-800 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => handleEditClick()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mx-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                    </svg>

                    <span class="mx-1">
                      Edit Picture
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </span>
                  </div>

                  <div class="cursor-pointer flex items-center p-3 text-sm text-gray-800 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => handleDeleteImage()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>


                    <span class="mx-1">
                      Delete Picture
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col mt-4 ml-1">
              <p className="text-2xl text-blue-500 font-bold cursor-pointer">{replacePlaceholders("{first_name}", studentDetails)}{" "}
                {replacePlaceholders("{last_name}", studentDetails)}</p>

              <div className="flex my-2 py-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                </svg>

                <p className="text-sm text-slate-900 dark:text-gray-200">{replacePlaceholders(
                  "{school_name}",
                  studentDetails
                )}</p>
              </div>
              <div className="flex mb-2 py-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
                <p className="text-sm text-slate-900 dark:text-gray-200">{replacePlaceholders("SAP ID - {sap_id}", studentDetails)}</p>
              </div>

              <div className="flex mb-2 py-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                </svg>

                <p className="text-sm text-slate-900 dark:text-gray-200 capitalize">{replacePlaceholders("{role}", studentDetails)}</p>
              </div>
              <div className="flex mb-2 py-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 mr-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
                </svg>

                <p className="text-sm text-slate-900 dark:text-gray-200">{replacePlaceholders("{email}", studentDetails)}</p>
              </div>

            </div>

            <div className="text-center flex items-center w-full mt-2">
              <button
                type="button"
                className="inline-flex w-full truncate justify-center items-center rounded-md bg-blue-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => onEditStudent(userId)}

              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-1">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
                <span className='text-xs ml-1'>Edit Profile</span>
              </button>
            </div>

          </div>



          <div className="bg-white rounded-md border-solid border border-gray-200 p-4 h-full w-4/5">
            <div className="flex overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap dark:border-gray-700">
              <button
                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'personal'
                  ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                  : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                  } sm:text-base dark:text-blue-300 whitespace-nowrap focus:outline-none`}
                onClick={() => handleDetailsChange('personal')}
              >
                Personal Details
              </button>
              <button
                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'address'
                  ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                  : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                  } sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none`}
                onClick={() => handleDetailsChange('address')}
              >
                Address Details
              </button>
              <button
                className={`inline-flex items-center h-10 px-4 -mb-px text-sm text-center ${selectedDetails === 'family'
                  ? 'text-blue-600 bg-transparent border-b-2 border-blue-500'
                  : 'text-gray-700 bg-transparent border-b-2 border-transparent hover:border-gray-400'
                  } sm:text-base dark:text-white whitespace-nowrap cursor-base focus:outline-none`}
                onClick={() => handleDetailsChange('family')}
              >
                Family Details
              </button>
            </div>

            <div className="overflow-x-auto overflow-y-hidden border-b border-gray-200 whitespace-nowrap dark:border-gray-700">
              <table className="w-full">
                <tbody>
                  {/* <!-- Entry 1 --> */}
                  {selectedDetails === 'personal' && (
                    <>
                      <tr className="flex text-start border-b border-gray-200 mt-2">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>First Name</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders(
                            "{first_name}",
                            studentDetails
                          )}
                        </td>
                      </tr>

                      {/* <!-- Entry 2 --> */}
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Middle Name</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders(
                            "{middle_name}",
                            studentDetails
                          )}
                        </td>
                      </tr>

                      {/* <!-- Entry 3 --> */}

                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Last Name</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders(
                            "{last_name}",
                            studentDetails
                          )}
                        </td>
                      </tr>

                      {/* <!-- Entry 4 --> */}

                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Gender</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{gender}", studentDetails)}
                        </td>
                      </tr>

                      {/* <!-- Entry 5 --> */}

                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Date of Birth</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{birthdate}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-star">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Aadhar Card Number</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{aadharcard_number}", studentDetails)}
                        </td>
                      </tr>
                    </>
                  )}
                  {/* <!-- Address Details --> */}
                  {selectedDetails === 'address' && (
                    <>
                      <tr className="flex text-start border-b border-gray-200 mt-2">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Permanent Address</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders(
                            "{address}",
                            studentDetails
                          )}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>City</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{city}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>State</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{state}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Zip Code</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{zip_code}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Email</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{email}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Contact Number</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{contact_number}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Alternate Number</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                          {replacePlaceholders("{alternative_number}", studentDetails)}
                        </td>
                      </tr>

                    </>
                  )}
                  {selectedDetails === 'family' && (
                    <>
                      <tr className="flex text-start border-b border-gray-200 mt-2">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Father Name</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{father_name}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Father Contact Number</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders(
                      "{father_contact_number}",
                      studentDetails
                    )}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Father Email</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{father_email}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Mother Name</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{mother_name}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Mother Contact Number</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders(
                      "{mother_contact_number}",
                      studentDetails
                    )}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Mother Email</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{mother_email}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Guardian Name</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{guardian_name}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start border-b border-gray-200">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Guardian Contact Number</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{guardian_contact_number}", studentDetails)}
                        </td>
                      </tr>
                      <tr className="flex text-start ">
                        <th
                          scope="col"
                          className="py-3.5 px-4 w-1/4 text-sm text-left text-gray-900 font-medium dark:text-gray-400"
                        >
                          <button className="flex items-center gap-x-3 focus:outline-none">
                            <span>Guardian Email</span>
                          </button>
                        </th>
                        <td className="pr-16 py-3.5 text-sm font-normal text-left flex-wrap text-gray-500 dark:text-gray-400">
                        {replacePlaceholders("{guardian_email}", studentDetails)}
                        </td>
                      </tr>
                     

                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>) : (
        <div>Loading</div>
      )}





    </>
  )
}

export default AdminStudentDetails