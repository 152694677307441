import {useState} from 'react';

const PowerPoint = ({ contentObj, setCurrentSlideData , setIsDataSaved }) => {
    const [url, setUrl] = useState('')

    const handleUrlInput = (e) => {
        if (e.target.value) {
            setUrl(e.target.value)
        }
    }

    const handleUpload = () => {
        setCurrentSlideData((currentSlide) =>{
            return {
                ...currentSlide , content : currentSlide.content.map((contentObject) => {
                    if (contentObject.id === contentObj.id) {
                        return {
                            ...contentObject , data : url
                        }
                    }
                    return {
                        ...contentObject
                    }
                })
            }
        })
    }

    if (contentObj.data) {
        return (
            <iframe src='https://view.officeapps.live.com/op/view.aspx?src=[https://www.your_website/file_name.pptx]' width='100%' height='600px' frameborder='0'></iframe>
        )
    } else {
        return (
            <div>
                <input type="url" onChange={handleUrlInput} />
                <button onClick={handleUpload}>Upload</button>
            </div>
        );
    }

}

export default PowerPoint;