import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BASEURL } from "../../constants";
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import '../Global/Styles/tabulatorStyles.css';
import { ReactTabulator } from 'react-tabulator';

import toast from "react-hot-toast";

// Debounce Hook
// This custom hook is used to debounce the search term input, preventing excessive re-renders or API calls when the user is typing.
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

const AdminAllMentors = () => {
    const { schoolId } = useParams();
    const navigate = useNavigate();
    const [mentorsData, setMentorsData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredMentors, setFilteredMentors] = useState([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [openPopup, setOpenPopup] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [rowData, setRowData] = useState(null);


    // Fetch mentors
    useEffect(() => {
        axios
            .get(`${BASEURL}/api/fetch-all-mentors-data/${schoolId}`)
            .then((res) => {
                setMentorsData(res.data.mentorsData || []);
            })
            .catch((error) => {
                console.error("Error fetching mentors data:", error);
            });
    }, []);

    // Search Filter 
    useEffect(() => {
        const filtered = mentorsData.filter(
            (mentor) =>
                mentor.first_name
                    .toLowerCase()
                    .includes(debouncedSearchTerm.toLowerCase()) ||
                mentor.last_name
                    .toLowerCase()
                    .includes(debouncedSearchTerm.toLowerCase())
        );
        setFilteredMentors(filtered);
    }, [debouncedSearchTerm, mentorsData]);


    // Define columns for the Tabulator table
    const columns = [
        { title: "Sno.", field: "number", width: 70, hozAlign: "center", cssClass: "center-text", headerSort: true, resizable: true },
        { title: "Name", field: "name", headerSort: true, resizable: true },
        { title: "Email", field: "email", headerSort: true, resizable: true },
        { title: "Contact", field: "contact", headerSort: true, resizable: true },
        { title: "Location", field: "location", headerSort: true, resizable: true },
        {
            title: 'Actions',
            formatter: () => {
                return '<button className="action-btn">•••</button>';
            },
            width: 100,
            cellClick: (e, cell) => {
                const rect = e.target.getBoundingClientRect();
                setPopupPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX - 280 }); // Adjusted position to the left
                setRowData(cell.getRow().getData());
                setOpenPopup(cell.getRow().getData().id); // Set the row ID to identify the open popup
            },
            headerSort: false
        }
    ];

    // Prepare table data for the ReactTabulator component
    const data = (filteredMentors || []).map((mentor, index) => ({
        id: mentor.user_id,
        number: index + 1,
        name: `${mentor.first_name} ${mentor.last_name}`,
        email: mentor.email,
        contact: mentor.contact_number,
        location: `${mentor.city}, ${mentor.state}`,
    }));

    // Navigate to the mentor's profile page
    const onViewDetails = (rowData) => {
        const { id } = rowData;
        navigate(`/admin/allMentors/${schoolId}/${id}`);
        console.log('userID', id);
        setOpenPopup(null);
    };

    const options = {
        pagination: 'remote', // or 'local'
        paginationSize: 10, // number of rows per page
    };

    return (
        <>
            <div className='mx-4 border-b border-gray-200'>
                <div className='pt-2 pb-3 flex justify-between'>
                    <div className="flex items-center overflow-x-auto whitespace-nowrap">
                        <p className="text-2xl text-slate-900 dark:text-gray-200 font-bold cursor-pointer">
                            Mentors
                        </p>
                    </div>
                    <div className='flex flex-row justify-between flex-x-2'>
                        <div className="relative flex items-center rounded-lg focus-within:shadow-lg bg-white overflow-hidden mr-4">
                            <div className="grid place-items-center w-12 text-gray-300">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input
                                className="peer w-full outline-none text-sm text-gray-700 pr-2"
                                type="text"
                                id="search"
                                placeholder="Search Mentor Name"
                                onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Render the ReactTabulator component with the table data and columns */}
            <ReactTabulator
                data={data}
                columns={columns}
                layout="fitColumns"
                responsiveLayout="hide"
                tooltips={true}
                options={options}
                className="custom-tabulator-styles"
            />

            {/* Render the popup menu when a row is clicked */}
            {openPopup && (
                <div
                    className="absolute bg-white shadow-lg rounded-lg py-2 w-48"
                    style={{ top: popupPosition.top, left: popupPosition.left }}
                >
                    <div className="cursor-pointer flex items-center px-3 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => onViewDetails(rowData)}>
                        <svg className="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill="currentColor"></path>
                            <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" fill="currentColor"></path>
                        </svg>

                        <span className="mx-1">
                            view profile
                        </span>
                    </div>

                    
                </div>
            )}

        </>
    );
};

export default AdminAllMentors;

