// CreateCourseModal.jsx
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { BASEURL } from '../../../constants.js';
import { jwtDecode } from "jwt-decode";
import Select from 'react-select';

import CreatableSelect from 'react-select/creatable';


const DiactoCreateCourseModal = ({ isOpen, onClose }) => {
    const [courseName, setCourseName] = useState("");
    const [subjectName, setSubjectName] = useState("");
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [courseDescription, setCourseDescription] = useState("");

    const [selectedClassIds, setSelectedClassIds] = useState([]);
    const [selectAllClasses, setSelectAllClasses] = useState(false);

    const [loading, setLoading] = useState(false);
    const [contentNeeds, setContentNeeds] = useState('');
    const [textLength, setTextLength] = useState('');
    const [readingLevel, setReadingLevel] = useState('');
    const [result, setResult] = useState('');
    const [copied, setCopied] = useState(false);

    const [userId, setUserId] = useState(null);

    const [coverImage, setCoverImage] = useState(null);

    const [selectedOptions, setSelectedOptions] = useState([]);


    const notify = () => toast.success("Course successfully created !!");
    const navigate = useNavigate();

    // const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);

    const subjectSelectOptions = subjectOptions.map((subject) => ({
        value: subject.subject_id,
        label: subject.subject_name,
    }));

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        if (isOpen) {
            const token = localStorage.getItem('auth');
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.user_id);
            console.log(`Opening modal for userId: ${decodedToken.user_id}`);
        }
    }, [isOpen]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const subjectsResponse = await axios.get(`${BASEURL}/api/get-subjects`);
                setSubjectOptions(subjectsResponse.data.subjects);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchSubjects();
    }, []);

    // useEffect(() => {
    //     const fetchClasses = async () => {
    //         try {
    //             const response = await axios.get(`${BASEURL}/api/get-classes`);
    //             setClassOptions(response.data.classes);
    //         } catch (error) {
    //             console.error('Error fetching classes:', error);
    //         }
    //     };

    //     fetchClasses();
    // }, []);

    useEffect(() => {
        // Generate class options from 1 to 12
        const classes = Array.from({ length: 12 }, (_, i) => ({
            class_id: i + 1,
            class_name: `${i + 1}`
        }));
        setClassOptions(classes);
    }, []);

    const handleSubjectSelection = (selectedOption) => {
        if (selectedOption) {
            const { value, label } = selectedOption;
            console.log(`Selected Subject ID: ${value}, Subject Name: ${label}`);
            console.log(`The userid: ${userId} and subjectId: ${value}`);
            setSelectedSubject(selectedOption);
        } else {
            console.log('Subject deselected');
            setSelectedSubject(null);
        }
    };


    const handleClassSelection = (classId) => {
        console.log('handleClassSelection called with classId:', classId);

        setSelectedClassIds((prevSelectedClassIds) => {
            console.log('Previous selectedClassIds:', prevSelectedClassIds);

            if (prevSelectedClassIds.includes(classId)) {
                // Remove the class ID from the array if it's already present (deselected)
                const updatedSelectedClassIds = prevSelectedClassIds.filter((id) => id !== classId);
                console.log('Class deselected:', classId);
                console.log('Updated selectedClassIds:', updatedSelectedClassIds);
                return updatedSelectedClassIds;
            } else {
                // Add the class ID to the array if it's not present (selected)
                const updatedSelectedClassIds = [...prevSelectedClassIds, classId];
                console.log('Class selected:', classId);
                console.log('Updated selectedClassIds:', updatedSelectedClassIds);
                return updatedSelectedClassIds;
            }
        });
    };

    const handleSelectAllClasses = () => {
        setSelectAllClasses((prevState) => !prevState);
        if (selectAllClasses) {
            setSelectedClassIds([]);
            console.log('All classes deselected');
        } else {
            const allClassIds = classOptions.map((classOption) => classOption.class_id);
            setSelectedClassIds(allClassIds);
            const classNames = classOptions.map((classOption) => classOption.class_name);
            console.log('All classes selected:', classNames);
        }
    };

    const difficultyLevelOptions = [
        { value: 'easy', label: 'Easy' },
        { value: 'medium', label: 'Medium' },
        { value: 'hard', label: 'Hard' },
    ];

    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState(null);

    const handleCoverImageChange = (e) => {
        if (e.target.files[0]) {
            setCoverImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!courseName || !courseDescription || !selectedSubject || !selectedDifficultyLevel) {
            toast.error('Please fill in all required fields.');
            return;
        }

        console.log("Form Data:", {
            courseName,
            courseDescription,
            subjectId: selectedSubject.value,
            classIds: selectedClassIds,
            difficultyLevel: selectedDifficultyLevel.value,
        });

        const token = localStorage.getItem('auth');
        const decodedToken = jwtDecode(token);
        // const schoolId = decodedToken.school_id;
        const role = decodedToken.role
        const userId = decodedToken.user_id

        const formData = new FormData();
        formData.append('courseName', courseName);
        formData.append('courseDescription', courseDescription);
        formData.append('subjectId', selectedSubject.value);
        formData.append('classIds', JSON.stringify(selectedClassIds));
        // formData.append('schoolId', schoolId);
        formData.append('role', role);
        formData.append('userId', userId);
        formData.append('difficultyLevel', selectedDifficultyLevel.value);
        formData.append('tags', JSON.stringify(selectedOptions.map(option => option.value))); // Add this line

        if (coverImage) {
            formData.append('coverImage', coverImage);
            console.log("Cover Image appended:", coverImage.name);
        }

        // Log the contents of formData
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const response = await axios.post(`${BASEURL}/api/diacto-create-course`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log("Response from server:", response.data);

            notify();
            // navigate(`/course-builder/${response.data.courseId}`);
            navigate(`/admin-course-builder/${response.data.courseId}`);
        } catch (error) {
            console.error('Error creating course:', error.response ? error.response.data : error.message);
            toast.error('Failed to create course.');
        }
    };

    function handleGenerateContent() {
        setLoading(true);
        //
        axios.post(`${BASEURL}/api/get-content`, {
            prompt: contentNeeds,
            maxTokens: textLength,
            readingLevel: readingLevel
        }).then((response) => {
            console.log('response ', response);
            setLoading(false);
            setCopied(false);
            let result = response.data.content;
            //convert into object
            result = result.replace('"Answer":', '');
            result = result.slice(2, -2);
            setResult(result);
        })
    }


    if (!isOpen) return null;




    return (

        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-15 backdrop-blur-sm transition-opacity duration-300 ease-out bg-gray-400" >
                {/* Sidebar component */}
                {isSidebarOpen && (
                    <div className="fixed inset-0 z-50 flex justify-end">
                        <div className="w-2/6 h-full bg-white shadow-xl p-4 transform transition-transform duration-300 ease-in-out">

                            {/* Add your sidebar content here */}
                            <div className="mt-4">

                                <div className="flex flex-row justify-between">
                                    <h2 className="text-lg font-semibold">AI Content Generator</h2>
                                    <button onClick={toggleSidebar} className="text-gray-700 hover:text-red-500 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>

                                    </button>
                                </div>

                                <div className="mb-2 mt-8">
                                    <label class="text-sm text-gray-700 dark:text-gray-200">
                                        Describe your content needs
                                    </label>

                                    <input value={contentNeeds} onChange={(e) => { setContentNeeds(e.target.value) }} class="block mt-2 w-full px-2 py-2 text-xs text-gray-700 bg-white border border-gray-200 rounded-md " type="text" name="text" />
                                </div>

                                <div className="mb-2">
                                    <label class="text-sm text-gray-700 dark:text-gray-200">
                                        Number of Words
                                    </label>

                                    <input type='number' min='0' value={textLength} onChange={(e) => { setTextLength(e.target.value) }} name="text"
                                        class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md " />
                                </div>

                                <div className="mb-2">
                                    <label class="text-sm text-gray-700 dark:text-gray-200">
                                        Reading Level (Age)
                                    </label>

                                    <input autoComplete="off" value={readingLevel} onChange={(e) => { setReadingLevel(e.target.value) }} type="text" name="text"
                                        class=" block mt-2 w-full px-2 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md " />
                                </div>
                                {/* 
                                <div className="mt-4 flex items-center justify-end gap-x-2">

                                    <button className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40">
                                        Generate
                                    </button>
                                </div> */}

                                <div className="mt-2 flex items-center justify-end gap-x-2">
                                    <button disabled={loading} onClick={handleGenerateContent} className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 ">
                                        {
                                            loading ? (
                                                <>
                                                    <svg aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                                    </svg>
                                                    Generating
                                                </>
                                            ) : (
                                                <>Generate</>
                                            )
                                        }
                                    </button>
                                </div>

                                <div className="mt-2">
                                    <label class="text-sm text-gray-700 ">
                                        Result
                                    </label>
                                    <textarea value={result} rows={8} type="text" name="text"
                                        className="text-xs block mt-2 w-full px-2 py-2 text-gray-700 bg-white border border-gray-200 rounded-md " />
                                    <div className="text-right">
                                        {
                                            copied ? (
                                                <button className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 mt-2">Copied</button>
                                            ) : (
                                                <button onClick={(e) => {
                                                    navigator.clipboard.writeText(result).then(function () {
                                                        setCopied(true);
                                                    })
                                                }} className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 mt-2">Copy</button>
                                            )
                                        }
                                    </div>
                                </div>


                                {/* Add more sidebar elements as needed */}
                            </div>
                        </div>
                    </div>
                )}
                <div className={`relative inline-block 2xl:w-4/12 2xl:h-max lg:w-6/12 lg:h-5/6 lg:overflow-y-scroll 2xl:overflow-y-clip px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all duration-300 transform bg-white rounded-lg shadow-xl dark:bg-gray-900 ${isSidebarOpen ? '-translate-x-1/3' : ''}`}>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                            <label class="text-sm text-gray-700 dark:text-gray-200">
                                Title
                            </label>

                            <input type="text" name="text" id="text" value={courseName} onChange={(e) => setCourseName(e.target.value)} class=" block mt-2 w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />

                            <div className="mt-3 p-2 bg-sky-50 w-full rounded-md flex flex-row justify-between">
                                <p className="text-xs flex-1">Let our AI work magic on your course content</p>
                                <button
                                    type="button"
                                    className="flex-1 text-xs ml-1 py-2 font-medium text-blue-500 bg-white border-solid border border-blue-500 rounded-md transition-colors duration-300 transform hover:bg-blue-500 hover:text-white focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                                    onClick={toggleSidebar}
                                >
                                    Use AI Generator
                                </button>
                            </div>
                        </div>
                        <div className="mb-2 mt-4">
                            <label class="text-sm text-gray-700 dark:text-gray-200">
                                Course Description
                            </label>

                            <input type="text" name="text" id="text" value={courseDescription}
                                onChange={(e) => setCourseDescription(e.target.value)} class=" block mt-2 w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300" />
                        </div>
                        <div className="mb-2 mt-4">
                            <label className="text-sm text-gray-700 dark:text-gray-200">
                                Cover Image (Optional)
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleCoverImageChange}
                                className="block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full dark:file:bg-gray-800 dark:file:text-gray-200 dark:text-gray-300 placeholder-gray-400/70 dark:placeholder-gray-500 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:focus:border-blue-300 cursor-pointer"
                            />
                        </div>
                        <div className="mb-2 mt-4">
                            <label className="text-sm text-gray-700 dark:text-gray-200">Subject</label>
                            <CreatableSelect
                                className="block mt-2 w-full text-sm"
                                options={subjectSelectOptions}
                                value={selectedSubject}
                                onChange={handleSubjectSelection}
                                isClearable
                            />
                        </div>
                        <div className="mb-2 mt-4">
                            <label className="text-sm text-gray-700 dark:text-gray-200">
                                Difficulty Level
                            </label>
                            <Select
                                className="block mt-2 w-full text-sm"
                                options={difficultyLevelOptions}
                                value={selectedDifficultyLevel}
                                onChange={(option) => setSelectedDifficultyLevel(option)}
                                isClearable
                            />
                        </div>
                        <div className="mb-2 mt-4">
                            <label className="text-sm text-gray-700">
                                Tags <span className='text-xs'>(Upto 3 tags)</span>
                            </label>

                            {/* <CreatableSelect isMulti className='block text-sm mt-2 w-full text-sm text-gray-700 bg-white rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-blue-300' /> */}

                            <CreatableSelect
                                isMulti
                                value={selectedOptions}
                                onChange={(e) => setSelectedOptions(e)}
                                name="colors"
                                isOptionDisabled={() => selectedOptions.length >= 3}
                                className="block mt-2 w-full text-sm text-gray-700 bg-white rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                                classNamePrefix="select"
                            />
                        </div>

                        <div className="mb-2 mt-4">
                            <div>
                                <div className="flex justify-between align-center">
                                    <label className="text-sm text-gray-700 dark:text-gray-200">Class</label>
                                    <label className="relative inline-flex cursor-pointer items-center">
                                        <input
                                            id="switch"
                                            type="checkbox"
                                            className="peer sr-only"
                                            checked={selectAllClasses}
                                            onChange={handleSelectAllClasses}
                                        />
                                        <label htmlFor="switch" className="hidden"></label>
                                        <div className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-500 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
                                        <p className="text-xs ml-3">Select all Classes</p>
                                    </label>
                                </div>
                                <div className="my-8 cursor-pointer">
                                    {classOptions.map((classOption) => (
                                        <span
                                            key={classOption.class_id}
                                            className={`
                                                px-4 py-2 mx-1 rounded-full transition-colors duration-300 transform border-solid border-2 border-blue-200
                                                ${selectedClassIds.includes(classOption.class_id)
                                                    ? 'bg-blue-500 text-white'
                                                    : 'bg-white text-gray-700 hover:bg-blue-500 hover:text-white'
                                                }
                                        `}
                                            onClick={() => handleClassSelection(classOption.class_id)}
                                        >
                                            {classOption.class_name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center justify-end gap-x-2">
                            <button onClick={onClose} className="px-4 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 transition-colors duration-300 transform border border-gray-200 rounded-md hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40">
                                Cancel
                            </button>
                            <button className="px-4 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-md transition-colors duration-300 transform hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40">
                                Create
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default DiactoCreateCourseModal;
