import { useEffect, useRef, useState } from 'react';
import './SideBar.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASEURL } from '../../../../constants';

const SideBar = ({validateQuestion, sidebarData, setSidebarData,
    handleSaveCourse,
    setSelectedChapterId, setSelectedTestId,
    selectedChapterId, selectedTestId, autoSaveSlide, autoSaveQuestion,
    setImagesArray, setSelectedTopicId, selectedTopicId
}) => {
    console.log("rendeing sidebar ",)
    const [isEditing, setIsEditing] = useState(null);
    const { courseId } = useParams();
    const [isHovering, setIsHovering] = useState("");
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState({
        show: '',
        deleteWhat: 'topic / subTopic / test',
        topicId: '',
        subTopicId: '',
        testId: ''
    });
    //state variable to store the users input then on click of update button we change the semesters state
    //same state variable will be used to update sem name , chap name , section name.
    const [newName, setNewName] = useState("");
    const buttonRef = useRef();
    //const {semesterId , chapterId , sectionId } = useparams();
    const lastActive = useRef(new Date());
    const timeOutDuration = 1000 * 60 * 60;  // 1hour in milliseconds

    function handleToggleClick(event) {
        const accordionHeader = event.currentTarget.parentNode.parentNode;

        accordionHeader.classList.toggle('active');

        const accordionBody = accordionHeader.nextElementSibling;
        if (accordionHeader.classList.contains("active")) {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
        } else {
            accordionBody.style.maxHeight = 0;
        }
    }

    useEffect(() => {
        const toggles = document.querySelectorAll('.toggle');
        console.log("toggles.length" , toggles.length)
        if (toggles.length > 0) {
            const firstToggle = toggles[0];
            console.log('firstToggle:', firstToggle);
            console.log('Element type:', firstToggle.constructor.name);

            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            firstToggle.dispatchEvent(event);
        }
    }, []);

    useEffect(() => {
        //this useEffect will run to update the height of the dropdown when user adds a slidegroup or a test
        console.log('updating the height')
        const accordionHeaders = document.querySelectorAll('div.accordion-header.active');

        accordionHeaders.forEach((accordionHeader) => {
            if (accordionHeader) {
                const accordionBody = accordionHeader.nextElementSibling;
                if (accordionHeader.classList.contains("active")) {
                    accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                } else {
                    accordionBody.style.maxHeight = 0;
                }
            }
        })

    }, [selectedChapterId, selectedTestId , selectedTopicId])

    useEffect(() => {
        document.addEventListener('keydown', handleUserActivity);
        document.addEventListener('mousemove', handleUserActivity);

        return () => {
            document.removeEventListener('keydown', handleUserActivity);
            document.removeEventListener('mousemove', handleUserActivity);
        }
    }, [])

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            //user inActive for an hour log him out i.e delete the token and route the user to login page 
            //also save his data 
            handleSaveCourse('logout');
        }, timeOutDuration - (new Date() - lastActive.current));

        return () => { clearTimeout(timeOutId) }
    }, [lastActive.current])

    function handleUserActivity() {
        lastActive.current = new Date();
    }

    function addTopic() {

        setError(false)
        axios.post(`${BASEURL}/api/add-topic`, {
            courseId: courseId
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData((sidebarData) => {
                    let newSidebarData = sidebarData;
                    newSidebarData.topics.push({
                        id: response.data.insertId, name: 'Untitled',
                        content: []
                    })
                    return newSidebarData;
                });
                setIsEditing(`topic${response.data.insertId}`)
                setNewName('Untitled');
                // buttonRef.current.click();
            } else {
                setError('adding semester failed try again');
                setNewName('Untitled');
            }
        })
    };

    function addSlideGroup(topicId, type, sequence) {

        function addSubtopic() {
            axios.post(`${BASEURL}/api/add-sub-topic`, {
                courseId: courseId,
                topicId: topicId,
                type: type,
                sequence: sequence
            }).then((response) => {
                if (response.data.status === 'success') {
                    //create a new slide to add into the slide group
                    axios.post(`${BASEURL}/api/add-slide`, {
                        subTopicId: response.data.insertId,
                        sequence: 1,
                        topicId: topicId,
                        courseId: courseId
                    }).then((addSlideResponse) => {
                        // it returns the slide 
                        console.log('add slide response ', addSlideResponse);
                        const createdSlide = addSlideResponse.data;
                        setSidebarData((sidebarData) => {
                            const newTopics = sidebarData.topics.map((topic) => {
                                if (topic.id === topicId) {
                                    return {
                                        ...topic, content: [...topic.content, {
                                            id: response.data.insertId,
                                            name: 'Untitled',
                                            type: type,
                                            sequence: sequence
                                        }]
                                    }
                                } else {
                                    return topic;
                                }
                            })
                            return { topics: newTopics }
                        });
                        // setMainCourseData(res.data);
                        setSelectedChapterId(response.data.insertId);
                        setSelectedTestId(null);
                        setNewName('Untitled');
                        setIsEditing(`subtopic${response.data.insertId}`);
                        setSelectedTopicId(topicId);
                    })

                } else {
                    setError('adding chapter failed try again');
                    setShowModal((showModal) => { return { ...showModal, show: false } })
                }
            })
        }

        if (selectedChapterId) {
            autoSaveSlide().then(() => {
                addSubtopic()
            })
        }
        if (selectedTestId) {
            if (!validateQuestion()) {
                // If validation fails, return early
                return;
            }
            autoSaveQuestion().then(() => {
                addSubtopic()
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            addSubtopic();
        }


    }

    function addTest(topicId, type, sequence) {
        console.log('clicked')
        function addTestAndQuestion() {
            axios.post(`${BASEURL}/api/add-test`, {
                topicId: topicId,
                type: type,
                sequence: sequence,
                courseId: courseId
            }).then((response) => {
                if (response.data.status === 'success') {
                    axios.post(`${BASEURL}/api/add-question`, {
                        testId: response.data.insertId,
                        sequence: 1,
                        topicId: topicId,
                        courseId: courseId
                    }).then((addQuestionResponse) => {
                        //question added 
                        setSidebarData((sidebarData) => {
                            const newTopics = sidebarData.topics.map((topic) => {
                                if (topic.id === topicId) {
                                    return {
                                        ...topic, content: [...topic.content, {
                                            id: response.data.insertId,
                                            name: 'Untitled',
                                            type: type,
                                            sequence: sequence
                                        }]
                                    }
                                } else {
                                    return topic;
                                }
                            })
                            return { topics: newTopics }
                        });
                        setSelectedTestId(response.data.insertId);
                        setSelectedChapterId(null);
                        setNewName('Untitled');
                        setIsEditing(`test${response.data.insertId}`)
                        setSelectedTopicId(topicId)
                        setError(false)
                    })
                }
            })
        }

        if (selectedChapterId) {
            console.log('clicked 1 ')
            autoSaveSlide().then(() => {
                addTestAndQuestion()
            })
        }
        if (selectedTestId) {
            console.log('clicked 2')
            if (!validateQuestion()) {
                // If validation fails, return early
                return;
            }
            autoSaveQuestion().then(() => {
                addTestAndQuestion()
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            console.log('clicked 3')
            addTestAndQuestion();
        }
    }

    function editTopicName(topicId) {
        if (!newName) {
            setError(true);
            return;
        }
        axios.put(`${BASEURL}/api/edit-topic-name`, {
            topicId: topicId,
            name: newName
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData((sidebarData) => {
                    const newTopics = sidebarData.topics.map((topic) => {
                        if (topic.id === topicId) {
                            return {
                                ...topic, name: newName
                            }
                        } else {
                            return topic;
                        }
                    })
                    return {
                        topics: newTopics
                    }
                })
                setIsEditing('');
                setError(false);
                setNewName("");

            } else {
                //handle the failed name update query
            }

            setShowModal((showModal) => { return { ...showModal, show: false } });
        })
    }

    function editSubTopicName(topicId, subTopicId) {
        if (!newName) {
            setError(true);
            return;
        }
        axios.put(`${BASEURL}/api/edit-sub-topic-name`, {
            subTopicId: subTopicId,
            name: newName
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData((sidebarData) => {
                    const newTopics = sidebarData.topics.map((topic) => {
                        if (topic.id === topicId) {
                            return {
                                ...topic, content: topic.content.map((subTopic) => {
                                    if (subTopic.id === subTopicId) {
                                        return {
                                            ...subTopic, name: newName
                                        }
                                    } else {
                                        return subTopic;
                                    }
                                })
                            }
                        } else {
                            return topic;
                        }
                    })
                    return { topics: newTopics };
                })
            }
            // setShowModal((showModal) => { return { ...showModal, show: false } });
            setNewName("");
            setIsEditing(``);
            setError(false);
        })

    }

    function editTestName(semId, testId) {
        if (!newName) {
            setError(true);
            return;
        }

        axios.put(`${BASEURL}/api/edit-test-name`, {
            testId: testId,
            name: newName
        }).then((response) => {
            if (response.data.status === 'success') {
                setSidebarData({
                    topics: sidebarData.topics.map((topic) => {
                        if (topic.id === semId) {
                            return {
                                ...topic, content: topic.content.map((q) => {
                                    if (q.id === testId) {
                                        return {
                                            ...q, name: newName
                                        }
                                    } else {
                                        return q;
                                    }
                                })
                            }
                        } else {
                            return topic;
                        }
                    })
                })
            }
            setIsEditing(``);
            setNewName("");
            setError(false);
        })
    }

    function handleSubTopicClick(chapId, topicId) {
        //if coming from test 

        if (selectedTestId) {

            if (!validateQuestion()) {
                // If validation fails, return early
                return;
            }
            autoSaveQuestion().then((response) => {
                setSelectedChapterId(chapId);
                setSelectedTopicId(topicId);
                setSelectedTestId(null);
                setImagesArray([])
            })
        }
        if (selectedChapterId) {
            autoSaveSlide().then((response) => {
                setSelectedChapterId(chapId);
                setSelectedTopicId(topicId);
                setSelectedTestId(null);
                setImagesArray([])
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            setSelectedChapterId(chapId);
            setSelectedTestId(null);
            setSelectedTopicId(topicId);
        }
    }

    function handleTestClick(testId, topicId) {

        if (selectedTestId) {
            autoSaveQuestion().then((response) => {
                setSelectedTestId(testId);
                setSelectedChapterId(null);
                setSelectedTopicId(topicId);
                setImagesArray([])
            })
        }
        if (selectedChapterId) {
            autoSaveSlide().then((response) => {
                setSelectedTestId(testId);
                setSelectedChapterId(null);
                setSelectedTopicId(topicId);
                setImagesArray([])
            })
        }
        if (!selectedChapterId && !selectedTestId) {
            setSelectedTestId(testId);
            setSelectedChapterId(null);
            setSelectedTopicId(topicId);
        }


    }

    function deleteEntity() {
        // depending on show modal state { show:ture , deleteWhat : 'topic / subTopic / test ' , deleteId : ''}
        if (showModal.deleteWhat === 'subTopic') {
            console.log('show modal', showModal.subTopicId)
            axios.delete(`${BASEURL}/api/delete-sub-topic`, {
                params: {
                    subTopicId: showModal.subTopicId
                }
            }).then((response) => {
                if (response.data.message === 'Slides deleted successfully') {
                    if (selectedChapterId === showModal.subTopicId) {
                        setSelectedChapterId(null);
                    }
                    setSidebarData((sidebarData) => {
                        const newSidebarData = {
                            topics: sidebarData.topics.map((topic) => {
                                if (topic.id === showModal.topicId) {
                                    //we know that 
                                    return {
                                        ...topic, content: topic.content.filter(contentObj => contentObj.id !== showModal.subTopicId)
                                    }
                                } else {
                                    return topic;
                                }
                            })
                        }
                        return newSidebarData;
                    })
                    setShowModal({ ...showModal, show: false })
                }
            })
        }
        if (showModal.deleteWhat === 'test') {
            axios.delete(`${BASEURL}/api/delete-test`, {
                params: {
                    testId: showModal.testId
                }
            }).then((response) => {
                if (response.data.message === 'questions deleted successfully') {
                    if (selectedTestId === showModal.testId) {
                        setSelectedTestId(null);
                    }
                    setSidebarData((sidebarData) => {
                        const newSidebarData = {
                            topics: sidebarData.topics.map((topic) => {
                                if (topic.id === showModal.topicId) {
                                    //we know that 
                                    return {
                                        ...topic, content: topic.content.filter(contentObj => contentObj.id !== showModal.testId)
                                    }
                                } else {
                                    return topic;
                                }
                            })
                        }
                        return newSidebarData;
                    })
                    setShowModal({ ...showModal, show: false })
                }
            })
        }
        if (showModal.deleteWhat === 'topic') {
            console.log('topic id ', showModal.topicId);
            axios.delete(`${BASEURL}/api/delete-topic`, {
                params: {
                    topicId: showModal.topicId
                }
            }).then((response) => {
                console.log(response, 'delete topic');
                /*
                    case 1 : when user is editing slide group or test when the topic is deleted from the same topic -- here you have to set current data to null for everything

                    case 2: when user is editing some other topics content but deletes the other topic -- here you just have to update the sidebar 

                    case 3: when user delete the topic and there are no other topics -- here you just have to update the sidebar
                
                */
                if (response.data.message === 'successfully delted topic') {
                    if (selectedTopicId === showModal.topicId) {
                        setSidebarData((sidebarData) => {
                            const newSidebarData = { topics: sidebarData.topics.filter((topic) => topic.id !== showModal.topicId) }
                            return newSidebarData;
                        })
                        setShowModal({ ...showModal, show: false })
                        setSelectedChapterId(null);
                        setSelectedTestId(null);
                    }
                    //even if no content is being edited the below condition will trigger
                    if (selectedTopicId !== showModal.topicId) {
                        setSidebarData((sidebarData) => {
                            const newSidebarData = { topics: sidebarData.topics.filter((topic) => topic.id !== showModal.topicId) }
                            return newSidebarData;
                        })
                        setShowModal({ ...showModal, show: false })
                    }
                }
            })
        }
    } 

    return (
        <div style={{ width: '15%', boxShadow:'0 12px 40px 0 rgba(200, 205, 212, 0.26)' }} className='h-full py-3 px-2 rounded-xl bg-white overflow-auto' >
            {
                showModal.show &&
                <div className="relative  z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                    <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed h-full  inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                            <div style={{ minWidth: '20%' }} className="relative p-3 h-auto transform flex-col justify-evenly rounded-lg bg-white text-left shadow-xl transition-all my-8">

                                <div className='flex w-full justify-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                    </svg>
                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='font-bold'>Are you sure?</span>

                                </div>
                                <div className="flex justify-center py-2 px-3">
                                    <span className='text-xs text-gray-600'>Do you really want to delete this?This action cannot be undone.</span>

                                </div>
                                <div className="flex justify-around">
                                    <button className='px-3 py-1 border-2 border-gray-300 rounded-lg text-gray-500 text-xs' onClick={() => { setShowModal({ show: false, topicId: '', subTopicId: '', testId: '', deleteWhat: '' }) }}>Cancel</button>
                                    <button onClick={() => { deleteEntity() }} className='px-3 py-1 bg-slate-500 text-white rounded-lg text-xs'>Yes, Delete it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="">
                <div className='flex justify-between items-center py-0 px-1'>
                    <span className='text-base font-bold'>Topics</span>
                    <button className='text-xs 2xl:text-base py-1 px-4 bg-[#393185] rounded-md cursor-pointer text-white' style={{ cursor: 'pointer' }} onClick={() => { addTopic() }}>Add Topic</button>
                </div> 
            </div>
            {
                sidebarData && <div className='border-l-8 border-l-[#c7c1ff] rounded-2xl p-3 mt-2' style={{backgroundColor:'#efeeff'}}>
                    {
                        sidebarData.topics.map((topic, index) => {
                            return (
                                <div key={index} className='w-full rounded mb-1'>
                                    <div className=''>
                                        <div key={index} className='accordion-header py-1 flex justify-between items-center bg-transparent'
                                            onMouseEnter={(event) => { setIsHovering(`topic${topic.id}`) }}
                                            onMouseLeave={() => { setIsHovering(''); }}
                                            title={`${topic.name}`}
                                        >
                                            <div className='flex items-center w-2/3'>
                                                <button className='toggle'
                                                    onClick={handleToggleClick}
                                                    ref={buttonRef}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4  cursor-pointer me-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                    </svg>
                                                </button>
                                                {
                                                    isEditing === `topic${topic.id}` ? (
                                                        <input className='outline-none w-full border-b border-b-blue-400 text-xs' value={newName} onChange={(e) => { setNewName(e.target.value) }} ></input>
                                                    ) : (<span className='text-sm truncate w-full'>{topic.name}</span>)
                                                }
                                            </div>
                                            <div className={`${isHovering === `topic${topic.id}` ? '' : 'invisible'} flex `}>
                                                {
                                                    isEditing === `topic${topic.id}` ? (
                                                        <i className="fa-regular fa-floppy-disk me-2 text-xs"
                                                            onClick={() => {
                                                                //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                //we need to make an api call to edit-semester-name then in response we need to update the frontend.
                                                                editTopicName(topic.id);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        ></i>

                                                    ) : (
                                                        <i className="fa-solid fa-pen me-2 text-xs"
                                                            onClick={() => {
                                                                //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                setIsEditing(`topic${topic.id}`);
                                                                setNewName(topic.name);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        ></i>

                                                    )
                                                }
                                                <svg onClick={() => {
                                                    setShowModal({ ...showModal, show: true, topicId: topic.id, deleteWhat: 'topic' });
                                                }}
                                                    style={{ cursor: 'pointer', marginRight: "3px" }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-2 text-xs">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='accordion-body max-h-0 overflow-hidden transition-all duration-300 ease-in-out'>
                                            <div className='ml-2 mt-2 px-1 pl-2'>
                                                {
                                                    topic.content.map((contentObject, index) => {
                                                        if (contentObject.type === 'slide-group') {
                                                            return (
                                                                <div key={index} name="single sub topic" className='flex justify-between items-center mb-1 rounded-md 2xl:p-1'
                                                                    onMouseEnter={() => { setIsHovering(`subTopic${contentObject.id}`); console.log('chapter', contentObject.id) }}
                                                                    onMouseLeave={() => { setIsHovering(null) }}
                                                                    title={`${contentObject.name}`}
                                                                    style={selectedChapterId === contentObject.id ? { backgroundColor: '#bae6fd' } : {}}
                                                                >
                                                                    <div className='flex w-2/3 items-center text-sm'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                                        </svg>
                                                                        {
                                                                            isEditing === `subtopic${contentObject.id}` ?
                                                                                (
                                                                                    <input className='outline-none border-b-sky-300 border-b-2 w-full text-xs' value={newName} onChange={(e) => { setNewName(e.target.value) }} ></input>
                                                                                ) : (
                                                                                    <label onClick={() => { handleSubTopicClick(contentObject.id, topic.id); }} className='w-full truncate text-xs'>{contentObject.name}</label>
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={`${isHovering === `subTopic${contentObject.id}` ? '' : 'invisible'} flex`}>
                                                                        {
                                                                            isEditing === `subtopic${contentObject.id}` ? (
                                                                                <i className="fa-regular fa-floppy-disk me-2 text-xs cursor-pointer"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        //we need to make an api call to edit-semester-name then in response we need to update the frontend.
                                                                                        editSubTopicName(topic.id, contentObject.id);
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i className="fa-solid fa-pen me-2 text-xs cursor-pointer"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        setIsEditing(`subtopic${contentObject.id}`);
                                                                                        setNewName(contentObject.name);
                                                                                    }}
                                                                                ></i>
                                                                            )
                                                                        }
                                                                        <svg style={{ cursor: 'pointer', marginRight: "3px" }} onClick={() => setShowModal({ ...showModal, show: true, deleteWhat: 'subTopic', subTopicId: contentObject.id, topicId: topic.id })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-1">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                        </svg>
                                                                    </div>
                                                                    {
                                                                        selectedChapterId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                        if (contentObject.type === 'test') {
                                                            return (
                                                                <div key={index} name="single sub topic" className='flex justify-between items-center mb-1 rounded-md 2xl:p-1'
                                                                    onMouseEnter={() => { setIsHovering(`test${contentObject.id}`); console.log('test', contentObject.id) }}
                                                                    onMouseLeave={() => { setIsHovering(null) }}
                                                                    title={`${contentObject.name}`}
                                                                    style={selectedTestId === contentObject.id ? { backgroundColor: '#bae6fd' } : {}}
                                                                >
                                                                    <div className='flex w-2/3 items-center text-sm'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                                                        </svg>

                                                                        {
                                                                            isEditing === `test${contentObject.id}` ?
                                                                                (
                                                                                    <input className='outline-none border-b-2 border-b-sky-300 w-full text-xs' value={newName} onChange={(e) => { setNewName(e.target.value) }} ></input>
                                                                                ) : (
                                                                                    <label onClick={() => { console.log('test clicked?'); handleTestClick(contentObject.id, topic.id); }} className='w-full truncate text-xs'>{contentObject.name}</label>

                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={`${isHovering === `test${contentObject.id}` ? '' : 'invisible'} flex items-center`}>
                                                                        {
                                                                            isEditing === `test${contentObject.id}` ? (
                                                                                <i className="fa-regular fa-floppy-disk me-2 text-xs"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        //we need to make an api call to edit-semester-name then in response we need to update the frontend.
                                                                                        editTestName(topic.id, contentObject.id);

                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i className="fa-solid fa-pen me-2 text-xs"
                                                                                    onClick={() => {
                                                                                        //setShowModal((showModal) => { return { ...showModal, semId: semester.id, type: 'semester', action: 'edit', show: true, title: `Edit Semester Name`, name: semester.name } })
                                                                                        setIsEditing(`test${contentObject.id}`);
                                                                                        setNewName(contentObject.name);
                                                                                    }}
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>
                                                                            )
                                                                        }
                                                                        <svg style={{ cursor: 'pointer', marginRight: "3px" }} onClick={() => setShowModal({ ...showModal, show: true, topicId: topic.id, testId: contentObject.id, deleteWhat: 'test' })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-1">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                        </svg>

                                                                    </div>
                                                                    {
                                                                        selectedTestId === contentObject.id && (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                            <div>
                                                <div className='flex justify-between p-1'>
                                                    <span className='flex align-items-center gap-1' style={{ cursor: 'pointer', fontSize: '0.8rem' }} onClick={(event) => {
                                                        console.log('order ', topic.content, topic.content.length - 1);
                                                        if (topic.content.length) {
                                                            addSlideGroup(topic.id, 'slide-group', topic.content[topic.content.length - 1].sequence + 1);
                                                        } else {
                                                            addSlideGroup(topic.id, 'slide-group', 1);
                                                        }
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" width="0.5rem" height='0.5rem' stroke="currentColor" className="size-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
                                                        </svg>
                                                        Slide group
                                                    </span>
                                                    <span className='flex align-items-center gap-1' style={{ cursor: 'pointer', fontSize: '0.8rem' }} onClick={() => {
                                                        if (topic.content.length) {
                                                            addTest(topic.id, 'test', topic.content[topic.content.length - 1].sequence + 1);
                                                        } else {
                                                            addTest(topic.id, 'test', 1);
                                                        }
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" width={"1rem"} height={'1rem'} stroke="currentColor" className="size-4">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                                        </svg>
                                                        Test
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}

export default SideBar;




