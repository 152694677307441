import Finish from '../icons/Finish';
import { ReactComponent as Speaker } from '../../assets/solar_user-speak-broken Svg 2.svg'
import { ReactComponent as ArrowRight } from '../../assets/Chevron gradient svg 1.svg'
import { ReactComponent as ArrowLeft } from '../../assets/Chevron gradient left svg 1.svg'

const Footer = ({ handleSpeechStop, handleTextToSpeech, disablePrevious, disableNext
    , slidesArray, currentSlideId
    , previousSlide, nextSlide, isSpeaking ,markFinish ,currentSlideData  
}) => {

    return (
        <div className='flex relative px-4 py-0 mt-4 mb-4 items-center justify-center h-10'>
            {
                isSpeaking ? (
                    <span onClick={() => {
                        handleSpeechStop();
                    }} className='absolute top-0 left-6 mt-2 cursor-pointer text-white'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                        </svg>
                    </span>
                ) : (
                    <span onClick={() => {
                        //what ever content there is needs to be spoken 
                        handleTextToSpeech()
                    }} className='absolute top-0 left-6 mt-2 cursor-pointer text-white'>
                        <Speaker />
                    </span>
                )
            }
            <div className='flex items-center text-white'>
                <ArrowLeft style={disablePrevious()} onClick={previousSlide} />
                {/* {
                    slidesArray[0].id === currentSlideId ? (
                        <svg style={disableJumpBack()} onClick={previousContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                        </svg>
                    ) : (
                        <ArrowLeft style={disablePrevious()} onClick={previousSlide} />
                    )
                } */}
                <button disabled={currentSlideData.finished} key={currentSlideId} onClick={()=>{
                    markFinish(currentSlideId);
                }}>
                    <Finish color={currentSlideData.finished ? '#fff':'#FFC195'} fill={currentSlideData.finished ? '#059669':'white'} />
                </button>

                <ArrowRight style={disableNext()} onClick={nextSlide} />

                {/* {
                    slidesArray[slidesArray.length - 1].id === currentSlideId ? (
                        <svg style={disableJumpNext()} onClick={nextContent} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                        </svg>
                    ) : (
                        <ArrowRight style={disableNext()} onClick={nextSlide} />
                    )
                } */}

            </div>
        </div>
    );
}

export default Footer;